// Custom Multiship styles

.checkoutmultishipping .personalization-delay {
    display: none;
}

.checkoutmultishipping,
.checkoutplaceorder,
.orderdetails-checkout {
    margin-top: 0;

    @include media-breakpoint-up(md) {
        margin-top: rem(30px);
    }

    .section-header {
        @include type-h4;

        padding-top: 0;
    }

    .item-list-heading {
        padding: 0 0 rem(20px) 0;
        border-bottom: $border2;
        display: flex;
        flex-direction: column;

        .section-header {
            padding: 0;

            @include type-multiship-table-header;

            display: inline-block;
        }

        .item-shipping-address {
            display: inline-block;
            flex-grow: 1;
            padding-top: rem(5px);
            padding-left: 0;

            div {
                display: block;

                @include type-multiship-order-address;
            }

            a {
                @include type-lone-link;

                text-decoration: underline;
                padding-top: rem(5px);
                display: block;
            }
        }

        @include media-breakpoint-up(md) {
            flex-direction: column;

            .item-shipping-address {
                padding-left: rem(30px);
                padding-top: rem(2px);

                div {
                    display: inline-block;
                }

                a {
                    float: right;
                    padding-top: 0;
                }
            }
        }
    }

    .item-list {
        margin-bottom: 0;

        & + .item-list {
            padding-top: rem(20px);
            margin-top: rem(30px);
            border-top: $border2;
        }
    }

    .ship-to-multiple {
        border-top: 0;
        margin-bottom: rem(30px);

        &.multiship {
            margin-bottom: rem(20px);
        }

        @include media-breakpoint-up(md) {
            border-top: $border2;
        }
    }

    .cart-row {
        padding: rem(20px) 0 rem(30px) 0;
        display: flex;
        border-bottom: $border2;
        flex-direction: column;

        &:last-child {
            border-bottom: 0;
            padding-bottom: 0;
        }

        div:not(.form-caption) {
            display: inline-block;
        }

        .item-details,
        .quantitycolumn,
        .item-image {
            padding: 0 rem(10px) 0 0;
        }

        .leadTimeMsg,
        .AddToCartAlertMessage {
            color: #d41836;
            line-height: 1.5rem;
        }

        .item-image {
            width: rem(100px);
            min-width: 100px;

            img {
                width: 100%;
            }
        }

        .item-details {
            width: auto;
            box-sizing: border-box;
            padding-right: 0;

            div {
                display: block;
            }
        }

        .quantitycolumn {
            width: auto;
            padding-right: 0;
            margin-top: rem(5px);
        }

        .shippingaddress {
            width: 100%;
            padding: 0;
            margin-top: rem(20px);

            .select-style {
                margin-bottom: rem(10px);
            }

            .name {
                display: block;

                div {
                    display: block;
                }
            }

            .edit-address {
                margin-top: rem(15px);
            }

            .address-actions {
                display: block;
            }

            &.hide {
                display: none;
            }
        }

        .attribute {
            .label {
                @include type-summary-product-label;

                text-transform: capitalize;
            }

            .value {
                @include type-summary-product-value;
            }

            & + .attribute {
                margin-top: rem(5px);
            }
        }

        .checkout-section {
            &:last-child {
                border-bottom: 0;
                margin-bottom: 0;
                padding-bottom: 0;
            }
        }

        @include media-breakpoint-up(lg) {
            flex-direction: column;

            .item-details {
                width: 75%;
                padding-right: rem(60px);
            }

            .quantitycolumn {
                width: 25%;
                padding-right: rem(60px);
                margin-top: 0;
            }

            .shippingaddress {
                width: 35%;
                margin-top: 0;
            }
        }

        // Shipments page specific styles
        &.shipments {
            .cart-row-grouping {
                width: 100%;

                .line-item {
                    display: flex;
                    width: 100%;
                }

                .item-details {
                    width: 100%;
                    padding-right: 0;
                    box-sizing: border-box;

                    .sku {
                        .label,
                        .value {
                            @include type-cart-sku;
                        }
                    }

                    @include media-breakpoint-up(md) {
                        width: 45%;
                        padding-right: 0.625rem;
                    }
                }

                .quantitycolumn {
                    padding-right: 0;
                    width: 100%;

                    @include media-breakpoint-up(md) {
                        width: 55%;
                    }

                    .product-availability-list {
                        @include type-summary-product-value;

                        display: block;
                        width: 100%;

                        .on-order,
                        .not-available {
                            color: $error-color;
                        }

                        @include media-breakpoint-up(md) {
                            width: 50%;
                        }
                    }
                }
            }
        }
    }

    .product-list-item {
        .name {
            @include type-summary-product-name;

            font-style: normal;
            margin-bottom: rem(5px);
            line-height: 1.5em;
        }

        .price,
        .sku {
            display: none;
        }
    }

    .cart-row-grouping {
        width: 100%;
        display: flex;

        @include media-breakpoint-up(md) {
            width: 70%;
        }
    }

    .cart-row-inner-details {
        display: flex;
        flex-direction: column;

        @include media-breakpoint-up(lg) {
            flex-direction: row;
            width: 100%;
        }

        @include media-breakpoint-up(md) {
            flex-direction: row;
        }
    }

    .shipping-gift {
        padding-top: rem(20px);
    }

    .shipping-method {
        &.multiship {
            .select-style {
                width: 100%;

                @include media-breakpoint-up(md) {
                    width: 50%;
                }

                .selectbox {
                    white-space: break-spaces;
                }
            }
        }
    }

    .shipping-delay {
        &.multiship {
            .select-style {
                margin-bottom: rem(10px);

                @include media-breakpoint-up(md) {
                    margin-bottom: 0;
                }
            }
        }
    }
}
