.gift-certificate-purchase {
    padding: 0.83em;
    margin-bottom: 1em;
    background-color: $section-header-bg;

    .content-asset {
        @include media-breakpoint-up(md) {
            float: left;
            box-sizing: border-box;
            width: 50%;
            padding-right: 1rem;
        }

        img {
            margin: 1.66em 0;
        }
    }

    form {
        box-sizing: border-box;
        width: 100%;
        padding: 0 1.66em 1.66em;
        background-color: $base-bg;

        @include media-breakpoint-up(md) {
            float: left;
            width: 50%;
        }

        .cert-amount {
            float: left;
            clear: none;
            width: 40%;
            margin: 0;
        }

        button {
            width: 12em;

            // for IE
            width: max-content;
            margin-top: -4px;
        }

        .form-row-button {
            float: left;
            clear: none;
            width: 50%;
            margin: 2.1em 0 0 1.66em;
        }
    }

    .cert-amount {
        .form-caption {
            position: absolute;
            width: 40%;
            margin: 1.66em 0 0;
        }

        &.error {
            padding-bottom: 2.92em;
        }
    }

    span.error {
        margin-left: 2%;
    }
}

.check-balance {
    padding: 0 0 1.66em;
    border-bottom: $border;
    margin: 0 0 1.66em;
}

.gift-cert-balance {
    float: left;
    width: 40%;

    label {
        display: none;
    }

    input {
        width: 93%;
        margin-top: 0;
    }

    .form-caption {
        margin-left: 0;
    }
}

.gift-cert-balance-submit {
    float: left;
    clear: none;
}

.gift-cert-balance .label {
    display: none;
}
