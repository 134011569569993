.menu-utility-user {
    margin: 0;
    padding: 0;

    @include media-breakpoint-up(mobile-menu) {
        position: absolute;
        top: 3px;
        right: 13%;
    }

    li {
        list-style: none;
        padding: 0.8em 1rem;
        border-top: $border;

        &:first-child {
            border: none;
        }

        @include media-breakpoint-up(mobile-menu) {
            float: left;
            padding: 0;
            border-color: $trans;
            margin: 0;
            text-align: center;
            background-color: $trans;

            &:first-child {
                border: none;

                svg {
                    position: relative;
                    top: -2px;
                }
            }
        }

        a {
            text-decoration: none;
            color: $inverted-text-color;

            &:active {
                color: $link-hover-color;
            }

            @include media-breakpoint-up(mobile-menu) {
                transition: color 0.5s ease;
                color: $icon-header-fill;

                &:hover {
                    text-decoration: none;
                    color: $icon-header-hover-fill;
                }
            }
        }
    }

    .user-info {
        @include media-breakpoint-up(mobile-menu) {
            position: relative;
            transition: color 0.5s ease;
        }

        &.navActive {
            .user-login {
                @include media-breakpoint-up(mobile-menu) {
                    color: $accent1;
                }
            }

            .user-panel {
                @include media-breakpoint-up(mobile-menu) {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }

        .user-panel {
            transition: all 0.3s ease;
            width: 290px;
            box-sizing: border-box;
            text-align: left;
            top: 40px;
            position: absolute;
            opacity: 0;
            visibility: hidden;
            right: -1.5rem;
            z-index: z("user-panel");
            margin-top: 0;
            background-color: $base-bg;
            box-shadow: $shadow2;

            h6,
            h3 {
                padding: 0;
                margin: rem(10px) 0 rem(18px) 0;
                text-transform: capitalize;
            }

            h6 {
                line-height: rem(16px);
            }

            h4 {
                margin: rem(5px) 0;
                line-height: rem(25px);
            }

            .user-panel-wrapper {
                position: relative;
                padding: rem(20px);
                box-sizing: border-box;

                &::after {
                    content: "";
                    position: absolute;
                    width: 20px;
                    height: 20px;
                    background: $base-bg;
                    transform: rotate(45deg);
                    top: -8px;
                    right: 24px;
                    box-shadow: $shadow-carat;
                    z-index: 100;
                }

                &::before {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 40px;
                    top: -20px;
                    background: $trans;
                }
            }

            .user-messages {
                p {
                    margin-bottom: rem(18px);
                    margin-top: 0;

                    a {
                        line-height: rem(16px);
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            .account-message {
                .account-personalized-message {
                    padding: rem(10px);

                    .personalized-message {
                        // hide Welcome User, message here
                        .welcome,
                        .welcome-user {
                            display: none;
                        }
                    }
                }
            }

            .user-links {
                padding-left: 0;
                margin-top: rem(10px);
                padding-top: rem(10px);
                border-top: $border2;

                a {
                    @include button;

                    margin-bottom: rem(10px);
                    width: 100%;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                &.registered {
                    border-top: none;
                    margin-top: rem(10px);

                    a {
                        @include button-text;

                        width: auto;
                    }
                }
            }

            a {
                display: block;
                padding: 0.5rem 0;
                color: $link-color;

                @include media-breakpoint-up(mobile-menu) {
                    @include type-footer-link;

                    padding: 0;
                    line-height: 2.2;
                }
            }

            .user-logout {
                @include type-user-logout-button;

                &:hover {
                    color: $link-hover-color;
                }
            }
        }
    }
}
