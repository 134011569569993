.infinite-scroll {
    .search-result-content {
        .infinite-scroll-loading {
            width: 100%;
            height: 35px;
            text-align: center;
            background: url("../images/infinite-loading-indicator.gif") center no-repeat;
        }
    }
}

.infinite-scroll-loader {
    text-align: center;

    &[data-loading-state="loaded"],
    &.infinite-scroll-loading {
        span {
            display: none;
        }
    }

    span {
        @include type-infinite-scroll-loader;

        cursor: pointer;
        margin-top: rem(10px);
    }
}

.disable-infinite-scroll {
    .search-result-content {
        .page-element {
            display: none;
        }
    }
}
