.pt_product-compare {
    .full-width {
        padding: 0 1.25em;

        .print-page {
            float: right;
        }

        .back {
            padding: 1em 0;
        }
    }
}

.compare-items {
    clear: both;
    overflow: hidden;
    display: none;

    @include media-breakpoint-up(md) {
        display: block;
    }

    h5 {
        margin: rem(10px) 0;
    }

    button {
        padding: 0 rem(30px);

        &.button-text {
            @include type-lone-link;

            padding: 0;
            margin-left: rem(20px);
        }
    }
}

.compare-items-panel {
    @include clearfix;

    float: left;
}

.compare-item {
    float: left;
    overflow: hidden;
    position: relative;
    width: rem(60px);
    height: rem(60px);
    margin-right: rem(20px);
    text-align: center;
    background-color: $subtle-contrast-bg;

    .compare-item-remove {
        display: none;
        position: absolute;
        top: rem(1px);
        right: rem(2px);
        z-index: z("compare-remove-btn");
        cursor: pointer;
    }

    &.active {
        .compare-item-remove {
            display: block;
        }
    }

    .compare-item-number {
        @include type-compare-item-number;
    }
}

.compare-item-image {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 100%;
}

.compare-buttons {
    float: right;
    padding: rem(10px) 0 0;
}

.category-switch {
    @include clearfix;

    border-top: $border2;
    padding-top: rem(20px);

    label {
        float: left;
        width: 25%;
        margin: 0;

        @include line-height(40px);
    }

    .field-wrapper {
        float: left;
        width: 25%;
    }
}

#compare-table {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    border-top: $border2;
    border-bottom: $border2;
    padding: rem(20px) 0;

    .product {
        width: 25%;
        padding: rem(10px);
        box-sizing: border-box;
        position: relative;
        flex-grow: inherit;

        @include type-compare-table-product;

        text-align: center;

        &.first {
            background-color: $subtle-contrast-bg;
            border: 0 none;
        }

        .remove-link {
            position: absolute;
            right: rem(6px);
            top: 0;
            z-index: z("compare-remove-btn");
        }

        .product-swatches {
            width: rem(260px);
            max-width: 100%;
            margin: 0 auto;
        }
    }

    .attribute-row {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        border-top: $border2;

        .value {
            width: 25%;
            box-sizing: border-box;
            padding: rem(10px);
            flex-grow: inherit;
            text-align: center;
            border-left: $border2;
            word-break: break-all;

            &.product-attribute {
                border: 0 none;

                @include type-h6;

                text-align: right;
                background-color: $subtle-contrast-bg;
            }
        }

        &:first-of-type {
            border-top: $border2;
        }

        &:last-of-type {
            border-bottom: none;
        }

        &:nth-child(2n) {
            background-color: $subtle-contrast-bg;
        }
    }

    .action {
        display: block;
        padding: 0.5em 0;
    }

    .value,
    .product {
        border-left: $border2;
    }

    .value {
        .value-item {
            &::after {
                content: ", ";
            }

            &:last-child {
                &::after {
                    content: "";
                }
            }
        }
    }

    .section-header {
        @include type-compare-table-section-header;

        padding: 1em;
    }

    .selectcategory {
        label {
            @include type-compare-table-section-header;

            display: block;
            margin: 1em 0;
        }

        select {
            margin: 0;
            width: 100px;
        }
    }

    .comparelists {
        padding: 0;
    }

    .compare-lists {
        label,
        select {
            float: none;
            width: 100%;
            text-align: left;
        }
    }
}
