// Secondary Navigation Selectors
#secondary {
    a {
        @include type-footer-link;

        &.lone-link {
            @include type-lone-link;
        }
    }
}

.nav {
    ul {
        list-style: none;
        padding: 0;
        border: none;
        margin: 0.58em 0;

        li {
            list-style: none !important;
            margin: 0 0 rem(12px) !important;
        }
    }

    .toggle {
        @include type-secondary-nav-header;

        display: block;
        border: none;
        margin: 0.83em 0 0.58em;

        @include media-breakpoint-up(md) {
            margin: rem(20px) 0 rem(10px);
        }

        span {
            background: none;
        }
    }

    .expanded {
        background-position: left -96px;
    }
}

.pt_customer-service .nav nav {
    margin: rem(10px) 0;

    @include media-breakpoint-up(md) {
        border-top: $border2;
        margin: rem(20px) 0;
    }
}

// Category Refinement Hierarchy
#category-level-1 {
    li {
        @include type-refinement-value;

        color: $body-text-color;

        .refinement-link {
            &.active {
                @include font-bold;
            }
        }
    }
}

#category-level-2 {
    padding: rem(12px) 0 0 rem(10px);

    li {
        @include type-refinement-sub-value;
    }

    .refinement-link {
        &.active {
            @include font-bold;

            color: $body-text-color-drk;
        }
    }
}

#category-level-3 {
    padding: rem(12px) 0 0 rem(10px);

    .refinement-link {
        margin: 0;
        padding: 0;
    }
}

// Global Refinement Selectors
.refinement-header {
    @include type-refinement-header;

    background: $section-header-bg;
    border-top: $border2;
    margin: 0;
    padding: rem(20px) 0;
}

.refinement {
    position: relative;

    &:last-child {
        border-bottom: $border2;
    }

    // Hide Refinements on Category Landing Pages
    .pt_categorylanding & {
        display: none;

        &.category-refinement {
            display: block;
        }
    }

    h3 {
        position: relative;
        cursor: pointer;

        &::after {
            content: "";
            display: block;
            position: absolute;
            right: 0;
            top: rem(20px);
            width: rem(14px);
            height: rem(14px);
            background: url("../images/svg-icons/increase.svg") no-repeat center;
        }

        & + ul {
            display: none;
        }

        &.expanded {
            padding-bottom: rem(12px);

            &::after {
                background: url("../images/svg-icons/decrease.svg") no-repeat center;
            }

            & + ul {
                display: block;
            }
        }
    }

    .refinement-top-level {
        margin-bottom: rem(12px);

        @include line-height(20px);

        a {
            display: inline;

            @include type-refinement-value;
        }
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        &.scrollable {
            max-height: rem(372px);
            overflow-y: auto;
            overflow-x: hidden;
        }

        // standard swatches get dark border
        &.swatches {
            li {
                float: left;
                margin: 0 rem(15px) rem(15px) 0;

                // 5 across in desktop
                @include media-breakpoint-up(lg) {
                    margin-right: rem(8px);
                }

                &.selected {
                    a {
                        border-color: $border-color;
                        border-width: 2px;
                    }
                }

                &.unselectable {
                    opacity: 0.3;

                    a::after {
                        content: "";
                        display: block;
                        box-sizing: border-box;
                        background: $swatch-border-color;
                        position: absolute;
                        transform: rotate(135deg);
                        width: 140%;
                        height: rem(2px);
                        left: -20%;
                        top: calc(50% - #{rem(1px)});
                    }
                }
            }

            a {
                display: block;
                box-sizing: border-box;
                position: relative;
                min-width: rem(30px);
                min-height: rem(30px);
                padding: rem(5px);
                text-align: center;
                border: $swatch-border;
                overflow: hidden;

                @include line-height(16px);

                &:hover {
                    border-color: $border-color;
                }
            }
        }

        // color swatches get inner white border when selected
        &.colorFamily {
            a {
                width: rem(30px);
                height: rem(30px);
                padding: 0;
                text-indent: -999em;
            }

            li {
                &.selected {
                    border: none;

                    a {
                        @include font-bold;
                    }

                    a::after {
                        content: "";
                        display: block;
                        box-sizing: border-box;
                        top: rem(1px);
                        left: rem(1px);
                        position: absolute;
                        border: $swatch-border;
                        width: calc(100% - #{rem(2px)});
                        height: calc(100% - #{rem(2px)});
                    }
                }

                &.selected:not(.swatch-white, .swatch-clear, .swatch-ivory) {
                    a::after {
                        border-color: $selected-swatch-border-color;
                    }
                }

                &.unselectable:not(.swatch-white, .swatch-clear, .swatch-ivory) {
                    a::after {
                        background: $white;
                    }
                }

                &:not(.swatch-white, .swatch-clear, .swatch-ivory) {
                    a {
                        border: 0 none;
                    }
                }
            }
        }
    }

    li {
        padding: 0;
        margin-bottom: rem(12px);

        @include line-height(19px);

        &.hidden {
            display: none;
        }

        &.selected {
            .checkbox-link {
                @include font-bold;
            }
        }
    }

    .clear-refinement {
        @include type-clear-refinement-link;

        padding-left: rem(15px);
    }

    .refinement-link {
        border: none;

        @include type-refinement-value;

        display: inline-block;
        word-break: break-word;

        .icon {
            vertical-align: top;
            margin-right: rem(15px);
        }

        &.checkbox-link {
            position: relative;
            padding-left: rem(35px);
            cursor: pointer;

            .icon {
                margin: 0;
                position: absolute;
                top: rem(1px);
                left: 0;
            }
        }
    }

    .selected .refinement-link {
        background-position: left bottom;
    }
}
