nav[role="navigation"] {
    @include clearfix;

    position: absolute;
    top: 0;
    left: -90%;
    width: 90%;
    margin-top: 0;
    background-color: $header-nav-mobile-bg;
    z-index: 2;

    @include media-breakpoint-up(md) {
        left: rem(-320px);
        width: rem(320px);
    }

    @include media-breakpoint-up(mobile-menu) {
        position: static;
        left: auto;
        width: 100%;
        margin-top: 1px; // clear
        background-color: $header-bg;
    }

    @include respond(desktop) {
        padding: 0 5%;
    }
}

#wrapper {
    &.menu-active {
        position: relative;

        .menu-overlay {
            display: block;
            background-color: $nav-mobile-overlay;
            opacity: 0.5;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 10;

            @include respond($mobile-menu-breakpoint) {
                display: none;
            }
        }
    }
}

.sub-nav-content-column {
    display: none;

    @include media-breakpoint-up(mobile-menu) {
        display: block;
    }
}

.menu-toggle,
.account-menu-toggle {
    display: block;
    float: left;
    min-height: initial;
    line-height: initial;
    padding: 0;
    border: none;
    border-radius: 0;
    margin: 0 3% 0 rem(10px);
    font-size: 1.1em;
    background-color: $trans;
    color: $link-color;

    @include media-breakpoint-up(md) {
        margin-right: 5%;
    }

    @include media-breakpoint-up(mobile-menu) {
        display: none;
    }

    &:active,
    &:focus,
    &:hover {
        border-color: $trans;
        background-color: $trans;
        color: $trans;
    }
}

// prevent cropping in iOS, Safari
.menu-toggle .icon {
    width: 24px;
}

.account-menu-toggle {
    margin: 0;
}

.mobile-menu-header {
    @include clearfix;

    display: flex;

    @include respond($mobile-menu-breakpoint) {
        display: none;
    }
}

.back-link,
.see-all-link {
    display: block;

    &::after {
        display: none;
    }

    @include respond($mobile-menu-breakpoint) {
        display: none !important;
    }
}

.back-link {
    cursor: pointer;
    text-align: left;
    line-height: rem(14px);

    .menu-item-toggle {
        .icon {
            position: relative;
            top: initial;
            right: initial;
            margin-top: -4px;
        }
    }

    &::after {
        display: none;
    }
}

.menu-tile {
    width: 25%;
    padding: 0 20px 25px;
    text-align: center;

    @include respond($mobile-menu-breakpoint, $max-content-breakpoint) {
        &:nth-child(4n) {
            border: none;
        }

        &:nth-child(n + 5) {
            margin-top: 20px;
        }
    }

    @include respond($max-content-breakpoint) {
        width: 20%;

        &:nth-child(5n) {
            border: none;
        }

        &:nth-child(n + 6) {
            margin-top: 20px;
        }
    }

    &:last-child {
        border: none;
    }

    a:not(.menu-tile-heading) {
        color: $link-nav-color;
    }
}

.menu-category-tiles {
    @include respond($mobile-menu-breakpoint) {
        display: flex;
        flex-wrap: wrap;
        width: 100% !important;
        justify-content: center;
    }
}

.menu-category-tile {
    @include respond($mobile-menu-breakpoint) {
        width: 25%;
        padding: 0 20px 25px;
    }

    @include respond($mobile-menu-breakpoint, $max-content-breakpoint) {
        &:nth-child(n + 5) {
            margin-top: 20px;
        }
    }

    @include respond($max-content-breakpoint) {
        width: 20%;

        &:nth-child(n + 6) {
            margin-top: 20px;
        }
    }

    img {
        display: none;

        @include respond($mobile-menu-breakpoint) {
            display: block;
            width: 100%;
        }
    }
}

.sub-nav-content-asset {
    display: block;

    &.show-menu-item {
        display: block;

        a {
            display: block;
        }

        .level-3 {
            display: none;
        }

        .sub-nav-content-column {
            &.text-content {
                li {
                    a {
                        display: block;
                    }
                }
            }
        }
    }

    &.large-nav-squares {
        .sub-nav-content-container {
            justify-content: center;
        }
    }

    .sub-nav-content-container {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;

        @include respond($mobile-menu-breakpoint) {
            flex-direction: row;
            padding: 35px 0 50px;
        }
    }

    .sub-nav-content-column {
        box-sizing: border-box;

        @include respond($mobile-menu-breakpoint) {
            padding-right: 25px;
        }
    }

    .sub-nav-content-item {
        box-sizing: border-box;

        @include respond($mobile-menu-breakpoint) {
            padding-bottom: 35px;
        }

        img {
            height: 101px;
            width: 101px;
            display: none;

            @include respond($mobile-menu-breakpoint) {
                display: block;
            }
        }

        span {
            display: block;
        }

        &.text-content {
            @include respond($mobile-menu-breakpoint) {
                padding-left: 20px;
                border-left: $border;
            }

            li {
                & > a {
                    @include respond($mobile-menu-breakpoint) {
                        &:hover {
                            text-decoration: none;
                            cursor: default;
                        }
                    }
                }
            }
        }

        &.single-item {
            padding-bottom: 0;

            img {
                height: 218px;
                width: 218px;
            }
        }
    }
}

// fix for zoomed in browsers
.menu-category {
    @include respond($mobile-menu-breakpoint) {
        width: 100%;
        line-height: 1.2;
    }
}

.menu-category,
.mobile-menu-utility-user {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: subpixel-antialiased;

    @include respond($mobile-menu-breakpoint) {
        display: flex;
        justify-content: space-between;
        height: 100%;
    }

    @include respond(large-down) {
        width: 100%;
    }

    li {
        list-style: none;
        padding-left: 0;

        @include media-breakpoint-up(mobile-menu) {
            border: none;
        }

        &:hover {
            > a {
                @include media-breakpoint-up(mobile-menu) {
                    background-color: $trans;
                }
            }
        }
    }

    a {
        @include type-menu-category-link;

        display: block;
        color: $link-color;

        @include respond(large-down) {
            padding: rem(18px) 0;
            margin: 0 rem(20px);
            width: calc(100% - 40px);

            &::after {
                content: "";
                width: 100%;
                height: 1px;
                position: absolute;
                bottom: 0;
                left: 0;
                background-color: $border2-color;
            }
        }

        @include media-breakpoint-up(mobile-menu) {
            width: auto;
        }

        @include respond($mobile-menu-breakpoint) {
            display: none;
        }

        &.back-link {
            @include type-nav-back-link;
        }
    }

    .arrow {
        position: absolute;
        top: 25px;
        right: 0;

        @include respond($mobile-menu-breakpoint) {
            display: none;
        }

        &.left {
            right: auto;
            left: 20px;
            transform: rotateY(180deg) translateY(-50%);
        }
    }
}

.mobile-cap {
    box-sizing: border-box;
    padding: 15px;
    position: relative;
    text-align: center;

    .close {
        padding: 0;
        background-color: $trans;
        border: none;
        position: absolute;
        left: 16px;
        top: 14px;
        min-height: 0;

        @include respond(large) {
            margin-left: 0;
        }
    }

    @include respond($mobile-menu-breakpoint) {
        display: none;
    }
}

.mobile-menu-level-label {
    display: none;

    @include type-nav-level-heading;

    padding: 12px 20px;
    box-sizing: border-box;
    background-color: $subtle-contrast-bg;
    line-height: rem(16px);

    &.show-menu-item {
        display: block;

        @include respond($mobile-menu-breakpoint) {
            display: none;
        }
    }
}

.level-1 {
    > li {
        // override background-color for small viewport
        background-color: $trans;

        @include respond($mobile-menu-breakpoint) {
            height: 100%;

            &:hover {
                > a {
                    background: $trans;
                    color: $link-hover-nav-color;

                    .icon {
                        fill: $link-hover-nav-color;
                    }
                }
            }

            > a {
                &.has-sub-menu {
                    // carat element
                    &::after {
                        content: "";
                        display: none;
                        position: absolute;
                        width: 20px;
                        height: 20px;
                        background: $base-bg;
                        transform: rotate(45deg);
                        bottom: -30px;
                        left: 6px;
                        box-shadow: $shadow-carat;
                        z-index: 100;
                    }

                    &::before {
                        content: "";
                        display: none;
                        position: absolute;
                        width: 200%;
                        height: 40px;
                        bottom: -35px;
                        background: $trans;
                    }
                }
            }

            &.navActive {
                > a {
                    &::after,
                    &::before {
                        display: block;
                    }
                }

                .level-2 {
                    display: block;
                }
            }
        }

        &:first-child {
            border: none;
        }

        &:last-child {
            > a {
                @include media-breakpoint-down(md) {
                    &::after {
                        display: none;
                    }
                }
            }
        }

        a {
            display: block;

            .icon {
                margin-right: 6px;
            }

            @include respond($small-breakpoint, $mobile-menu-breakpoint) {
                display: none;

                &.show-menu-item {
                    display: block;
                    position: relative;
                }
            }
        }

        > a {
            color: $link-nav-color;

            // prevents category name wrapping outside of desktop
            br {
                display: none;
            }

            @include respond($mobile-menu-breakpoint) {
                display: flex;
                position: relative;
                height: 100%;
                color: $link-nav-color;
                margin: 0 12px;
                text-align: center;

                // handles the stacked nav items
                span.break-name {
                    line-height: 1.2;
                    text-align: center;

                    br {
                        display: block;
                    }
                }
            }
        }
    }
}

.level-2 {
    > ul {
        padding-bottom: 0.5em;
        padding-left: 2.1rem;
    }

    .level-1 > .active & {
        display: block;
    }

    a {
        color: $link-nav-color;
    }

    @include respond($mobile-menu-breakpoint) {
        display: none;
        position: absolute;
        z-index: 99;
        padding: 0;
        margin-top: 20px;
        box-shadow: $shadow;

        a {
            color: $link-nav-color;
            line-height: 1.2;
            margin-bottom: rem(10px);

            &:hover {
                text-decoration: underline;
            }
        }

        .banner {
            width: 28.8%;
        }
    }

    .banner {
        display: none;

        img {
            vertical-align: top;
        }

        @include respond($mobile-menu-breakpoint) {
            display: block;
        }
    }

    &.edge {
        right: 0;
        left: initial;
        transform: translateX(0);
    }

    .horizontal-banner {
        width: 100%;
        padding: rem(20px) rem(30px);
        box-sizing: border-box;
        display: none;

        span {
            @include type-menu-category-link;
        }

        @include respond($mobile-menu-breakpoint) {
            display: block;
        }
    }
}

.menu-columns {
    background-color: $nav-col-bg;

    @include clearfix;

    @include respond(large-down) {
        padding-left: 0 !important;
    }

    @include respond($mobile-menu-breakpoint) {
        display: flex;
        padding: rem(30px) rem(30px) rem(20px) rem(30px);
        margin: auto;
        width: intrinsic; /* Safari/WebKit uses a non-standard name */
        width: max-content;
        max-width: calc(100vw - #{rem(80px)});
    }

    @include respond($max-content-breakpoint) {
        max-width: $max-content-breakpoint-hf - 60;
    }

    .top-banner & {
        @include respond($max-content-breakpoint) {
            box-sizing: unset;
        }
    }

    @include media-breakpoint-up(lg) {
        > ul {
            max-width: 225px;
            min-width: 150px;
        }
    }
}

.menu-vertical {
    padding-left: 0;

    > li {
        > a {
            @include type-menu-category-link;
        }
    }

    @include respond($mobile-menu-breakpoint) {
        padding-right: 20px;
        padding-left: 20px;

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-left: 0;
        }

        &:last-of-type {
            border-right: none;
        }

        > li {
            &:not(.menu-category-tile) {
                margin-top: rem(25px);

                &:first-child {
                    margin-top: 0;
                }
            }
        }
    }
}

.level-3 {
    padding: 0;

    a {
        @include type-nav-level-3-link;

        color: $link-nav-color;

        @include respond($mobile-menu-breakpoint) {
            color: $link-nav-color;
        }
    }

    .mobile-menu-level-label {
        padding-left: rem(20px);
    }

    li {
        &:first-child {
            a.show-menu-item {
                margin-left: 50px;
                text-wrap: wrap;
                width: calc(100% - 52px);

                .menu-item-toggle {
                    margin-left: -30px;
                }
            }
        }
    }
}

.tap-to-close-mobile-menu {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
}

.menu-utility-user {
    display: none;

    @include respond($mobile-menu-breakpoint) {
        display: flex;

        .user-info {
            display: flex;
        }

        .account {
            margin-right: rem(10px);
        }

        .arrow {
            margin-left: rem(10px);
        }

        .user-account {
            display: flex;

            .welcome-container {
                display: flex;
                flex-direction: column;

                .welcome-user {
                    @include font-size(11px);

                    line-height: 0.75em;
                    text-align: left;
                }

                .account-message {
                    line-height: 1.15em;
                }
            }
        }
    }
}

.mobile-menu-utility-user {
    display: block;
    margin: 0;
    padding: 0;
    list-style: none;

    .mobile-menu-level-label {
        padding: 15px 0;
        margin: 0 20px;
        position: relative;

        &::after {
            content: "";
            width: 100%;
            height: 1px;
            position: absolute;
            bottom: 0;
            left: 0;
            background-color: $border2-color;
        }
    }

    li {
        padding: 0;
        background-color: $subtle-contrast-bg;

        &:last-child {
            a {
                &::after {
                    display: none;
                }
            }
        }

        a {
            position: relative;

            @include type-nav-mobile-account-link;

            > .icon {
                margin-right: 10px;
            }
        }
    }

    @include respond($mobile-menu-breakpoint) {
        display: none;
    }
}

.mobile-account-menu {
    display: none;

    .account-nav-registered {
        margin-top: rem(-10px);
    }

    .back {
        cursor: pointer;
        padding-left: rem(20px);
    }

    .level-1-header {
        background-color: $subtle-contrast-bg;

        a {
            @include type-h5;

            line-height: rem(40px);
            padding: 0 rem(20px);
        }

        .icon {
            position: relative;
            top: -3px;
        }

        ul {
            background: $body-bg;
            list-style: none;
            margin: 0;

            li {
                padding: 0;

                &:last-of-type a::after {
                    display: none;
                }

                a {
                    @include font-reg($body-text-size);

                    display: block;
                    margin: 0 20px;
                    padding: rem(8px) 0;
                    position: relative;

                    &::after {
                        content: "";
                        width: 100%;
                        height: 1px;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        background-color: $border2-color;
                    }
                }
            }
        }
    }

    .welcome-user {
        @include type-h5;

        padding: rem(15px) rem(20px) 0;
    }

    .account-message {
        .personalized-message {
            // hide Welcome User, message here
            .welcome,
            .welcome-user {
                display: none;
            }
        }
    }

    .summary-link {
        padding: rem(15px) rem(20px);
    }

    .account-logout {
        display: block;
        margin: 0 rem(20px);
        padding: rem(15px) 0;
        border-top: $border2;
    }
}

// global account messaging
.account-personalized-message {
    background: $personalized-message-bg;
    color: $personalized-message-color;
    margin: rem(10px) 0;
    padding: rem(20px);

    &.success {
        background: $personalized-message-success-bg;

        .personalized-message {
            &::before {
                background-image: url("../images/svg-icons/tooltip-icon.svg");
            }
        }
    }

    &.warning {
        background: $personalized-message-warning-bg;
        color: $personalized-message-warning-color;

        .personalized-message {
            &::before {
                background-image: url("../images/svg-icons/error-icon.svg");
            }
        }
    }

    .personalized-message {
        display: inline-block;
        padding-left: rem(30px);

        @extend %alt-p;

        line-height: rem(20px);
        position: relative;

        &::before {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: rem(20px);
            height: rem(20px);
            background: url("../images/svg-icons/tooltip-info-white.svg") no-repeat center;
            background-size: contain;
        }
    }
}

.menu-active {
    .navigation-mobile-backing {
        left: 0;
    }
}

.navigation-mobile-backing {
    position: fixed;
    top: 0;
    left: -90%;
    width: 90%;
    height: 100%;
    z-index: 1;
    background-color: $nav-mobile-backing;
    transition: left 0.2s ease-in;

    @include media-breakpoint-up(md) {
        left: rem(-320px);
        width: rem(320px);
    }

    @include respond($mobile-menu-breakpoint) {
        display: none;
    }
}
