#mini-cart {
    position: absolute;
    top: 21px;
    right: 20px;
    z-index: z("minicart");

    @include media-breakpoint-up(md) {
        top: 24px;
    }

    @include media-breakpoint-up(mobile-menu) {
        top: 5px;
        right: 7%;

        // increase height to keep hover active
        // when moving into mincart panel
        height: rem(30px);
    }

    .mini-cart-name {
        a {
            color: $link-color;
        }
    }
}

.mini-cart-total {
    .mini-cart-link {
        color: $icon-header-hover-fill;

        .minicart-quantity {
            position: relative;
            top: 4px;
            padding-left: rem(4px); // 8px on screen
        }
    }

    .mini-cart-empty {
        transition: color 500ms ease;
        color: $icon-header-fill;

        &:hover {
            text-decoration: none;
            color: $icon-header-hover-fill;
        }

        .minicart-quantity {
            border: $border;
            background: $header-bg;
            color: $body-text-color-lt;
        }
    }
}

.mini-cart-content {
    display: none;
    position: absolute;
    top: 40px;
    right: -20px;
    z-index: z("minicart", "dropdown");
    width: rem(300px);
    background: $base-bg;
    box-shadow: $shadow-minicart;
    padding: rem(20px) 0;

    @include media-breakpoint-down(md) {
        display: none !important; // no jquery overrides
    }

    // only show in desktop
    @include media-breakpoint-up(lg) {
        #mini-cart:hover & {
            display: block;

            // mini-cart carat
            &::after {
                content: "";
                position: absolute;
                width: 20px;
                height: 20px;
                background: $base-bg;
                transform: rotate(45deg);
                top: -10px;
                left: 82%;
                box-shadow: $shadow-carat;
                z-index: 100;
            }

            // add spanning psuedo element to maintain hover into minicart
            &::before {
                content: "";
                position: absolute;
                width: 100%;
                height: 40px;
                top: -20px;
                background: $trans;
            }
        }
    }
}

.mini-cart-products {
    overflow: auto;
    max-height: 300px;
    padding: 0 rem(20px);
}

.mini-cart-product {
    position: relative;
    overflow: hidden;
    padding: 0 0 rem(20px);
}

.mini-cart-image {
    float: left;
    width: 33%;

    img {
        max-width: 100%;
    }
}

.mini-cart-product-details {
    float: right;
    width: calc(66% - 10px);
}

.mini-cart-attributes,
.mini-cart-product .product-availability-list {
    @include type-mini-cart-attribute;
}

.mini-cart-qty {
    .label {
        @include font-bold;
    }

    .mini-cart-content & {
        display: inline;
        padding-right: rem(15px);
    }

    .value {
        @include font-bold;
    }
}

.mini-cart-pricing {
    @include type-h5;

    clear: right;

    .mini-cart-content & {
        display: inline;

        @include line-height(30px); // in case of wrapping
        .price-promotion {
            display: inline;
        }
    }

    .price-standard {
        text-decoration: line-through;

        @include font-reg;

        & + .price-sales {
            color: $price-sale-color;
        }
    }
}

// shown in add to cart modal only
.mini-cart-content .mini-cart-problem-pack {
    display: none;
}

.mini-cart-totals {
    .mini-cart-content & {
        border-top: $border2;
        margin: 0 rem(10px);
    }
}

.mini-cart-subtotals {
    width: 100%;

    .mini-cart-content & {
        padding: rem(20px) rem(10px) rem(10px);
        overflow: auto;

        .label {
            float: left;
        }

        .value {
            float: right;
        }
    }

    .value {
        @include font-bold;
    }
}

.mini-cart-link-cart {
    width: 100%;
}

.mini-cart-link-checkout {
    display: block;
    clear: both;
    margin: rem(10px) 0 0;

    @include type-h5;

    text-align: center;
    color: $btn-color !important;
}

.checkout-mini-cart {
    overflow: auto;
    max-height: 330px;
    background: $box-bg;
}
