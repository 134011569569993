// --------------------------------------
// Product Details Styles
// --------------------------------------

@mixin type-pdp-main {
    @include font-reg;
}

@mixin type-pdp-label {
    float: left;
    padding-right: rem(10px);
}

@mixin type-product-number {
    @include font-size(12px, 18px);
}

@mixin type-product-early-bird {
    @include font-bold(12px);
}

@mixin type-product-info-rating {
    font-size: 2em;
}

@mixin type-product-price {
    @include type-h3;
}

@mixin monthly-product-price {
    @include type-h4;
    @include font-reg;

    line-height: 1;
}

@mixin type-product-attribute-label {
    @include font-bold($body-text-size, 20px);
}

@mixin type-product-attribute-value {
    @include font-bold;
}

@mixin type-promotion-callout {
    @include type-h4;

    color: $product-promotion-color;
}

@mixin type-zoom-title {
    @include font-size(10px);
}

// --------------------------------------
// Bonus Product Styles
// --------------------------------------

@mixin type-selected-bonus-item-name {
    @include font-bold;
}

// --------------------------------------
// Store Inventory Styles
// --------------------------------------

@mixin type-store-tile-status {
    font-size: 0.9em;
}

@mixin type-store-list-item-selected {
    @include font-bold;
}

// --------------------------------------
// Recommendation Styles
// --------------------------------------

@mixin type-recommendation-product-name {
    @include font-reg($body-text-size);
}

@mixin type-recommendation-product-price {
    @include type-h5;
}
