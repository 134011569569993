// --------------------------------------
// Form Component Styles
// --------------------------------------

// Legend Styles
@mixin type-legend {
    @include font-bold($body-text-size);
}

// Legend Inner Span Styles
@mixin type-legend-inner {
    @include font-bold(12px);

    text-transform: none;
}

// Required Indicator Wrapper Styles
@mixin type-dialog-required {
    @include font-reg(12px);

    text-transform: none;
}

// Required Indicator Wrapper Inner Em Styles
@mixin type-dialog-required-inner {
    @include font-reg($body-text-size);

    text-transform: titlecase;
    font-style: normal;
}

// Label Styles
@mixin type-label {
    @include type-h6;
}

// Inline Label Styles
@mixin type-label-inline {
    @include line-height(16px);
    @include font-reg($body-text-size);
}

// Form Label Text Styles
@mixin type-label-text {
    @include font-bold($body-text-size);
}

// Input and Textarea Styles
@mixin type-input {
    @include font-size($input-text-size);
}

// Select Styles
@mixin type-select {
    @include font-size($input-text-size);
}

// Form Caption Styles
@mixin type-form-caption {
    line-height: 2em;

    @include font-size($input-form-caption-size);

    color: $input-form-caption-color;
}

// Checkbox Form Caption Styles
@mixin type-checkbox-form-caption {
    @include font-size(12px);
}

// Horizontal Form Caption Styles
@mixin type-horizontal-form-caption {
    @include font-size(12px);
}

// Form Error Styles
@mixin type-form-error {
    @include font-size(12px, 24px);

    letter-spacing: 0.1px;
}

@mixin type-error-icon {
    display: none;
    background: url("../images/svg-icons/error-icon.svg") no-repeat;
    position: relative;
    top: 4px;
    width: 20px;
    height: 17px;
    margin-right: rem(4px);
}

@mixin type-form-caption-error-message {
    color: $error-color;
}

// Required Indicator Styles
@mixin type-required-indicator {
    @include font-reg(12px);

    color: $error-color;
    white-space: nowrap;
}

// Form Tooltip Styles
@mixin type-field-tooltip {
    @include font-size(12px);

    text-decoration: underline;
    line-height: 2.7em;

    @include media-breakpoint-up(md) {
        line-height: 3.25em;
    }
}

// --------------------------------------
// Tooltip Styles
// --------------------------------------

@mixin type-tooltip {
    @include media-breakpoint-down(md) {
        @include font-size(12px);
    }
}

@mixin type-recommendation-tooltip-price {
    @include font-reg;
}
