@use "sass:color";

// --------------------------------
//  GLOBAL VARIABLES USED IN ALL 10 SITES
//  See site variables for colors, fonts and overrides
// --------------------------------

// --------------------------------
//  VIEWPORTS
// --------------------------------
$small-breakpoint: 320px;
$medium-breakpoint: 480px;
$large-breakpoint: 768px;
$desktop-breakpoint: 1025px;
$max-content-breakpoint: 1280px;
$max-content-breakpoint-hf: 1440px; // header and footer max only
$mobile-menu-breakpoint: $desktop-breakpoint;

// Bootstrap Media Breakpoint Override
$grid-breakpoints: (
    xs: $small-breakpoint,
    sm: $medium-breakpoint,
    md: $large-breakpoint,
    lg: $desktop-breakpoint,
    xl: $max-content-breakpoint,
    mobile-menu: $mobile-menu-breakpoint
);

// --------------------------------
//  PAGE LAYOUT
// --------------------------------
$content-width-gutters: 15px;
$left-nav-width: 230px; // content and search refinement left navs
$padding-search-results-mobile: 0.625rem 0.625rem 1.875rem !default;
$page-padding-registered-mobile: 0 0.625rem !default;

// --------------------------------
//  GLOBAL COMPONENTS
// --------------------------------

// Headings
// ------------------------
$section-heading-color: $white;
$hero-heading-lg: 72px;
$hero-heading-sm: 40px;
$hero-sub-heading-lg: 24px;
$hero-sub-heading-sm: 20px;
$top-banner-padding-mobile: 20px 10px 10px !default;
$top-banner-padding-top-medium: 25px !default;
$top-banner-padding-bottom-medium: 15px !default;
$top-banner-padding-top-large: 43px !default;
$top-banner-padding-bottom-large: 32px !default;
$top-banner-padding-right-large: 10px !default;
$top-banner-padding-left-large: 10px !default;

// Body Text
// ------------------------
$body-text-size: 15px !default;
$body-text-color: $dark-gray;
$body-text-color-lt: $medium-gray;
$body-text-color-drk: $dark-gray;
$body-text-color-black: $dark-gray;
$subtle-text-color: $medium-gray;
$inverted-text-color: $white; // over a dark background
$body-letter-spacing: 0.1px !default;
$body-alt-p-letter-spacing: 0.2px !default;
$breadcrumb-spacer-color: $dark-gray !default;
$body-line-height: 1.43; // 20px

// List Text
$list-line-height: 2;
$list-letter-spacing: 0.2px !default;
$list-left-padding: 15px;

// Background Colors
// ------------------------
$body-bg: $white;
$base-bg: $white;
$header-banner-bg: $dark-gray;
$header-bg: $white;
$header-nav-mobile-bg: $white;
$footer-bg: $white;
$footer-copy-bg: $lighter-gray;
$subtle-contrast-bg: $lighter-gray;
$box-bg: $white;
$box-drk-bg: $medium-gray;
$section-header-bg: $white;
$footer-top-bg-light: $brand-primary;
$footer-top-bg-dark: $brand-secondary;
$account-bg: $lighter-gray;
$refinements-bottom-bg: $lighter-gray;
$toy-warning-message-bg: $lighter-gray;
$recommendations-bg: $lighter-gray;
$active-panel-bg: color.adjust($accent1, $lightness: 10%) !default;
$panel-bg: $accent1 !default;
$active-account-nav: $accent1 !default;

// order history and order Details
$orderhistory-inverted-text-color: $inverted-text-color;
$orderhistory-pagination-text-color: $dark-gray;
$orderhistory-bg: $box-bg;
$orderhistory-bg-contrast: $subtle-contrast-bg;
$orderhistory-bg-dark-contrast: $box-drk-bg;

// Borders & Divider Lines
// ------------------------
$border-color: $dark-gray;
$border-brand-color: $brand-primary;
$border: 1px solid $border-color;
$border-thick: 2px solid $border-color;
$border-thick2: 5px solid $border-brand-color;
$border2-color: $light-gray;
$border2: 1px solid $border2-color;
$border3-color: $medium-gray;
$border3: 1px solid $border3-color;
$border4: 1px solid $border-brand-color;
$border5-color: $lighter-gray;
$border5: 1px solid $border5-color;
$border6-color: $white;
$border6: 1px solid $border6-color;
$selected-swatch-border-color: $white;
$selected-swatch-border: 1px solid $selected-swatch-border-color;
$container-border-radius: 4px;

// Badges
$badge-border-color: $white;
$badge-text-color: $white;
$badge-new-color: $accent2 !default;
$badge-newcolor-color: $black;
$badge-newcolor-font-size: 13px;
$badge-sale-color: $accent3;
$badge-rebate-color: $body-text-color;
$badge-rebate-text-color: $white;
$badge-rebate-border-color: $lighter-gray;

// Links
// ------------------------
$link-color: $dark-gray;
$link-hover-color: $brand-primary;
$link-decor: underline;
$link-hover-decor: underline;

// used in footer
$link-alt-color: $dark-gray;
$link-alt-hover-color: $dark-gray;
$link-alt-decor: none;
$link-alt-hover-decor: underline;

// used on catalog request page (content assets)
$link-alt2-color: $brand-primary;
$link-breadcrumb-letter-spacing: 0.2px !default;
$link-breadcrumb-font-size: 12px;
$link-breadcrumb-decor: none;
$link-breadcrumb-hover-decor: underline;

// primary navigation
$link-nav-desktop-letter-spacing: 0.3px !default;
$link-nav-font-size: $body-text-size;
$link-nav-desktop-text-decor: none;
$link-nav-color: $dark-gray;
$link-hover-nav-color: $brand-primary;
$nav-col-bg: $white;
$nav-mobile-backing: $white;
$nav-mobile-overlay: $black;

// back links
$left-arrow: url("../images/svg-icons/arrow.svg") no-repeat;

// Errors
// ------------------------
$error-bg-color: $white;
$error-border: 1px solid $error-color;

// Box Shadows
// ------------------------
$shadow: 0 7px 22px 0 rgb(0 0 0 / 20%);
$shadow2: 0 2px 9px 0 $light-gray !default; // account dropdown
$shadow-minicart: 0 2px 9px 0 rgb(165 165 165 / 50%) !default;
$shadow-carat: -4px -4px 20px -5px rgb(0 0 0 / 25%); // top only
$shadow-tile: 0 0 9px 0 $light-gray !default; // all 4 sides
$shadow-tile-slider: 0 2px 9px 0 rgb(165 165 165 / 50%) !default;
$footer-img-shadow: 0 4px 10px 0 rgb(0 0 0 / 15%) !default;

// Tooltip
// ------------------------
$tooltip-bg: $white;
$tooltip-color: $dark-gray;
$tooltip-shadow: $shadow;
$tooltip-radius: 3px;

// Dialog
// ------------------------
$dialog-bg: $base-bg; // white
$dialog-header-bg: $dark-gray;
$dialog-title-color: $white;

// --------------------------------
//  FORMS
// --------------------------------

// Input Elements
// ------------------------
$label-color: $dark-gray;
$label-font: $sans-serif-bold;
$label-font-size: $body-text-size;
$input-text-color: $dark-gray;
$input-text-size: $body-text-size;
$input-placeholder-color: $medium-gray;
$input-form-caption-size: 12px;
$input-form-caption-color: $dark-gray;
$input-border-color: $light-gray;
$input-border-selected-color: $dark-gray;
$input-border-width: 1px;
$input-border: $input-border-width solid $input-border-color;
$input-border-radius: $site-input-border-radius;
$input-height: 40px;
$input-padding-horizontal: 14px;
$input-bg: $white;
$textarea-height: 70px;
$focus-outline-style: none;
$focus-border: 1px solid $medium-gray;
$dropdown-arrow-icon-color: $dark-gray;
$dropdown-arrow-icon-width: 20px;
$quantity-button-bg: $lighter-gray;
$quantity-button-width: 40px;
$quantity-input-width: 50px;
$quantity-input-right-margin: 15px;

// Global Button Styles
// ------------------------
$btn-height: 40px; // including font height and borders
$btn-side-padding: 8%;
$btn-font: $button-font;
$btn-font-size: $body-text-size !default;
$btn-letter-space: 0.2px !default;
$btn-border-width: 2px;
$btn-border-radius: $site-btn-border-radius;
$btn-text-transform: none;
$btn-transition: all 0.3s ease;
$btn-width-desktop: 300px;

// Primary Button
// ------------------------
$btn-color: $white; // text
$btn-bg: $brand-primary;
$btn-border-color: $brand-primary !default;
$btn-hover-color: $white; // text
$btn-hover-bg: $brand-secondary;
$btn-hover-border-color: $brand-secondary;
$btn-disabled-opacity: 1;
$btn-disabled-bg: $light-gray !default;
$btn-disabled-color: $medium-gray !default;
$btn-disabled-border-color: $light-gray !default;
$btn-disabled-font: $button-font;

// Secondary Button
// ------------------------
$btn2-font: $button-font;
$btn2-color: $brand-primary !default; // text
$btn2-bg: $white;
$btn2-border-color: $lighter-gray !default;
$btn2-hover-color: $brand-primary; // text
$btn2-hover-bg: $white;
$btn2-hover-border-color: $brand-primary;
$btn2-disabled-opacity: 1;
$btn2-disabled-bg: $white;
$btn2-disabled-color: $light-gray !default;
$btn2-disabled-border-color: $lighter-gray !default;

// Tertiary Button
// ------------------------
$btn3-font: $button-font;
$btn3-color: $dark-gray; // text
$btn3-bg: $white;
$btn3-border-color: $lighter-gray;
$btn3-hover-color: $dark-gray; // text
$btn3-hover-bg: $white;
$btn3-hover-border-color: $medium-gray;
$btn3-disabled-opacity: 1;
$btn3-disabled-bg: $white;
$btn3-disabled-color: $light-gray !default;
$btn3-disabled-border-color: $lighter-gray;

// Error Button
// ------------------------
$btn-error-color: $white; // text
$btn-error-bg: $brand-primary;
$btn-error-border-color: $brand-primary;
$btn-error-hover-color: $white; // text
$btn-error-hover-bg: $brand-secondary;
$btn-error-hover-border-color: $brand-secondary;

// Large Button Variant
// ------------------------
$lg-btn-height: 50px;
$lg-btn-side-padding: 10%;
$lg-btn-fontsize: $body-text-size;

// Add To Cart Button Variant
// ------------------------
$a2c-btn-height: 50px;
$a2c-btn-side-padding: 20px;
$a2c-bg: $accent3;
$a2c-bg-hover: $accent4;

// --------------------------------
//  ICONOGRAPHY
// --------------------------------
$icon-fill: $dark-gray;
$icon-fill2: $medium-gray;
$icon-hover-fill: $dark-gray;
$icon-header-fill: $dark-gray;
$icon-header-hover-fill: $dark-gray;
$icon-social-fill: $brand-primary;
$icon-social-hover-fill: $brand-secondary;

// --------------------------------
//  ECOMMERCE ELEMENTS
// --------------------------------

// Stock
// ------------------------
$in-stock-color: $body-text-color;
$out-of-stock-color: $brand-primary;
$backorder-color: $brand-primary;

// Rating
// ------------------------
$rating-color: $dark-gray;

// Variations
// ------------------------
$variation-active-color: $dark-gray;
$swatch-text-color: $dark-gray;
$swatch-border-color: $light-gray;
$pdp-swatch-border-color: $dark-gray;
$swatch-selected-color: $light-gray;
$swatch-unselectable-color: $light-gray;
$swatch-unselectable-hover-color: $light-gray;
$swatch-border: 1px solid $swatch-border-color;
$swatch-selected-border: 1px solid $pdp-swatch-border-color;
$swatch-text-color-inverse: $white;
$swatch-bg-inverse: $dark-gray;

// Personalized messages
// ------------------------
$personalized-message-bg: $medium-gray;
$personalized-message-color: $white;
$personalized-message-warning-color: $body-text-color;
$personalized-message-letter-spacing: $list-letter-spacing !default;
$personalized-message-warning-bg: $warning-color;
$personalized-message-success-bg: $success-color;

// Product
// ------------------------
$product-number-color: $body-text-color;
$product-promotion-color: $brand-primary;
$product-badge-bg: $accent2;
$product-personalization-bg: $lighter-gray;
$product-esp-bg: $lighter-gray;

// Price
// ------------------------
$price-standard-color: $dark-gray;
$price-tiered-color: $brand-primary;
$price-sale-color: #d71921 ;

// Checkout
// ------------------------
$progress-indicator-active-color: $brand-primary;
$progress-indicator-inavtive-color: $light-gray;
$progress-indicator-number-color: $white;

// Slick Carousel Theme
// ------------------------
$slick-loader-path: "../lib/slick/";
$slick-arrow-color: $black;
$slick-arrow-bg-color: $trans;
$slick-arrow-bg-color-hover: $trans;
$slick-darkarrow-color: $white;
$slick-darkarrow-bg-color: $black;
$slick-darkarrow-bg-color-hover: $black;
$slick-dot-color: $lighter-gray;
$slick-dot-color-hover: $medium-gray;
$slick-dot-color-active: $medium-gray;
$slick-dot-size: 25px;
$slick-dot-shadow: 0 0 1px $medium-gray;
$slick-opacity-default: 0.75;
$slick-opacity-on-hover: 1;
$slick-opacity-not-active: 0.25;

// Zoom Elements
// ------------------------
$zoom-preload-background: url("../images/zoomloader.gif") $base-bg no-repeat 43px 30px;

// ADA Elements
// ------------------------
$skip-nav-focus-bg-color: $dark-gray;
$swatch-types: black #000, blue #0a21cb, orange orange, red red, brown #88452c, purple purple, green green, gray gray, pink pink, white #fff, yellow yellow, navy navy, clear #fff, silver silver, gold gold, beige #f2ecda, ivory #fff;
$tab-selection-color: #285cc7;

// flag-icon-css

$flag-icon-css-path: "../lib/flag-icon-css/flags";

// --------------------------------
//  CONTENT
// --------------------------------
$hero-banner-text: $body-text-color;
$content-tile-inner-header: $body-text-color;
$content-tile-inner-header-primary: $brand-primary;
$content-tile-inner-header-inverse: $white;
$content-tile-outer-header: $body-text-color;
$promo-banner-border-color: $white;
$promo-banner-bg-color: $accent1;
$promo-banner-text-color: $white;
$power-reviews-primary: #d71921;
