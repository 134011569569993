@use "sass:color";

#QuickViewDialog {
    padding-top: rem(10px);

    .product-add-to-cart {
        .AddToCartAlertMessage {
            @include media-breakpoint-up(xl) {
                margin-left: 160px;
            }
        }
    }

    @media screen and (min-width: 768px) {
        .recommendations-container {
            position: relative;
            margin-left: 8%;
            margin-right: 8%;

            &::before {
                position: relative;
            }
        }
    }
}

.js {
    .ui-dialog-content {
        .product-col-2 {
            width: 44%;
            max-width: rem(400px);

            @include media-breakpoint-down(md) {
                width: 100%;
            }

            .product-image {
                max-width: 100%;
            }

            .product-primary-image.slick-slider {
                .slick-arrow {
                    &.slick-prev {
                        left: rem(5px);
                    }

                    &.slick-next {
                        right: rem(5px);
                    }
                }

                .slick-dots {
                    height: auto;
                    margin-top: rem(20px);
                    overflow: visible;
                }
            }

            #thumbnails {
                display: none;
            }

            .wistia_thumb {
                .wistia_click_to_play {
                    @include button;
                    @include tertiary-button;

                    max-width: rem(300px);
                }
            }
        }

        .product-col-1,
        .product-col-3 {
            width: 53%;
            max-width: rem(480px);

            @include media-breakpoint-down(md) {
                width: 100%;
            }
        }

        .product-col-3 {
            &.product-set {
                width: 100%;
                max-width: 100%;
            }
        }

        h1.product-name {
            display: block;
        }

        .rating-wrapper {
            @include line-height(21px);

            margin-bottom: rem(25px);

            .links {
                display: none;

                a {
                    &.first {
                        border-right: $border;
                        padding-right: rem(8px);
                        margin-right: rem(5px);
                    }
                }
            }

            #BVRRSummaryContainer {
                float: left;
                margin-bottom: 0;

                & ~ .links {
                    display: inline-block;
                }

                .bv-compat {
                    .bv-rating-ratio-number {
                        display: none !important;
                    }
                }

                .bv-write-review-container {
                    display: none !important;
                }
            }
        }

        .pdp-main {
            .product-set-list {
                overflow: auto;
                max-height: rem(600px);
                border-bottom: $border2;

                .product-set-item {
                    padding: rem(20px) 0;
                }

                .product-set-image {
                    width: 22%;
                }

                .product-set-header,
                .product-set-details {
                    width: 45%;
                }

                .product-pricing-info,
                .product-add-to-cart-info {
                    width: 41%;
                }

                .product-name {
                    margin: 0;
                }
            }
        }
    }

    .zoomPad {
        float: left;
        position: relative;
        z-index: z("quickview-zoompad");
        width: 100%;
        cursor: crosshair;
    }

    .zoomPreload {
        @include font-reg(12px);

        opacity: 0.8;
        position: absolute;
        top: 0;
        left: 0;
        z-index: z("quickview-zoompreload");
        width: 90px;
        height: 43px;
        padding: 8px;
        border: $border2;
        text-align: center;
        text-decoration: none;
        background: $zoom-preload-background;
        color: $body-text-color-drk;
    }

    .zoomPup {
        opacity: 0.6;
        position: absolute;
        z-index: z("quickview-zoompup");
        overflow: hidden;
        border: $border2;
        background-color: $base-bg;
        cursor: crosshair;
    }

    .zoomOverlay {
        display: none;
        position: absolute;
        left: 0;
        top: 0;
        z-index: z("quickview-zoomoverlay");
        width: 100%;
        height: 100%;
        background: $base-bg;
    }

    .zoomWindow {
        position: absolute;
        left: 110%;
        top: 40px;
        z-index: z("quickview-zoomwin");
        height: auto;
        background: $base-bg;
    }

    .zoomWrapper {
        position: relative;
        z-index: z("quickview-zoomwrap");
        border: $border;
    }

    .zoomWrapperTitle {
        @include type-zoom-title;

        display: block;
        opacity: 0.6;
        position: absolute;
        top: 0;
        left: 0;
        z-index: z("quickview-zoomtitle");
        overflow: hidden;
        width: 100%;
        height: 18px;
        line-height: 18px;
        text-align: center;
        background: color.adjust($box-drk-bg, $lightness: 50%);
        color: $base-bg;
    }

    .zoomWrapperImage {
        display: block;
        position: relative;
        z-index: z("quickview-zoomwrapimg");
        overflow: hidden;

        img {
            display: block;
            position: absolute;
            z-index: z("quickview-zoomimg");
            border: none;
        }
    }

    .zoomIframe {
        display: block;
        opacity: 0.8;
        position: absolute;
        z-index: z("quickview-zoomiframe");
    }
}

#quickviewbutton {
    @include media-breakpoint-down(md) {
        display: none !important;
    }
}
