// Quantity Module
.quantity-module {
    display: flex;
    align-items: center;

    * {
        box-sizing: border-box;
    }

    label {
        margin: 0 4px 0 0;
    }

    span,
    input {
        height: 50px;
    }

    span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: $quantity-button-width;
        padding: 0 4px;
        border: $input-border;
        cursor: pointer;
        background: $quantity-button-bg;

        &:first-of-type {
            border-top-left-radius: rem($input-border-radius);
            border-bottom-left-radius: rem($input-border-radius);
        }

        &:last-of-type {
            border-top-right-radius: rem($input-border-radius);
            border-bottom-right-radius: rem($input-border-radius);
        }

        .icon {
            width: 15px;
            height: 15px;
        }
    }

    input,
    select {
        @include type-quantity-input;
    }

    input {
        appearance: textfield;
        width: $quantity-input-width;
        border-radius: 0;
        text-align: center;
        border-left: none;
        border-right: none;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            appearance: none;
            margin: 0;
        }
    }

    select {
        height: 48px;

        @include media-breakpoint-up(md) {
            height: 35px;
        }
    }

    &.disabled {
        pointer-events: none;

        input {
            color: $btn3-disabled-color;
        }
    }
}
