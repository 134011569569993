.js {
    .loader {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: z("loading-icon") + 1;
    }

    .loader-bg {
        opacity: 0.7;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: $base-bg;
    }

    .loader-indicator {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: z("loading-icon");
        width: 64px;
        height: 64px;
        background: url("../images/loading-small.gif") no-repeat center;
    }
}
