.account-module.full-width .order-history {
    * {
        box-sizing: border-box;
    }

    .search-result-items {
        margin: 0;
        position: relative;

        > li {
            margin-bottom: rem(30px);
            padding: 0;

            .order-history-item {
                background: $orderhistory-bg-contrast;
                transition: background-color 0.1s ease;
                transition: border 0.1s ease;
                border: 0;
            }

            .order-history-item-summary {
                color: $body-text-color;
            }

            &.expanded {
                .order-history-item {
                    background: $orderhistory-bg-dark-contrast;
                    border: 2px solid $orderhistory-bg-dark-contrast;
                }

                .order-history-item-summary {
                    color: $orderhistory-inverted-text-color;
                }
            }
        }
    }
}

.return-and-pagination {
    display: flex;
    flex-direction: column;
    margin-bottom: rem(25px);

    @include media-breakpoint-up(md) {
        flex-direction: row;
        align-items: center;
    }

    .how-to-return,
    .return-url {
        border-bottom: $border;
        margin: rem(10px) 0;
        padding: 0 0 rem(20px);
        text-align: center;

        @include media-breakpoint-up(md) {
            border-bottom: 0;
            margin: 0;
            padding: 0;
            text-align: left;
        }
    }

    .search-result-options {
        @include media-breakpoint-up(md) {
            margin-left: auto;
        }
    }

    .pagination {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include media-breakpoint-up(md) {
            flex-direction: row;
        }

        .results-hits {
            color: $orderhistory-pagination-text-color;
            margin-bottom: rem(10px);

            @include media-breakpoint-up(md) {
                margin: 0 rem(40px) 0 0;
            }
        }

        li.first-last {
            line-height: rem(20px);
            margin: 0 5px;

            a.page-first,
            a.page-previous,
            a.page-next,
            a.page-last {
                margin: 0;
            }
        }
    }
}

.order-history-search {
    line-height: 1.47;

    .label {
        font-weight: bold;
    }

    a.order-search-ordernumber-value {
        text-decoration: underline;
    }
}

.order-history-item {
    background: $orderhistory-bg-dark-contrast;
    border: $border3;
    padding: 0;

    .order-history-item-summary {
        color: $orderhistory-inverted-text-color;
        line-height: 1.5em;
    }
}

.order-history-item-summary {
    display: flex;
    flex-direction: column;
    padding: rem(10px) 0 0;
    cursor: pointer;

    @include media-breakpoint-up(md) {
        flex-direction: row;
        padding: rem(10px) 0;
    }

    .order-date,
    .order-amount,
    .order-number {
        display: flex;
        flex-direction: row;
        width: 100%;
        padding: 0 rem(20px);
        box-sizing: border-box;

        @include media-breakpoint-up(lg) {
            width: 17%;
        }

        @include media-breakpoint-up(md) {
            width: 20%;
            flex-direction: column;
        }

        .label {
            @include media-breakpoint-down(sm) {
                width: 50%;
            }
        }

        .value {
            @include font-bold($body-text-size);

            @include media-breakpoint-down(md) {
                width: 50%;
            }
        }
    }

    .order-detail-toggle {
        border-top: $border6;
        padding: rem(5px) rem(20px);
        text-align: center;

        @include media-breakpoint-up(md) {
            align-self: center;
            margin-left: auto;
            margin-right: rem(20px);
            border: 0;
            padding: 0;
            text-align: left;
        }

        &::after {
            content: "";
            display: inline-block;
            margin-left: rem(10px);
            width: rem(15px);
            height: rem(15px);
            background: url("../images/svg-icons/increase.svg") no-repeat center;
            position: relative;
            top: 2px;
        }

        &.expanded {
            color: $orderhistory-inverted-text-color;

            &::after {
                background: url("../images/svg-icons/decrease.svg") no-repeat center;
                filter: brightness(0) invert(1);
            }
        }
    }
}

.order-details {
    background: $orderhistory-bg;
    box-sizing: border-box;

    address {
        font-style: normal;
    }

    .order-details-top {
        background: $orderhistory-bg-contrast;
        padding: rem(10px) rem(20px);
        line-height: 1.3;
        display: flex;
        flex-direction: column;

        @include media-breakpoint-up(lg) {
            flex-direction: row;
        }

        .address-container {
            display: flex;
            flex-direction: row;
        }

        .bill-to {
            @include font-bold($body-text-size);

            padding-right: rem(10px);
        }

        .address {
            display: flex;
            flex-direction: column;
        }

        .order-actions {
            margin-top: rem(15px);
            display: flex;
            flex-direction: column;

            @include media-breakpoint-up(lg) {
                margin-left: auto;
                margin-top: 0;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
            }

            .button,
            button {
                margin-bottom: rem(15px);
                align-self: center;
                width: 100%;
                padding-left: 0;
                padding-right: 0;

                @include media-breakpoint-up(lg) {
                    margin-bottom: 0;
                    width: rem(150px);
                }

                &.cancel-order {
                    @include media-breakpoint-up(lg) {
                        margin-left: rem(20px);
                    }
                }
            }

            .down-payment {
                button {
                    width: 100%;

                    @include media-breakpoint-up(lg) {
                        width: rem(260px);
                    }
                }

                & + .product-add-to-cart {
                    .button {
                        @include media-breakpoint-up(lg) {
                            margin-left: rem(20px);
                        }
                    }
                }
            }
        }
    }

    .billing-message {
        padding: rem(10px) rem(20px) 0;

        @include media-breakpoint-up(lg) {
            padding-top: rem(20px);
        }
    }

    .order-shipments {
        padding: rem(20px) rem(10px) 0;
        height: auto;

        @include media-breakpoint-up(lg) {
            padding-top: rem(20px);
        }

        @include media-breakpoint-down(md) {
            height: 100%;
        }
    }

    .shipping-address {
        margin-top: 0;

        .ship-to {
            @include type-h3;

            line-height: 1.3;

            @include media-breakpoint-up(lg) {
                width: rem(160px);
                float: left;
            }
        }

        .address {
            @include type-label;

            line-height: 1.47;
            margin-bottom: rem(15px);

            @include media-breakpoint-up(lg) {
                float: left;
                width: calc(100% - #{rem(160px)});
            }

            address {
                #main & {
                    @include type-label;
                }
            }
        }
    }

    .order-shipment-gift-messaging {
        line-height: 1.47;
        margin-bottom: rem(15px);
    }

    .line-item {
        padding: rem(25px) 0;
        border-bottom: $border5;
        line-height: 1.47;
        display: flex;
        flex-flow: column wrap;

        @include media-breakpoint-up(lg) {
            padding: rem(30px) 0;
            flex-direction: row;
            justify-content: space-between;
        }

        &:first-child {
            border-top: $border5;
        }

        &:last-child {
            border-bottom: 0;
        }

        &.ordersearchguest {
            border-bottom: $border2;
            margin-top: 1.25rem;

            &:last-child {
                border-bottom: $border2;
            }
        }

        .line-item-image {
            width: rem(80px);
            float: left;

            > img {
                max-width: 100%;
            }
        }

        .line-item-info,
        .line-item-status,
        .line-item-actions {
            width: 100%;

            @include media-breakpoint-up(lg) {
                width: 40%;
            }
        }

        .line-item-info {
            .line-item-details {
                float: right;
                width: calc(100% - #{rem(100px)});
            }

            .sku {
                @include type-product-number;

                color: $product-number-color;
            }

            @include media-breakpoint-up(lg) {
                width: calc(100% * 1 / 3);
            }
        }

        .line-item-status {
            margin-bottom: rem(10px);
            padding-left: rem(100px);
            width: inherit;

            @include font-bold($body-text-size);

            @include media-breakpoint-up(lg) {
                margin: 0;
                padding: 0;
                box-sizing: border-box;
            }

            @include media-breakpoint-up(lg) {
                width: 15%;
            }

            &.ordersearchguest {
                float: right;
                width: calc(100% - 6.25rem);
            }
        }

        .line-item-actions {
            .line-item-action {
                .label {
                    @include font-bold($body-text-size);

                    display: inline-block;
                    width: rem(100px);
                }

                .button {
                    margin-top: rem(20px);
                    width: 100%;
                    padding-left: 0;
                    padding-right: 0;

                    @include media-breakpoint-up(lg) {
                        width: rem(150px);
                        float: right;
                    }
                }

                &:first-child {
                    .button {
                        margin-top: 0;
                    }
                }
            }

            @include media-breakpoint-up(lg) {
                width: 45%;
                text-align: right;
            }

            &.ordersearchguest {
                padding-bottom: 20px;
                float: right;
                margin-right: 69px;

                .line-item-action {
                    .button {
                        float: none;
                    }
                }
            }
        }

        .line-item-price-qty {
            @include font-bold($body-text-size);
        }

        .AddToCartAlertMessage {
            color: #d41836;
        }

        .line-item-write-review {
            display: inline-block;
            margin-top: rem(10px);

            @include type-lone-link;
        }
    }

    .orderSearchGuestWrapper {
        display: flex;
    }
}

.order-detail-actions {
    a.lone-link {
        margin: rem(15px);
    }
}

.order-details.svc-result-items.order-status-guest {
    margin: initial;

    .order-shipments {
        padding: initial;
    }

    .line-item:first-child {
        border-top: initial;
    }

    .line-item {
        padding: initial;
        flex-direction: column;

        .line-item-status {
            @include media-breakpoint-down(md) {
                padding-left: initial;
            }
        }

        .value {
            font-family: "nunito_sansregular", Arial, sans-serif;
        }

        .order-search-tracking {
            width: 296px;
        }

        a.order-search-tracking-number {
            text-decoration: underline;
        }

        .line-item-action.clearfix {
            display: inline-flex;

            .button {
                width: 9.75rem;
                height: 40px;
            }
        }

        .line-item-actions {
            width: 160px;
            padding-top: 20px;
        }
    }
}

.order-history {
    .down-payment {
        float: left;
        margin-top: 10px;

        @include media-breakpoint-down(md) {
            width: 100%;
        }

        button {
            height: 40px;
            width: 100%;

            @include media-breakpoint-up(lg) {
                width: rem(260px);
            }
        }
    }
}
