@use "sass:math";

// Generated a media query based on the given option
// Possible options:
//      print - styles only apply to printing
//      small-down - styles only apply to viewports smaller than 480px
//      small - styles only apply to viewports between 320px and 480px
//      medium-down - styles only apply to viewports smaller than 768px
//      medium - styles only apply to viewports between 480px and 767px
//      medium-up - styles only apply to viewports 480px and above
//      large-down - styles only apply to viewports smaller than 1025px
//      large - styles only apply to viewports between 768px and 1024px
//      large-up - styles only apply to viewports 768px and above
//      desktop - styles only apply to viewports 1025px and above
//      [min-width] - number value (with units) is applied as the min-width for a custom media query
//      [min-width], [max-width] - values (with units) are applied as min-width & max-width for a custom mediaquery

@mixin respond($media, $max: null) {
    @if $media == "print" {
        @media only print {
            @content;
        }
    } @else if $media == "small-down" {
        @media only screen and (max-width: $medium-breakpoint - 1) {
            @content;
        }
    } @else if $media == "small" {
        @media only screen and (min-width: $small-breakpoint) and (max-width: $medium-breakpoint - 1) {
            @content;
        }
    } @else if $media == "medium-down" {
        @media only screen and (max-width: $large-breakpoint - 1) {
            @content;
        }
    } @else if $media == "medium" {
        @media only screen and (min-width: $medium-breakpoint) and (max-width: $large-breakpoint - 1) {
            @content;
        }
    } @else if $media == "medium-up" {
        @media only screen and (min-width: $medium-breakpoint) {
            @content;
        }
    } @else if $media == "large-down" {
        @media only screen and (max-width: $desktop-breakpoint - 1) {
            @content;
        }
    } @else if $media == "large" {
        @media only screen and (min-width: $large-breakpoint) and (max-width: $desktop-breakpoint - 1) {
            @content;
        }
    } @else if $media == "large-up" {
        @media only screen and (min-width: $large-breakpoint) {
            @content;
        }
    } @else if $media == "desktop" {
        @media only screen and (min-width: $desktop-breakpoint) {
            @content;
        }
    } @else if type-of($media) == number and $max == null {
        @media only screen and (min-width: $media) {
            @content;
        }
    } @else if type-of($media) == number and type-of($max) == number {
        @media only screen and (min-width: $media) and (max-width: $max - 1) {
            @content;
        }
    }
}

// Inner page content width
// --------------------------------------

@mixin content-width() {
    box-sizing: border-box;
    margin: auto;
    width: calc(100% - #{$content-width-gutters * 2});
    max-width: $max-content-breakpoint;
}

// Apply full-bleed width to a content-width page by including this mixin
@mixin override-content-width() {
    width: 100%;
    max-width: 100%;
}

// Remove the default gutters in a content-width page by including this mixin
@mixin override-content-width-gutters() {
    width: 100%;
}

// Clearfix
// --------------------------------------

@mixin clearfix {
    &::after {
        content: "";
        display: table;
        clear: both;
    }
}

// Visually Hidden Elements
// --------------------------------------

@mixin visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

// Full-Bleed (using VW's)
// --------------------------------------

@mixin full-bleed-width() {
    width: 100vw;
    max-width: 100vw;
    margin-left: calc((100vw - 100%) / -2);
}

// can be applied to elements that already receive the full-bleed styling to override within a particular viewport
@mixin override-full-bleed() {
    width: auto;
    max-width: 0;
    margin-left: initial;
}

// Override a button's height (including borders). Optionally change the side paddings.
// ex1: @include set-button-height(50px);
// ex2: @include set-button-height(50px, 35px);
// --------------------------------------

@mixin set-button-height($height: null, $sides: $btn-side-padding) {
    @if $height and unit($height) == "px" {
        line-height: 1em; // this value allows the math below to work...
        padding: rem(math.div($height - $btn-border-width * 2 - $btn-font-size, 2)) $sides;
        min-height: rem($height);
    }
}
