.pt_categorylanding {
    #main {
        // override content-width for full bleed
        @include override-content-width;
    }

    .primary-content {
        padding-bottom: 0;
    }

    #secondary {
        display: none;

        @include media-breakpoint-up(md) {
            display: block;
        }
    }
}

.pt_landing {
    .refinements {
        display: none;
        box-sizing: border-box;

        @include media-breakpoint-up(md) {
            display: block;
        }
    }

    .primary-content {
        padding-bottom: 0;

        @include media-breakpoint-up(md) {
            padding-left: rem(40px);
        }
    }
}

.bottom-banner-container {
    overflow: hidden;

    .bottom-banner-cell {
        float: left;
        overflow: hidden;
        text-align: center;

        img {
            @include media-breakpoint-only(md) {
                max-width: 100%;
            }
        }
    }

    .banner-cell-1 {
        width: 33%;
    }

    .banner-cell-2 {
        width: 34%;
    }

    .banner-cell-3 {
        width: 33%;
    }
}

.primary-callout-text {
    margin-top: rem(20px);
    padding: 0 rem(20px);

    @include media-breakpoint-up(md) {
        margin-top: rem(40px);
        padding: 0;
        width: 85%;
    }
}

.mobile-refinement-buttons {
    text-align: center;

    .refinement-header {
        border: none;
        cursor: default;
        padding: rem(10px) 0 rem(20px);

        @include type-h3;

        &.expanded {
            padding-bottom: rem(20px);
        }

        &::after {
            content: none;
        }

        & + ul {
            display: block;
        }
    }

    #category-level-1 {
        .refinement-link {
            @include button;
            @include tertiary-button;

            width: 100%;
        }

        .expandable {
            .refinement-link {
                @include button;
                @include tertiary-button;

                width: 100%;
            }
        }
    }

    .refinement {
        border: 0 none;

        &[class*="-refinement"] {
            display: none;
        }

        &.category-refinement {
            display: block;
        }
    }

    @include media-breakpoint-up(md) {
        display: none;
    }
}
