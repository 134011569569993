.sizinginformation {
    padding: 10px;
    border: none;

    @include media-breakpoint-down(md) {
        padding: 0;
    }

    th {
        background: $section-header-bg;

        @include media-breakpoint-down(md) {
            @include font-reg;

            padding: 0 0 0 2px;
        }
    }

    td,
    th {
        padding: 10px;
        border-bottom: 1px solid $section-header-bg;
    }

    td {
        border-left: 1px solid $section-header-bg;

        @include media-breakpoint-down(md) {
            padding: 5px;
            text-align: center;
            background: $white;
        }
    }

    .ui-tabs-nav {
        height: 33px;
    }

    .sizing-charts {
        border: none;
    }

    .sizechart {
        @include media-breakpoint-down(md) {
            padding: 0 !important;
        }
    }

    .tips-list {
        padding: 0 !important;

        li {
            list-style: none !important;
        }
    }
}
