@use "sass:color";

.pt_account,
.pt_order,
.pt_customer-service {
    .page-content {
        display: flex;
        flex-wrap: wrap;
    }

    .breadcrumb {
        flex-basis: 100%;
    }

    .primary-content {
        @include media-breakpoint-down(sm) {
            margin-top: 15px;
        }
    }

    #secondary {
        @include font-reg($body-text-size);
    }

    // custom registered styles
    #main.registered {
        background: $account-bg;
        padding: 0;
        margin-top: rem(10px);
        width: 100%;
        max-width: 100%;

        @include media-breakpoint-down(md) {
            padding: $page-padding-registered-mobile;
        }

        .account-nav-registered {
            margin: 0;
            border-top: none;

            @include media-breakpoint-up(md) {
                margin: 0 0 rem(25px);
            }
        }
    }
}

// unregistered user navigation
.nav {
    .secondary-navigation {
        @include font-reg($body-text-size);

        .toggle {
            @include type-h6;

            letter-spacing: 0.2px;
            cursor: pointer;
        }

        ul {
            margin: 0 0 rem(25px);

            li {
                margin: 0;
                padding: 0;
            }
        }

        a {
            @include font-reg($body-text-size);
        }
    }
}

.account-nav-unregistered {
    margin: rem(10px) 0;

    @include media-breakpoint-up(md) {
        border-top: $border2;
        margin: rem(20px) 0;
    }
}

.account-nav-asset {
    border-top: $border2;

    @include font-reg($body-text-size);

    h2 {
        @include type-h6;
    }

    a {
        @include type-lone-link;
    }
}

// registered navigation styles
.account-nav-desktop-top {
    // the account level-1 desktop navigation
    background: $panel-bg;
    color: $inverted-text-color;
    height: rem(60px);
    width: 100%;

    .inner-menu {
        display: flex;
        justify-content: space-between;
        max-width: $max-content-breakpoint;
        margin: 0 auto;
    }

    // user off-canvas nav elements reused here
    .level-1-header {
        display: flex;
        padding: 0;
        background: $trans;

        @include line-height(60px);

        &:hover:not(.welcome-user),
        &.active {
            background-color: $active-panel-bg;
        }

        .icon {
            fill: $inverted-text-color;
            position: relative;
            top: -3px;
        }

        >a,
        &.welcome-user {
            padding: 0 rem(20px);
        }

        &.welcome-user {
            display: inline-block;
        }

        a,
        &.welcome-user span {
            @include type-h5;
        }

        .account-logout a {
            @include font-reg($body-text-size);

            color: $inverted-text-color;
            padding-left: rem(20px);

            &:hover {
                color: $inverted-text-color;
            }
        }
    }

    ul {
        display: none;
    }

    .account-nav-unregistered {
        display: none !important; // never show this
    }
}

// account level-2 navigation
.account-nav-desktop-bottom {
    .welcome-user,
    .account-message {
        display: none;
    }

    @include media-breakpoint-up(lg) {
        @include content-width;

        .back {
            display: none;
        }
    }

    .level-1-header {
        display: none;

        &.active {
            display: block;
        }

        ul {
            display: none;
        }

        @include media-breakpoint-up(lg) {
            ul {
                list-style: none;
                display: flex;
                padding: 0;

                li {
                    padding: 0 rem(50px) 0 0;
                    flex: 0 0 auto;
                }

                li a {
                    @include type-account-nav;

                    &:hover {
                        @include type-active-account-nav;
                    }
                }

                li.active a,
                li.active a:hover {
                    @include type-active-account-nav;
                }
            }
        }
    }
}

// Registered Users Custom Layout Styles
#main.registered,
:not(.registered) #accountGuardPage {
    // account body
    .account-body {
        margin-bottom: rem(10px);
        display: flex;
        flex-flow: column wrap;

        @include media-breakpoint-up(lg) {
            @include content-width;

            flex-direction: row;
            justify-content: space-between;
        }
    }

    .ag-result-container.full-width {
        width: 100%;
    }

    // inset white elements
    .account-module:not(.no-shadow) {
        padding: rem(20px);
        border-radius: $container-border-radius;
        box-shadow: $shadow2;

        @include media-breakpoint-up(lg) {
            padding: rem(40px);
        }
    }

    .account-module {
        background: $body-bg;
        box-sizing: border-box;
        margin: rem(15px) 0;
        overflow: auto;
        order: attr(data-order);
        width: 100%;

        @include media-breakpoint-up(lg) {
            width: calc(50% - 15px);
            order: 0;
        }

        &.first-in-mobile-order {
            @include media-breakpoint-down(md) {
                order: 1;
            }
        }

        &.second-in-mobile-order {
            @include media-breakpoint-down(md) {
                order: 2;
            }
        }

        h2,
        h3,
        h4 {
            margin-top: 0;
            margin-bottom: rem(10px);

            &.no-margin {
                margin: 0;
            }

            &.secondary {
                margin-top: rem(40px);
            }
        }

        &.full-width {
            width: 100%;

            h4 {
                margin-bottom: rem(20px);

                &.no-margin {
                    margin: 0;
                }
            }
        }

        &.full-click {
            padding: 0;

            .edit-link {
                padding: rem(40px);
                box-sizing: border-box;
                margin-right: rem(40px);
                width: calc(100% - #{rem(40px)});
            }

            .account-module-content {
                padding: 0 rem(40px) rem(40px) rem(40px);
            }
        }

        &.manage-credit-card {
            h4 {
                @include type-h4;

                margin: rem(10px) 0 0;
            }

            .button {
                margin-top: rem(20px);

                @include responsive-button(260px);
            }
        }

        .enrolled-alert {
            @include font-bold;

            color: $brand-primary;
        }

        .terms-and-conditions {
            @include type-lone-link;
        }

        address {
            @include type-address-text;
        }

        .account-message {
            margin-bottom: rem(25px);
        }

        .scrollable {
            max-height: rem(372px);
            overflow-y: auto;
            overflow-x: hidden;
        }
    }

    .account-module.ag-account-registration {
        background-color: #ebebeb;

        .section-header {
            @include type-h4;

            padding: 0;
        }

        @include media-breakpoint-up(lg) {
            padding: rem(24px) rem(40px) rem(24px) rem(24px);

            .ag-account-form .two-col-per-row {
                margin-right: -1rem;
                width: calc(100% + 1rem);
            }

            .ag-account-form .two-col-per-row .form-row {
                display: inline-block;
                vertical-align: top;
                width: calc(50% - 0.5rem);

                .field-wrapper {
                    width: 100%;
                }

                label span {
                    white-space: nowrap;
                }

                span.error-icon {
                    margin-top: -0.5rem;
                }
            }

            .ag-account-form .two-col-per-row .form-row:first-of-type {
                margin-right: 0.5rem;
            }

            .button-fancy-large {
                padding-left: 3rem;
                padding-right: 3rem;
            }
        }
    }

    .universal-drawer {
        &.account-module {
            padding: 0;

            h4 {
                margin-bottom: 0;
            }
        }
    }

    .manage-order {
        .modify-payment {
            @include media-breakpoint-up(lg) {
                &:first-child {
                    order: 2;
                }

                &:nth-child(2) {
                    order: 1;
                }

                &:last-child {
                    order: 3;
                }
            }
        }
    }
}

.account-menu-select {
    @extend %select-style;

    display: block;
    padding-left: 10px;
    margin-top: 10px;

    &.open {
        background-image: url("../images/svg-icons/arrow-up.svg");
    }

    @include media-breakpoint-up(md) {
        display: none;
    }
}

.account-menu {
    @include media-breakpoint-down(sm) {
        display: none;
        padding: 15px;
        border: $border2;
        margin: 10px 0;
    }

    @include media-breakpoint-up(md) {
        display: block !important;
    }

    .account-nav-asset {
        @include media-breakpoint-down(sm) {
            margin: 30px 0 0;
        }
    }
}

#PasswordResetForm {
    fieldset {
        position: relative;
    }

    button {
        margin-top: rem(20px);

        @include button;
        @include responsive-button(340px);
    }
}

h1 {
    .account-logout {
        @include type-account-logout-link;

        float: none;
    }
}

.registration-selection {
    input,
    label {
        display: inline;
        float: none;
    }
}

.account-registration {
    h2 {
        @include type-h4;
    }

    .dialog-required {
        display: block;
        margin-bottom: rem(25px);
    }

    .checkout-guest-create-account {
        .dialog-required {
            display: inline;
            margin: 0;
        }
    }

    form {
        @include media-breakpoint-up(md) {
            float: left;
            width: 70%;
        }
    }

    .form-row {
        @include media-breakpoint-up(md) {
            &.states,
            &.postal {
                float: left;
                width: 48%;
            }

            &.postal {
                margin-left: 4%;
            }
        }

        .field-wrapper {
            width: 100%;
        }

        &.has-tooltip {
            .field-wrapper {
                width: 94%;
            }

            .form-field-tooltip {
                width: 5%;
            }
        }
    }

    .form-row-button {
        margin-top: rem(25px);

        button {
            width: 100%;

            @include media-breakpoint-up(md) {
                width: inherit;
            }
        }
    }

    .account-registration-right {
        @include media-breakpoint-up(md) {
            float: right;
            width: 25%;
        }
    }
}

.address-list {
    padding: 0;
    margin-top: rem(10px);
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(md) {
        flex-flow: row wrap;
    }

    li {
        list-style: none;
        float: none;
        width: auto;
        padding: 0 0 rem(10px);
        margin: rem(10px) 0 0;
        border-bottom: $border5;

        &:last-child {
            border: 0;
        }

        @include media-breakpoint-up(md) {
            min-height: 135px;
            margin: 0 rem(20px);
            width: 30%;
            border: 0;

            &:first-child {
                margin-left: 0;
            }

            &:nth-child(3n+3) {
                margin-right: 0;
            }

            &:nth-child(3n + 1) {
                margin-left: 0;
            }
        }
    }

    a:not(.button) {
        @include type-lone-link;
    }

    .address-controls {
        a:not(.button) {
            margin: 0 rem(20px);

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .mini-address-title {
        @include type-address-list-title;
    }

    .address-make-default {
        @include visually-hidden;
    }
}

.button.address-create {
    @include responsive-button(rem(340px));
}

.paymentslist .update-message {
    color: $success-color;

    .icon {
        fill: $success-color;
        position: relative;
        top: -3px;
    }
}

.payment-list {
    overflow: hidden;
    padding: 0;

    li {
        list-style: none;
        margin: 0;
        padding: rem(25px) 0;
        display: flex;
        flex-direction: column;
        border-top: $border5;

        &:first-child {
            padding-top: 0;
            border-top: 0;
        }

        @include media-breakpoint-up(md) {
            flex-direction: row;
            align-content: space-between;
        }

        &.warning-message-row {
            padding: rem(10px);
            background: $warning-color;
            width: 100%;

            div {
                display: flex;
            }

            span:first-of-type {
                padding-right: rem(10px);

                .icon {
                    position: relative;
                    top: -3px;
                }

                & + span {
                    top: 3px;
                }
            }

            span {
                @include font-bold;

                line-height: normal;
                position: relative;
            }
        }

        .cc-type-number,
        .cc-expires {
            width: 25%;
            display: flex;
            flex-direction: row;
            line-height: 2.7em;
            margin-bottom: rem(10px);

            @include media-breakpoint-up(md) {
                margin-bottom: 0;
            }

            @include respond(large) {
                width: 30%;
            }

            .cc-type {
                margin-right: rem(20px);
            }

            @include media-breakpoint-up(md) {
                .cc-number {
                    margin-right: rem(50px);
                }
            }
        }

        .cc-expires {
            @include media-breakpoint-up(lg) {
                margin-left: -5rem;
                width: auto;
            }
        }

        form[name="payment-update"],
        form[name="payment-remove"] {
            display: flex;
            flex-direction: column;

            @include media-breakpoint-up(md) {
                flex-direction: row;
                align-content: space-between;
            }

            fieldset {
                width: 100%;
            }

            .form-row label {
                display: none;
            }

            .field-wrapper {
                width: 100%;
            }

            button {
                text-align: left;
            }

            .form-row.month,
            .form-row.year,
            button[name*="paymentinstruments_creditcards_storedcards"] {
                @include media-breakpoint-up(md) {
                    margin: 0 1%;
                }
            }

            button[name*="paymentinstruments_creditcards_storedcards"] {
                line-height: 1.5em;

                @include media-breakpoint-up(md) {
                    margin-left: rem(25px);
                }
            }
        }

        form[name="payment-update"] {
            @include media-breakpoint-up(md) {
                width: 60%;
            }

            button.error-icon {
                color: $error-color;
                padding-left: rem(25px);

                span::before {
                    content: " ";

                    @include type-error-icon;

                    display: inline-block;
                    margin-left: rem(-25px);
                }
            }
        }

        form[name="payment-remove"] {
            @include media-breakpoint-up(md) {
                width: 15%;
            }
        }
    }
}

.manage-credit-card {
    .credit-card-info {
        display: flex;
        flex-direction: row;
        margin-top: rem(10px);

        .cc-type,
        .cc-number {
            margin-right: rem(5px);
        }
    }

    .label-recent-used {
        margin-right: rem(25px);
    }
}

#CreditCardForm {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(md) {
        flex-flow: row wrap;
        justify-content: flex-start;
    }

    .field-wrapper {
        width: 100%;
    }

    .form-row {
        margin: rem(25px) 0 0;

        @include media-breakpoint-up(md) {
            margin: rem(10px) 2% 0;

            &.cc-type {
                margin-left: 0;
                max-width: 20%;
            }

            &.year {
                margin-right: 0;
            }
        }

        &.creditcard-number {
            @include media-breakpoint-up(md) {
                min-width: 25%;
            }
        }
    }

    .form-label-text {
        display: none;
    }

    .form-row-button {
        flex-basis: 100%;
        margin-left: 0;
        margin-right: 0;

        button {
            margin-top: rem(20px);

            @include responsive-button(rem(340px));
        }
    }
}

.payment-method-type {
    display: none;

    .credit-card-fields {
        display: none;

        .cvn {
            position: relative;
            padding-right: rem(30px);
            width: 25%;

            .error-message {
                width: 150%;
            }

            .field-wrapper {
                width: 100%;
            }

            .form-field-tooltip {
                position: absolute;
                right: 0;
                top: 20px;
                width: auto;
                padding: 0;
                margin: 0;
            }

            @include media-breakpoint-down(md) {
                width: 50%;

                .error-message {
                    width: 200%;
                }
            }
        }
    }

    .input-with-tooltip {
        .tooltip {
            top: 22px;
        }
    }
}

.order-history-header {
    overflow: hidden;
    padding: 10px;
    background: $section-header-bg url("../images/interface/icon-double-line-header.png") repeat-x scroll left top;

    .label {
        @include type-order-history-header-label;
    }

    button {
        @include type-order-history-header-button;

        float: right;

        @include media-breakpoint-down(md) {
            padding: 0.25em;
        }
    }

    .order-date,
    .order-status,
    .order-number {
        float: left;
        width: 60%;
        margin: 2px 0;
    }
}

.svc-result-items {
    margin: 20px 0;

    li {
        width: 100%;
        padding: 20px 0;
        float: none;

        .order-history-item {
            position: relative;

            .order-details {
                position: relative;
                width: 100%;
                height: 0;
                clear: both;

                &.progress {
                    height: 50px;
                    overflow: hidden;
                    transition: height 0.3s ease;
                }

                &.loaded {
                    height: auto;
                    overflow: visible;
                }

                .line-item-actions {
                    button,
                    a {
                        display: block;
                    }
                }
            }
        }
    }
}

.order-history-table {
    th,
    td {
        padding: 5px 10px;
    }

    ul {
        padding: 0;
        margin: 0;
    }

    li {
        list-style: none;
    }

    .order-shipped-to,
    .order-items,
    .order-total-price {
        width: 33%;
    }

    .toggle {
        display: block;
        cursor: pointer;
    }
}

.js {
    .order-history-table {
        .hidden {
            display: none;
        }
    }
}

.facebook-registration {
    position: relative;
    left: -3px;
    width: 100.7% !important;

    .fb_iframe_widget {
        position: relative;
        width: 100.7% !important;

        span {
            position: relative;
            width: 100.7% !important;

            iframe {
                position: relative;
                width: 100.7% !important;
            }
        }
    }

    @include media-breakpoint-up(md) {
        left: 0;
        width: 100% !important;

        .fb_iframe_widget {
            width: 100% !important;

            span {
                width: 100% !important;

                iframe {
                    width: 100% !important;
                }
            }
        }
    }
}

.manage-password {
    button[name$="resetpassword_send"] {
        @include button;

        margin-bottom: rem(20px);

        @include responsive-button(rem(300px));

        @include media-breakpoint-up(md) {
            margin-right: rem(30px);
        }
    }

    a:not(.button) {
        @include type-lone-link;
    }

    @include media-breakpoint-down(md) {
        .success-form {
            margin-top: rem(20px);
        }
    }
}

.account-email-preferences .account-module {
    position: relative;
}

.account-module .manage-password {
    .button {
        @include responsive-button(rem(300px));
    }
}

.account-module.billing-address {
    .button {
        margin-top: rem(20px);

        @include responsive-button(rem(300px));
    }
}

.buynow-paylater {
    .account-module & {
        .card-subheading {
            display: flex;
            align-items: center;

            > img {
                width: 70px;
            }

            h4 {
                margin-left: rem(25px);
            }
        }

        a {
            @include type-lone-link;
        }
    }
}

.account-module-unlinked {
    &.full-width {
        width: 100%;

        .col-1 {
            margin-right: 4%;

            .account-welcome {
                margin-bottom: rem(20px);

                @include media-breakpoint-up(md) {
                    margin-bottom: 0;
                }

                .welcome-user {
                    display: block;
                    margin-bottom: rem(10px);

                    @include type-h2;

                    @include media-breakpoint-up(md) {
                        margin-top: 0;
                    }
                }
            }
        }
    }
}

.account-summary-linked {
    .account-personalized-message {
        position: relative;
        top: rem(-20px);
        left: rem(-20px);
        background: $personalized-message-bg;
        color: $personalized-message-color;
        padding: rem(10px) rem(20px);
        margin-top: 0;

        @include font-bold($body-text-size);
        @include line-height(20px);

        letter-spacing: $personalized-message-letter-spacing;
        width: 100%;
        text-align: center;

        @include media-breakpoint-up(lg) {
            top: rem(-40px);
            left: rem(-40px);
            margin-bottom: rem(-20px);
            width: calc(100% + #{rem(40px)});
        }

        &.success {
            background: $personalized-message-success-bg;

            .personalized-message {
                &::before {
                    background-image: url("../images/svg-icons/tooltip-icon.svg");
                }
            }
        }

        &.warning {
            background: $personalized-message-warning-bg;
            color: $personalized-message-warning-color;

            .personalized-message {
                &::before {
                    background-image: url("../images/svg-icons/error-icon.svg");
                }
            }
        }

        .personalized-message {
            text-align: left;
            display: inline-block;
            position: relative;
            padding-left: rem(30px);

            &::before {
                content: "";
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                width: rem(20px);
                height: rem(20px);
                background: url("../images/svg-icons/tooltip-info-white.svg") no-repeat center;
                background-size: contain;
            }
        }
    }

    .account-name {
        @include type-h2;

        padding-bottom: rem(3px);
        border-bottom: $border2;
    }

    .account-plan-title {
        background: $lighter-gray;

        @include type-label;
        @include line-height(30px);

        padding: 0 rem(10px);
        text-align: center;

        @include media-breakpoint-up(lg) {
            text-align: left;
        }
    }

    .account-summary-items {
        margin-top: rem(10px);
        display: flex;
        flex-flow: row wrap;

        &.account-plan-info {
            @include media-breakpoint-up(lg) {
                margin-top: rem(13px);
            }
        }

        & > div {
            width: 100%;
            box-sizing: border-box;
            margin-bottom: rem(12px);

            @include media-breakpoint-up(lg) {
                width: 50%;
            }

            &.account-plan-available-credit {
                text-align: center;

                @include media-breakpoint-up(lg) {
                    width: calc(100% / 3);
                    text-align: left;
                    padding: 0 rem(10px);
                }

                .value {
                    @include type-h2;
                }

                & ~ div {
                    width: 50%;
                    text-align: center;

                    @include media-breakpoint-up(lg) {
                        width: calc(100% / 3);
                        text-align: left;
                        padding: 0 rem(10px);
                    }

                    .value {
                        @include font-size(18px);

                        @include media-breakpoint-up(lg) {
                            @include font-size(20px);

                            padding-top: rem(7px);
                        }
                    }
                }
            }
        }

        .label {
            @include type-label;

            margin-bottom: rem(4px);
        }
    }
}

form[id*="ordertrack"] {
    .account-module & {
        margin-top: rem(15px);
    }
}

form[id*="profile_addaccount"] {
    .account-module & {
        .field-wrapper {
            width: 100%;

            @include media-breakpoint-up(lg) {
                width: rem(360px);
            }
        }

        .postal {
            .field-wrapper {
                @include media-breakpoint-up(lg) {
                    width: rem(160px);
                }
            }
        }

        .form-row-button {
            display: flex;
            flex-direction: column;
            align-items: center;

            @include media-breakpoint-up(lg) {
                flex-direction: row;
            }

            .privacy-policy,
            .remove-account {
                margin-left: 0;
                margin-top: rem(10px);

                @include media-breakpoint-up(lg) {
                    margin-left: rem(30px);
                    margin-top: 0;
                }
            }
        }
    }
}

#verify-account {
    text-align: left;

    p {
        font-family: "Nunito Sans", sans-serif;
        font-weight: 400;
        color: #393939;
        font-size: 15px;
    }

    button {
        font-family: "Nunito Sans", sans-serif;
        font-weight: 700;
        color: #fff;
        font-size: 15px;
        background-color: #d71921;
        line-height: 1;
        max-width: 210px;
        text-align: center;
        align-items: center;
        justify-content: center;
        padding: 0 4em;
        margin-right: 4em;
    }
}

.ui-dialog form[name$="profile_address"] {
    .field-wrapper {
        width: 100%;
    }

    .has-tooltip {
        .field-wrapper {
            width: 90%;
        }

        .form-field-tooltip {
            width: 5%;
        }
    }

    .form-row-button {
        button {
            box-sizing: border-box;
            margin: 0 0 rem(5px);

            @include responsive-button(48%);

            @include media-breakpoint-up(md) {
                margin: 0 1%;

                &:first-child {
                    margin-left: 0;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        button.cancel-button {
            @include tertiary-button;
        }

        &.with-delete {
            button {
                margin: 0 0 rem(5px);

                @include responsive-button(32%);

                @include media-breakpoint-up(md) {
                    margin: 0 0.5%;

                    &:first-child {
                        margin-left: 0;
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}

.account-module {
    &.full-width {
        .order-status {
            .form-row {
                @include media-breakpoint-up(lg) {
                    float: left;
                    margin-right: rem(30px);
                }

                .field-wrapper {
                    width: 100%;
                    max-width: 100%;
                }

                @include media-breakpoint-up(lg) {
                    &.postal {
                        width: rem(160px);
                        margin-right: rem(40px);
                    }

                    &:not(.postal) {
                        width: 23%;
                    }
                }
            }

            .form-row-button {
                margin-top: 0;
                margin-bottom: 0;

                @include media-breakpoint-down(sm) {
                    margin-top: 25px;
                }
            }

            button[name*="ordertrack_findorder"] {
                width: 100%;

                @include media-breakpoint-up(lg) {
                    width: rem(320px);
                }

                margin-top: 25px;
                margin-right: 0;
            }
        }
    }

    .order-status {
        .form-row {
            .field-wrapper {
                width: 100%;
            }

            &.postal {
                .field-wrapper {
                    @include media-breakpoint-up(lg) {
                        max-width: rem(180px);
                    }
                }
            }

            &:not(.postal) {
                .field-wrapper {
                    @include media-breakpoint-up(lg) {
                        width: rem(360px);
                    }
                }
            }
        }
    }

    h4 {
        span {
            @include font-bold($body-text-size);

            @include media-breakpoint-down(md) {
                display: block;
            }
        }
    }

    .account-module-link {
        margin-bottom: rem(20px);
    }

    .account-module-actions {
        cursor: pointer;

        h4 {
            float: left;
            margin-bottom: 0 !important;
        }

        .edit-link {
            display: inline-block;
            background: url("../images/svg-icons/arrow-down.svg") no-repeat right center;
            padding: 0 rem(20px);
            float: right;
            margin-top: 0;
            text-align: left;
            width: calc(100% - 20px);
        }
    }

    .account-module-content {
        margin-top: rem(20px);
        display: none;

        .success {
            color: $success-color;
            margin-bottom: rem(10px);

            @include line-height(18px);

            display: flex;

            .icon {
                fill: $success-color;
                padding-right: rem(8px);
                position: relative;
                top: -3px;
            }
        }

        .error-form {
            @include line-height(18px);

            .icon {
                vertical-align: top;
            }
        }

        h5 {
            margin: 0 0 rem(10px);
        }

        .current-email {
            margin: rem(15px) 0 rem(30px);
            line-height: rem(20px);

            .value {
                @include font-bold($body-text-size);
            }

            a {
                margin-left: rem(10px);
            }
        }

        .existing-emails {
            margin-bottom: rem(10px);

            a {
                margin-left: rem(10px);
            }

            & + form {
                margin-top: rem(20px);
            }
        }

        .form-row {
            &.form-row-button {
                .cancel {
                    margin-left: rem(20px);
                }
            }
        }
    }

    &.active {
        .account-module-actions {
            .edit-link {
                background-image: url("../images/svg-icons/arrow-up.svg");
            }
        }

        .account-module-content {
            display: block;
        }
    }

    .account-module-row {
        margin-bottom: rem(8px);

        @include line-height(22px);

        overflow: hidden;

        .label {
            @include type-label;

            min-width: rem(150px);
            display: inline-block;

            @include media-breakpoint-down(sm) {
                width: 100%;
                display: block;
            }
        }

        .value {
            // to accomodate .label mid width
            width: calc(100% - 150px);

            @include media-breakpoint-down(sm) {
                width: 100%;
                display: block;
            }

            .order-tracking-value {
                @include media-breakpoint-down(sm) {
                    float: right;
                    text-align: right;
                }
            }
        }

        .field-wrapper {
            width: 100%;

            @include media-breakpoint-up(lg) {
                max-width: rem(360px);
            }
        }
    }

    .account-module-list-items {
        .list-item {
            display: flex;
            flex-flow: row wrap;
            padding: rem(5px) rem(10px);

            @include media-breakpoint-up(lg) {
                justify-content: space-between;
            }

            &.list-item-title {
                @include type-label;

                margin-bottom: rem(3px);

                @include media-breakpoint-down(md) {
                    display: none;
                }
            }

            &.even {
                background: $lighter-gray;
            }

            .item {
                box-sizing: border-box;

                @include line-height(22px);

                @include media-breakpoint-down(md) {
                    width: 100%;
                }
            }
        }
    }

    .account-module-button-row {
        margin: rem(20px) 0;

        &.buttons-row {
            .button {
                @include media-breakpoint-up(lg) {
                    width: calc(50% - 15px);
                    float: left;
                    margin-right: rem(15px);
                }
            }
        }

        .button {
            padding-left: 0;
            padding-right: 0;
            width: 100%;

            @include media-breakpoint-up(lg) {
                max-width: rem(260px);
            }

            & + .button {
                @include media-breakpoint-down(md) {
                    margin-top: rem(20px);
                }
            }
        }

        form {
            & + .button {
                @include media-breakpoint-down(md) {
                    margin-top: rem(20px);
                }
            }
        }
    }

    .make-payment {
        .make-payment-message {
            @include type-label;
            @include line-height(22px);

            margin-bottom: rem(15px);
        }

        .make-payment-autopay-enroll-msg {
            margin-bottom: rem(2px);

            @include line-height(22px);
        }

        &.make-payment-include {
            @include media-breakpoint-up(xl) {
                width: rem(260px);
                float: left;
            }

            & + .content-asset {
                margin-top: rem(20px);
                padding: rem(20px);
                background: $lighter-gray;

                @include media-breakpoint-up(xl) {
                    float: right;
                    margin-top: 0;
                    width: rem(260px);
                    box-sizing: border-box;
                    min-height: rem(254px);
                }

                .subtitle {
                    &.secondary {
                        margin-top: rem(10px);
                    }
                }
            }
        }
    }

    .payment-confirmation {
        .print-or-save-message {
            margin-bottom: rem(15px);
            margin-top: rem(15px);
        }

        .next-pay-callout {
            margin-top: rem(30px);
        }

        .return-button {
            margin-top: rem(30px);
            margin-bottom: rem(20px);
        }

        .account-module-row {
            .label {
                min-width: rem(200px);
            }

            .value {
                width: calc(100% - #{rem(200px)});
            }
        }
    }

    .transaction-detail {
        .account-module-list-items {
            .item {
                @include media-breakpoint-up(lg) {
                    width: calc(50% - #{rem(40px)});
                }

                &.last {
                    text-align: right;

                    @include font-bold($body-text-size);

                    @include media-breakpoint-up(lg) {
                        width: rem(80px);
                    }
                }

                &.first {
                    & ~ .item {
                        @include media-breakpoint-down(md) {
                            width: 50%;
                        }
                    }
                }
            }
        }
    }
}

.account-payment-mailing-address {
    line-height: 1.47;

    .subtitle {
        @include font-bold($body-text-size);
    }

    .content-width & {
        @include media-breakpoint-up(sm) {
            display: flex;
        }

        .col {
            min-width: rem(200px);
        }
    }
}

.change-plan {
    .plan-item {
        margin-bottom: rem(20px);
    }

    .change-plan-buttons-row {
        padding-top: rem(20px);
        border-top: $border2;
    }

    .button {
        padding-left: 0;
        padding-right: 0;
        width: 100%;

        @include media-breakpoint-up(lg) {
            max-width: rem(260px);
        }
    }
}

.list-items {
    .list-item {
        margin-bottom: rem(30px);
        background: $orderhistory-bg-contrast;
        display: flex;
        flex-direction: column;
        padding: rem(10px) 0;

        @include media-breakpoint-up(md) {
            flex-direction: row;
            justify-content: space-between;
        }

        .item {
            box-sizing: border-box;
            padding: 0 rem(10px);

            @include line-height(22px);

            .label {
                @include media-breakpoint-down(sm) {
                    width: 50%;
                    float: left;
                }
            }

            .value {
                @include font-bold($body-text-size);

                @include media-breakpoint-down(sm) {
                    width: 50%;
                    float: right;
                }
            }

            &.item-actions {
                @include media-breakpoint-down(sm) {
                    border-top: $border6;
                    padding-top: rem(10px);
                    text-align: center;
                }

                @include media-breakpoint-up(md) {
                    @include line-height(44px);

                    text-align: right;
                }
            }
        }
    }

    &.row-4 {
        .item {
            @include media-breakpoint-up(md) {
                width: calc(100% / 4);
            }
        }
    }

    &.row-5 {
        .item {
            @include media-breakpoint-up(md) {
                width: calc(100% / 5);
            }
        }
    }

    &.row-6 {
        .item {
            @include media-breakpoint-up(md) {
                width: calc(100% / 6);
            }
        }
    }
}

.make-payment,
.autopayment-wrapper {
    .payment-amount {
        margin-bottom: rem(30px);

        @include media-breakpoint-up(md) {
            margin-bottom: rem(40px);
        }
    }

    .payment-methods {
        .month,
        .day,
        .year {
            display: inline-block;
            width: 15%;

            label {
                display: none;
            }

            .field-wrapper {
                width: 100%;
            }

            @include media-breakpoint-down(md) {
                width: 100%;
            }
        }

        .month {
            label {
                display: block;
            }
        }

        .year {
            margin-left: 10px !important;
        }

        .expiration-date {
            .month {
                >label {
                    width: 100%;

                    @include media-breakpoint-up(md) {
                        width: 150%;
                    }
                }
            }
        }
    }

    .pending-payment-message {
        margin-bottom: rem(15px);

        @include media-breakpoint-up(md) {
            margin-bottom: rem(25px);
        }
    }

    .payment-row {
        padding: rem(20px) 0;
        border-bottom: $border2;

        &.other-payment {
            padding: rem(10px) 0;

            label {
                float: left;
                width: rem(160px);

                @include line-height(40px);
            }

            .form-row {
                float: left;
                width: calc(100% - #{rem(160px)});

                label {
                    display: none;
                }

                .field-wrapper {
                    width: 100%;

                    .input-text {
                        display: block;
                        max-width: rem(120px);
                    }
                }
            }
        }

        label {
            margin: 0;

            .due-on {
                @include media-breakpoint-down(sm) {
                    display: block;
                    padding-left: rem(30px);
                }
            }
        }

        .form-row {
            margin: 0;
        }
    }

    .form-row-button {
        margin: rem(30px) 0 0;

        @include media-breakpoint-up(md) {
            margin-top: rem(40px);
        }
    }

    .payment-method-type {
        padding: 20px 0 0;

        .payment-method-type-row {
            margin-bottom: rem(15px);

            .dash {
                @include media-breakpoint-down(sm) {
                    display: none;
                }
            }

            .expires-label {
                @include media-breakpoint-down(sm) {
                    display: block;
                    padding-left: rem(30px);
                }
            }
        }

        .credit-card-fields {
            padding: 20px 0 0 rem(27px);
        }

        .form-row {
            margin: 0 0 rem(20px);

            .button-fancy-large {
                border: 2px solid $brand-primary;
                background-color: $brand-primary;
                color: #fff;

                &:hover {
                    border-color: $brand-secondary;
                    background-color: $brand-secondary;
                    color: #fff;
                }
            }

            &.cvn {
                width: 100%;
                max-width: rem(220px);
                box-sizing: border-box;

                .form-field-tooltip {
                    top: rem(27px);
                }
            }

            &.checkbox {
                margin: rem(20px) 0;
            }

            label {
                margin-bottom: rem(7px);
            }
        }

        .ach-form-container {
            margin-top: rem(10px);
        }

        .ach-name-fields {
            margin-top: rem(20px);

            .form-row,
            .formfield {
                label {
                    font-family: inherit;

                    &.error {
                        padding-left: 25px;
                        position: relative;

                        .error-icon {
                            position: absolute;
                            top: -2px;
                            left: 0;
                        }
                    }
                }

                &:nth-of-type(1) {
                    width: calc(100% - 52%);
                    margin-right: 2%;
                    display: inline-block;
                    vertical-align: top;
                    float: left;
                }

                &:nth-of-type(2) {
                    width: calc(100% - 52%);
                    margin-left: -24%;
                    display: inline-block;
                    vertical-align: top;

                    @include media-breakpoint-down(sm) {
                        margin-left: 0;
                    }
                }
            }
        }

        .ach-type-container {
            .form-row,
            .formfield {
                width: 100%;
                display: inline-block;
                vertical-align: top;
            }
        }

        .ach-new-account-label {
            padding-top: 20px;
        }
    }
}

// CR#5 customer service mobile changs
.pt_customer-service {
    @include media-breakpoint-down(sm) {
        #main {
            display: flex;
        }

        #secondary {
            order: 2;
        }

        #primary {
            margin-bottom: rem(15px);
        }
    }

    @include media-breakpoint-up(md) {
        .breadcrumb {
            margin-bottom: rem(15px);
        }
    }
}

.autopayment-wrapper {
    .otp-payment-wrapper {
        margin-bottom: rem(40px);

        .payment-row {
            padding-bottom: rem(20px);
            margin-bottom: rem(20px);
            border-bottom: $border2;

            .form-row,
            label {
                margin-bottom: 0;
            }
        }

        .other-payment {
            label,
            .field-wrapper,
            .form-row {
                display: inline-block;
            }

            .field-wrapper {
                width: 150px;
                margin-left: rem(10px);
            }
        }
    }

    .opt-payment-amount {
        position: relative;

        .otp-due {
            position: absolute;
            right: 0;

            @include font-bold;
        }
    }

    .ap-payments-section {
        margin-bottom: rem(40px);
    }

    .payment-methods {
        margin-bottom: rem(25px);
        padding-bottom: rem(25px);

        >label {
            margin-bottom: 0;
            position: relative;
            padding-left: rem(30px);
            line-height: 1.3em;

            &::before {
                position: absolute;
                left: 0;
            }
        }

        .payment-method-type {
            padding-left: rem(30px);
            padding-top: rem(15px);

            >label {
                margin-bottom: rem(15px);
                position: relative;
                padding-left: rem(30px);
                line-height: 1.3em;

                &:last-of-type {
                    margin-bottom: 0;
                }

                &::before {
                    position: absolute;
                    left: 0;
                }
            }
        }
    }

    .form-row {
        label {
            margin-bottom: rem(7px);
        }
    }

    .form-row-button {
        button {
            width: 100%;

            @include media-breakpoint-up(md) {
                width: auto;
            }
        }
    }

    .cvn {
        .field-wrapper {
            display: inline-block;
            width: 90%;

            @include media-breakpoint-up(md) {
                width: 100%;
            }
        }

        .form-field-tooltip {
            float: none;
            vertical-align: top;
            width: auto;
            display: inline-block;
        }

        @include media-breakpoint-up(md) {
            width: 50%;
        }
    }

    .credit-card-fields {
        margin-top: rem(30px);
        display: none;
    }

    .ap-terms-conditions {
        border-bottom: $border2;

        .terms-box {
            max-height: 350px;
            padding-bottom: rem(5px);
            overflow: auto;
        }
    }

    .ap-terms-callout {
        margin-top: rem(30px);
        margin-bottom: rem(30px);
    }
}

.modify-payment {
    .form-row-button {
        max-width: 300px;

        button {
            width: 100%;
        }
    }

    .manage-options {
        .form-row-button {
            margin-bottom: 0;
        }
    }

    .button,
    button {
        width: 100%;
    }

    @include media-breakpoint-down(md) {
        .form-row-button {
            max-width: 100%;
        }
    }
}

.container-add-account {
    .privacy-policy {
        margin-left: 0;
    }
}

.autopay-enroll-steps {
    margin-bottom: rem(20px);

    div {
        & + div {
            margin-top: rem(20px);
        }
    }
}

#password-success {
    margin: 10px 0;

    span {
        @include font-bold;
    }
}

.createbenefits {
    margin-bottom: rem(50px);

    h3 {
        @include type-h4;
    }

    ul.no-bullets {
        margin: 0;
        padding: 0;

        @include font-reg($body-text-size, 20px);

        li {
            list-style: none;
            margin: rem(10px) 0;
            padding: 0;
        }

        .title {
            @include font-bold($body-text-size, 20px);
        }
    }
}

div.ui-dialog-payment-confirmation {
    button.payment-go-back {
        border: none;
        text-decoration: underline;
    }

    span.payment-amount {
        margin-left: 5px;
    }

    .ui-dialog-buttonpane {
        margin-bottom: 1em;
    }
}

div.ew-failure {
    color: $body-text-color;
}
