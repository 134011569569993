@use "sass:color";

// 1  Base
// 2  Colors
// 3  Options
// 4  Widgets
// 5  States
// 6  Icons
// 7  Corner radius
// 8  Accordion
// 9  Button
// 10 Datepicker
// 11 Dialog
// 12 Menu
// 13 Progressbar
// 14 Selectable
// 15 Selectmenu
// 16 Slider
// 17 Spinner
// 18 Tabs
// 19 Tooltip
// 20 Z-Index

// 1. Base
// ---------------------------------------------------------
$ui-border-radius: 4px !default;
$ui-border-width: 1px !default;
$ui-font-family: arial, helvetica, sans-serif !default;
$ui-font-size: 16px !default;
$ui-line-height: 1.5 !default;
$ui-font-weight: 400 !default;
$ui-padding-v: 6px !default; // v - vertical
$ui-padding-h: 12px !default; // h - horizontal
$ui-transition-duration: 0.3s !default;
$ui-transition-timing-function: linear !default;
$ui-transition: $ui-transition-duration $ui-transition-timing-function !default;
$ui-front-shadow: 0 6px 12px rgb(0 0 0 / 17.5%) !default;
$ui-spacing: 20px !default;

// 2. Colors
// ---------------------------------------------------------
$ui-gray-darker: #333 !default;
$ui-gray-dark: #666 !default;
$ui-gray: #aaa !default;
$ui-gray-light: #ddd !default;
$ui-gray-lighter: #e9e9e9 !default;
$ui-text-color: $ui-gray-darker !default;

// 3. Options
// ---------------------------------------------------------
$ui-enable-rounded: true !default;
$ui-enable-shadows: false !default;
$ui-enable-transitions: false !default;
$ui-enable-gradients: false !default;

// 4. Widgets
// ---------------------------------------------------------
$ui-widget-border-width: $ui-border-width !default;
$ui-widget-font-family: $ui-font-family !default;
$ui-widget-font-size: $ui-font-size !default;
$ui-widget-line-height: $ui-line-height !default;
$ui-widget-font-weight: $ui-font-weight !default;
$ui-widget-content-border-color: $ui-gray-light !default;
$ui-widget-content-background: #fff !default;
$ui-widget-content-color: $ui-text-color !default;
$ui-widget-header-border-color: $ui-gray-light !default;
$ui-widget-header-background: $ui-gray-lighter !default;
$ui-widget-header-color: $ui-text-color !default;
$ui-widget-header-font-weight: 600 !default;
$ui-widget-overlay-background: $ui-gray !default;
$ui-widget-overlay-opacity: 0.3 !default;
$ui-widget-shadow: 0 5px 15px rgb(0 0 0 / 50%) !default;

// 5. States
// ---------------------------------------------------------
$ui-states-border-width: $ui-border-width !default;
$ui-state-default-border-color: #c5c5c5 !default;
$ui-state-default-background: #f6f6f6 !default;
$ui-state-default-color: #454545 !default;
$ui-state-hover-border-color: #ccc !default;
$ui-state-hover-background: #ededed !default;
$ui-state-hover-color: #2b2b2b !default;
$ui-state-focus-border-color: $ui-state-hover-border-color !default;
$ui-state-focus-background: $ui-state-hover-background !default;
$ui-state-focus-color: $ui-state-hover-color !default;
$ui-state-active-border-color: #003eff !default;
$ui-state-active-background: #007fff !default;
$ui-state-active-color: #fff !default;
$ui-state-highlight-border-color: #dad55e !default;
$ui-state-highlight-background: #fffa90 !default;
$ui-state-highlight-color: #777620 !default;
$ui-state-error-border-color: #f1a899 !default;
$ui-state-error-background: #fddfdf !default;
$ui-state-error-color: #5f3f3f !default;
$ui-state-disabled-cursor: default !default;
$ui-state-disabled-opacity: 0.35 !default;
$ui-priority-primary-font-weight: bold !default;
$ui-priority-secondary-opacity: 0.5 !default;
$ui-priority-secondary-font-weight: normal !default;

// 6. Icons
// ---------------------------------------------------------
$ui-icon-font-path: "../fonts/" !default;
$ui-icon-font-name: "jquery-ui" !default;

// 7. Corner radius
// ---------------------------------------------------------
$ui-corner-border-radius: $ui-border-radius !default;

// 8. Accordion
// ---------------------------------------------------------
$ui-accordion-font-size: $ui-font-size !default;
$ui-accordion-line-height: $ui-line-height !default;
$ui-accordion-font-weight: $ui-font-weight !default;
$ui-accordion-spacing: $ui-spacing !default;
$ui-accordion-header-margin: 2px 0 0 0 !default;
$ui-accordion-header-padding: $ui-padding-v $ui-padding-h !default;
$ui-accordion-header-transition: background $ui-transition, color $ui-transition, border-color $ui-transition !default;
$ui-accordion-content-padding: 15px !default;

// 9. Button
// ---------------------------------------------------------
$ui-button-padding-v: $ui-padding-v !default;
$ui-button-padding-h: $ui-padding-h !default;
$ui-button-font-size: $ui-font-size !default;
$ui-button-line-height: $ui-line-height !default;
$ui-button-font-weight: $ui-font-weight !default;
$ui-button-transition: background $ui-transition, color $ui-transition, border-color $ui-transition !default;

// 10. Datepicker
// ---------------------------------------------------------
$ui-datepicker-width: 300px !default;
$ui-datepicker-padding: $ui-padding-v !default;
$ui-datepicker-box-shadow: $ui-front-shadow !default;
$ui-datepicker-spacing: $ui-spacing !default;
$ui-datepicker-header-padding-v: $ui-padding-v !default;
$ui-datepicker-header-padding-h: $ui-padding-v !default;
$ui-datepicker-header-margin: 0 !default;
$ui-datepicker-header-border-color: $ui-widget-header-border-color !default;
$ui-datepicker-header-border-width: $ui-border-width !default;
$ui-datepicker-header-border-radius: $ui-border-radius !default;
$ui-datepicker-header-background: $ui-widget-header-background !default;
$ui-datepicker-header-color: $ui-widget-header-color !default;
$ui-datepicker-title-font-size: $ui-font-size !default;
$ui-datepicker-title-line-height: $ui-line-height !default;
$ui-datepicker-title-font-weight: $ui-font-weight !default;
$ui-datepicker-header-nav-size: 24px !default;
$ui-datepicker-header-nav-transition: $ui-button-transition !default;
$ui-datepicker-header-nav-position-v: calc(50% - ($ui-datepicker-header-nav-size / 2)) !default;
$ui-datepicker-header-nav-position-h: calc($ui-datepicker-header-padding-h / 2) !default;
$ui-datepicker-header-nav-icon-top: calc(50% - ($ui-datepicker-header-nav-size / 2)) !default;
$ui-datepicker-select-offset: $ui-datepicker-padding !default;
$ui-datepicker-calendar-font-size: 14px !default;
$ui-datepicker-calendar-margin: 0 0 $ui-datepicker-padding !default;
$ui-datepicker-calendar-th-padding: 12px 5px !default;
$ui-datepicker-calendar-td-padding: 1px !default;
$ui-datepicker-day-padding: 6px 2px !default;
$ui-datepicker-day-transition: $ui-button-transition !default;
$ui-datepicker-buttonpane-border-width: $ui-border-width 0 0 0 !default;
$ui-datepicker-buttonpane-padding: $ui-datepicker-padding 0 0 !default;
$ui-datepicker-buttonpane-margin: 0 !default;

// 11. Dialog
// ---------------------------------------------------------
$ui-dialog-padding: $ui-padding-v !default;
$ui-dialog-box-shadow: $ui-widget-shadow !default;
$ui-dialog-titlebar-padding-v: $ui-padding-v !default;
$ui-dialog-titlebar-padding-h: $ui-padding-h !default;
$ui-dialog-titlebar-border-radius: $ui-border-radius !default;
$ui-dialog-titlebar-border-width: $ui-border-width !default;
$ui-dialog-titlebar-background: $ui-widget-header-background !default;
$ui-dialog-titlebar-margin: 0 !default;
$ui-dialog-titlebar-color: $ui-widget-header-color !default;
$ui-dialog-titlebar-border-color: $ui-widget-header-border-color !default;
$ui-dialog-titlebar-close-size: 20px !default;
$ui-dialog-titlebar-close-padding: 1px !default;
$ui-dialog-titlebar-close-top: calc(50% - ($ui-dialog-titlebar-close-size / 2 + $ui-border-width)) !default;
$ui-dialog-titlebar-close-right: $ui-dialog-titlebar-padding-v !default;
$ui-dialog-title-width: calc(100% - #{($ui-dialog-titlebar-padding-v * 2) + $ui-dialog-titlebar-close-size}) !default;
$ui-dialog-content-padding: 15px !default;
$ui-dialog-buttonpane-border-width: $ui-border-width 0 0 0 !default;
$ui-dialog-buttonpane-padding: $ui-dialog-padding 0 0 !default;
$ui-dialog-buttonpane-offset: 10px !default;
$ui-dialog-group-table-width: 95% !default;

// 12. Menu
// ---------------------------------------------------------
$ui-menu-min-width: 150px !default;
$ui-menu-padding: 0 !default;
$ui-menu-border-radius: 0 !default;
$ui-menu-spacing: $ui-spacing !default;
$ui-menu-border-color: $ui-widget-content-border-color !default;
$ui-menu-border-border-width: $ui-border-width !default;
$ui-menu-item-padding-v: $ui-padding-v !default;
$ui-menu-item-padding-h: $ui-padding-h !default;
$ui-menu-item-transition: $ui-button-transition !default;
$ui-menu-item-active-background: $ui-state-active-background !default;
$ui-menu-item-active-color: $ui-state-active-color !default;
$ui-menu-divider-margin-v: 10px !default;
$ui-menu-divider-border: $ui-border-width solid $ui-widget-content-border-color !default;

// 13. Progressbar
// ---------------------------------------------------------
$ui-progressbar-height: 20px !default;
$ui-progressbar-spacing: $ui-spacing !default;
$ui-progressbar-background: $ui-widget-content-background !default;
$ui-progressbar-border: $ui-border-width solid $ui-widget-content-border-color !default;
$ui-progressbar-overlay-opacity: 0.25 !default;
$ui-progressbar-value-background: $ui-widget-header-background !default;
$ui-progressbar-value-border: $ui-border-width solid $ui-widget-header-border-color !default;

// 14. Selectable
// ---------------------------------------------------------
$ui-selectable-border: $ui-border-width dotted $ui-gray-darker !default;

// 15. Selectmenu
// ---------------------------------------------------------
$ui-selectmenu-optgroup-font-size: $ui-font-size !default;
$ui-selectmenu-optgroup-font-weight: bold !default;
$ui-selectmenu-optgroup-color: $ui-text-color !default;
$ui-selectmenu-optgroup-line-height: $ui-line-height !default;
$ui-selectmenu-optgroup-padding-v: $ui-menu-item-padding-v !default;
$ui-selectmenu-optgroup-padding-h: $ui-menu-item-padding-h !default;
$ui-selectmenu-optgroup-margin: ($ui-menu-divider-margin-v - ($ui-selectmenu-optgroup-padding-v * 2)) 0 $ui-menu-divider-margin-v !default;
$ui-selectmenu-button-padding-v: $ui-button-padding-v !default;
$ui-selectmenu-button-padding-h: $ui-button-padding-h !default;
$ui-selectmenu-button-line-height: $ui-line-height !default;
$ui-selectmenu-button-transition: $ui-button-transition !default;

// 16. Slider
// ---------------------------------------------------------
$ui-slider-background: $ui-progressbar-background !default;
$ui-slider-border: $ui-progressbar-border !default;
$ui-slider-range-background: $ui-progressbar-value-background !default;
$ui-slider-range-border: $ui-progressbar-border !default;
$ui-slider-range-font-size: 14px !default;
$ui-slider-handle-size: 24px !default;
$ui-slider-handle-transition: $ui-button-transition !default;
$ui-slider-horizontal-height: $ui-progressbar-height !default;
$ui-slider-horizontal-handle-top: calc(50% - ($ui-slider-handle-size / 2 + $ui-border-width)) !default;
$ui-slider-horizontal-handle-margin-left: calc($ui-slider-handle-size / -2) !default;
$ui-slider-vertical-width: $ui-slider-horizontal-height !default;
$ui-slider-vertical-height: 200px !default;
$ui-slider-vertical-handle-top: $ui-slider-horizontal-handle-top !default;
$ui-slider-vertical-handle-margin-bottom: calc($ui-slider-handle-size / -2) !default;

// 17. Spinner
// ---------------------------------------------------------
$ui-spinner-display: inline-block !default;
$ui-spinner-button-width: 20px !default;
$ui-spinner-button-font-size: 6px !default;
$ui-spinner-button-up-icon-top: -3px !default;
$ui-spinner-button-down-icon-top: -3px !default;
$ui-spinner-input-padding-v: $ui-padding-v !default;
$ui-spinner-input-padding-h: $ui-padding-h !default;
$ui-spinner-input-color: inherit !default;
$ui-spinner-input-font-size: $ui-font-size !default;
$ui-spinner-input-line-height: $ui-line-height !default;
$ui-spinner-input-font-weight: $ui-font-weight !default;
$ui-spinner-input-background: #fff !default;
$ui-spinner-input-height: auto !default;
$ui-spinner-input-width: calc(100% - #{$ui-spinner-button-width}) !default;
$ui-spinner-input-border-width: $ui-border-width !default;
$ui-spinner-input-border-color: $ui-widget-header-border-color !default;
$ui-spinner-input-transition: border-color $ui-transition !default;
$ui-spinner-input-focus-border-color: color.adjust($ui-state-active-border-color, $lightness: -5%) !default;

// 18. Tabs
// ---------------------------------------------------------
$ui-tabs-padding: $ui-padding-v !default;
$ui-tabs-spacing: $ui-spacing !default;
$ui-tabs-border: $ui-border-width solid $ui-widget-header-border-color !default;
$ui-tabs-nav-padding: $ui-tabs-padding $ui-tabs-padding 0 !default;
$ui-tabs-nav-offset: $ui-tabs-padding !default;
$ui-tabs-nav-border-color: $ui-widget-header-border-color !default;
$ui-tabs-nav-border-width: $ui-border-width !default;
$ui-tabs-nav-background: $ui-widget-header-background !default;
$ui-tabs-nav-border-radius: $ui-border-radius !default;
$ui-tabs-nav-margin: 0 !default;
$ui-tabs-tab-margin: $ui-border-width $ui-tabs-nav-offset calc($ui-border-width / -1) 0 !default;
$ui-tabs-tab-transition: $ui-button-transition !default;
$ui-tabs-anchor-padding: $ui-padding-v $ui-padding-h !default;
$ui-tabs-panel-padding: $ui-padding-v $ui-padding-h !default;

// 19. Tooltip
// ---------------------------------------------------------
$ui-tooltip-padding: 10px !default;
$ui-tooltip-max-width: 300px !default;
$ui-tooltip-background: $ui-widget-content-background !default;
$ui-tooltip-border: $ui-border-width solid $ui-widget-content-border-color !default;
$ui-tooltip-border-radius: $ui-border-radius !default;
$ui-tooltip-color: $ui-text-color !default;

// 20. Z-Index
// ---------------------------------------------------------
$ui-zindex-slider-range: 1 !default;
$ui-zindex-slider-handle: 2 !default;
$ui-zindex-spinner-input: 20 !default;
$ui-zindex-selecthelper: 100 !default;
$ui-zindex-buttonhover: 100 !default;
$ui-zindex-datepicker: 500 !default;
$ui-zindex-tooltip: 900 !default;
$ui-zindex-menu: 700 !default;
$ui-zindex-selectmenu: 700 !default;
$ui-zindex-autocomplete: 700 !default;
$ui-zindex-dialog: 800 !default;
