.item-details {
    .product-list-item {
        .name {
            @include type-product-list-item-name;
        }

        .label {
            @include font-bold;
        }

        .value {
            @include font-reg;
        }

        .promo {
            color: $price-sale-color;
        }

        .price-standard {
            text-decoration: line-through;
            color: $body-text-color-lt;

            + .price-sales {
                color: $price-sale-color;
            }
        }
    }
}

.item-availability,
.item-quantity-details {
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }
}

.pt_gift-registry {
    fieldset {
        .item-list {
            // this selector targets the gift registry product table (in registry.isml), but not the gift registry event listings table (in registrylist.isml)
            @extend %product-table;
        }
    }
}

.item-dashboard {
    width: 16rem;

    label {
        width: auto;
    }

    .field-wrapper {
        width: auto;
    }

    label + .field-wrapper {
        @include media-breakpoint-up(lg) {
            float: right;
        }
    }

    .option-priority {
        label,
        .value {
            @include media-breakpoint-up(lg) {
                @include line-height(40px);
            }
        }
    }

    .option-quantity-desired {
        label {
            @include media-breakpoint-up(lg) {
                @include line-height(40px);
            }
        }

        input {
            width: rem(40px);
            padding: 0;
            text-align: center;
        }
    }

    .option-add-to-cart {
        margin-top: rem(10px);

        @include media-breakpoint-up(lg) {
            display: flex;
        }

        .inventory {
            float: left;
            width: 100%;

            @include media-breakpoint-up(lg) {
                width: rem(140px);
            }
        }

        .button,
        button {
            display: block;
            float: right;
            width: 100%;
            margin-top: rem(15px);

            @include media-breakpoint-up(lg) {
                width: calc(100% - #{rem(140px)});
                margin-top: 0;
            }
        }
    }

    .option-quantity-purchased {
        .exceeded {
            color: $error-color;
        }
    }
}
