.pt_wish-list {
    // custom registered styles
    #main.registered {
        background: $account-bg;
        padding: 0;
        margin-top: rem(10px);
        width: 100%;
        max-width: 100%;

        @include media-breakpoint-down(md) {
            padding: 0 rem(10px);
        }

        .account-nav-registered {
            margin: 0;
            border-top: none;

            @include media-breakpoint-up(md) {
                margin: 0 0 rem(25px);
            }
        }
    }

    .list-table-header {
        overflow: hidden;
        padding: 2em;
        background: $section-header-bg url("../images/interface/icon-double-line-header.png") repeat-x scroll left top;

        p {
            float: right;
            overflow: hidden;
            width: 40%;
            margin: 0;
            color: $body-text-color-lt;
        }
    }

    .primary-content {
        .list-table-header {
            h2 {
                float: left;
            }
        }
    }

    .item-list {
        @extend %product-table; // primary_region_elements.scss

        border-bottom: $border2;

        @include media-breakpoint-down(md) {
            label:not(.visually-hidden),
            .field-wrapper {
                width: 100%;
                float: left;
            }

            td {
                &:not(.item-image) {
                    padding-left: rem(10px);
                }
            }
        }

        .item-details,
        .item-availability {
            padding-top: rem(10px);
        }

        .item-availability ul {
            line-height: normal;
        }

        .view a {
            @include type-lone-link;
        }

        .lone-link {
            line-height: 2;
        }

        .AddToCartAlertMessage {
            color: #d41836;
            width: 100%;
            text-align: center;
            margin-top: 10px;
        }
    }

    .wishlist-shipping {
        .form-row {
            &:first-child {
                margin: rem(50px) 0;
            }

            button {
                width: 100%;

                @include media-breakpoint-up(md) {
                    width: inherit;
                }
            }
        }
    }

    .login-general {
        margin-top: rem(30px);
    }
}

.list-share {
    @include clearfix;
    @include type-list-share-options;

    padding: 2em;
    border-top: $border2;
    padding-left: 0;

    .share-option {
        display: block;
        float: left;
        box-sizing: border-box;
        margin-bottom: 0.5rem;
    }

    .share-options {
        float: left;
        margin: 0.3rem 0 0.5rem 1rem;
        text-align: center;

        .share-title {
            display: inline;
        }
    }

    .list-title {
        float: left;
    }

    .print-page {
        float: right;

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    .share-link {
        cursor: pointer;
    }

    .share-link-content {
        display: none;

        &.active {
            display: block;
            float: left;
        }
    }
}

.event-info {
    margin: 1em;
}

.wishlist-search,
.registry-search {
    .col-1 & {
        .form-row {
            width: 100%;
        }
    }

    .form-row {
        float: left;
        clear: none;
        width: 100%;

        @include media-breakpoint-up(md) {
            width: 33%;

            .field-wrapper {
                width: 90%;
            }
        }
    }

    .form-row-button {
        button {
            @include media-breakpoint-down(md) {
                width: 100%;
            }
        }
    }
}

.account-module .wishlist-shipping {
    .form-row:first-child {
        margin-top: 0;
    }
}
