@use "sass:color";

.tooltip {
    @include type-tooltip;

    cursor: help;
    position: relative;

    &.product-tile {
        cursor: pointer;
        display: block;
    }

    .icon {
        position: relative;
        top: -2px;
        margin: 0 3%;
    }
}

.tooltip-content {
    display: none;
}

.ui-tooltip.ui-widget-content {
    background: $tooltip-bg;
    border-radius: $tooltip-radius;
    box-shadow: $tooltip-shadow;
    color: $tooltip-color;
}

.ui-tooltip-content {
    padding: 0; // 20 on screen

    @include font-size($body-text-size);

    .recommendation-tooltip-header {
        @include type-recommendation-tooltip-header;

        padding: 0.5em;

        .product-name {
            @include type-recommendation-product-name;
        }

        .product-price {
            @include type-h5;

            .price-standard {
                text-decoration: line-through;

                @include type-recommendation-tooltip-price;

                & + .price-sales {
                    color: $price-sale-color;
                }
            }
        }
    }

    .recommendation-tooltip-description,
    .recommendation-tooltip-attributes {
        padding: 0.8em;
    }

    .shipping-method-cost,
    .surcharge-product,
    .promo {
        padding-bottom: 0.3rem;
        text-align: right;
    }

    .shippingtotal {
        clear: right;
        margin-top: 0.3rem;
        text-align: right;

        .value {
            padding-top: 0.3rem;
            border-top: $border;
            border-color: color.adjust($tooltip-bg, $lightness: 20%);
        }
    }
}
