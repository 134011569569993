/*
* Color classes for ease of use
* Names taken directly from style guide aliases
*/

.brand-primary {
    color: $brand-primary;
}

.brand-primary-bg {
    background: $brand-primary;
}

.brand-secondary {
    color: $brand-secondary;
}

.brand-secondary-bg {
    background: $brand-secondary;
}

.accent1 {
    color: $accent1;
}

.accent1-bg {
    background: $accent1;
}

.accent2 {
    color: $accent2;
}

.accent2-bg {
    background: $accent2;
}

.accent3 {
    color: $accent3;
}

.accent3-bg {
    background: $accent3;
}

.accent4 {
    color: $accent4;
}

.accent4-bg {
    background: $accent4;
}

.error-color {
    color: $error-color;
}

.error-color-bg {
    background: $error-color;
}

.success-color {
    color: $success-color;
}

.success-color-bg {
    background: $success-color;
}

.warning-color {
    color: $warning-color;
}

.warning-color-bg {
    background: $warning-color;
}

.dark-gray {
    color: $dark-gray;
}

.dark-gray-bg {
    background: $dark-gray;
}

.medium-gray {
    color: $medium-gray;
}

.medium-gray-bg {
    background: $medium-gray;
}

.light-gray {
    color: $light-gray;
}

.light-gray-bg {
    background: $light-gray;
}

.lighter-gray {
    color: $lighter-gray;
}

.lighter-gray-bg {
    background: $lighter-gray;
}

.black {
    color: $black;
}

.black-bg {
    background: $black;
}

.white {
    color: $white;
}

.white-bg {
    background: $white;
}
