/*
* Slot product tiles differ from grid tiles
* Slot tile override styles are in _slots_assets_scss
*/

@use "sass:string";

.product-tile {
    @include clearfix;

    display: flex;
    flex-direction: column;
    flex-basis: 100%; // needed for win7 IE11
    position: relative;

    img {
        max-width: 100%;
    }

    .product-image {
        flex-shrink: 0;
        height: auto;
        position: relative;
        margin-bottom: rem(10px);
        min-height: 136px;

        .thumb-link {
            display: block;
            position: relative;
            width: 100%;
            max-width: 100%;

            img {
                vertical-align: top;

                &.thumb-back {
                    position: absolute;
                    top: 0;
                    left: 0;
                    opacity: 0;
                }
            }
        }

        &.rollover {
            &.hover {
                .thumb-link {
                    img {
                        &.thumb-front {
                            @include respond(large-up) {
                                opacity: 0;
                            }
                        }

                        &.thumb-back {
                            @include respond(large-up) {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }

        .quickview {
            display: none;
            position: absolute;
            bottom: -6%; // .thumb-link height
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            padding: rem(1px) 0; // 30px height
            background-color: rgb($base-bg, 0.75);

            @include font-bold;

            text-align: center;

            .qv-icon {
                position: relative;
                top: -3px;
                margin-left: rem(6px);

                // make the icon bold
                stroke-width: 0.5px;
                stroke: $icon-fill;
            }
        }

        &:hover {
            .quickview {
                display: block;
            }
        }
    }

    .product-name {
        flex: 1 0 auto;
        display: flex;

        @include type-tile-product-name;
    }

    .product-pricing {
        @include type-h5;

        margin: rem(7px) 0 0;

        @include line-height(22px);

        line-height: 1.375rem;
        display: flex;
        flex-direction: column;

        .product-standard-price {
            @include font-reg;

            text-decoration: line-through;

            &.basePrice {
                @include type-h5;

                text-decoration: none;
            }

            & + .product-sales-price {
                color: $price-sale-color;
            }
        }

        .product-sales-price {
            color: $price-sale-color;

            &.list-price {
                color: #000;
            }
        }

        .product-set-price {
            &.sale-set {
                color: $price-sale-color;
            }
        }

        .price-standard-range {
            text-decoration: line-through;

            @include font-reg;
        }

        .percentage-discount {
            color: $brand-primary;
            margin: 0.25rem 0 0;
        }
    }

    .grid-tile & {
        height: 100%;
        box-sizing: border-box;
        padding: rem(20px) rem(20px) 0;

        @include media-breakpoint-up(md) {
            padding: rem(20px);
        }
    }

    .product-swatches {
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            overflow: hidden;
        }

        li {
            float: left;
            line-height: 1;
            padding: 0;
            margin: 0 rem(10px) rem(10px) 0;
            border: solid 1px black;

            &.last {
                margin: 0;
            }

            &.unselectable {
                opacity: 0.3;

                a::after {
                    content: "";
                    display: block;
                    box-sizing: border-box;
                    background: $swatch-border-color;
                    position: absolute;
                    transform: rotate(135deg);
                    width: 140%;
                    height: rem(2px);
                    left: -20%;
                    top: calc(50% - #{rem(1px)});
                }

                .swatch {
                    pointer-events: none;
                }
            }
        }

        .swatch {
            display: block;
            width: rem(20px);
            height: rem(20px);
            position: relative;

            &.selected,
            &:hover {
                &::after {
                    content: "";
                    display: block;
                    box-sizing: border-box;
                    top: rem(1px);
                    left: rem(1px);
                    position: absolute;
                    border: $selected-swatch-border;
                    width: calc(100% - #{rem(2px)});
                    height: calc(100% - #{rem(2px)});
                }
            }

            img {
                height: 100%;
                width: 100%;
            }
        }

        .product-swatches-all {
            float: left;

            @include type-tile-view-all-link;

            margin-left: rem(10px);
        }
    }

    .product-badge {
        // badge sits in the upper right corner of .grid-tile
        position: absolute;
        top: 0;
        left: rem(-5px);
    }

    // style inner div to collapse if no promo
    .product-promo > div {
        @include type-tile-product-promo;

        .tooltip {
            display: inline-block;
            vertical-align: text-bottom;
        }
    }

    .product-compare {
        margin-top: rem(15px);
        line-height: 1;
        min-height: rem(19px);
        display: none;

        @include media-breakpoint-up(md) {
            display: block;
        }

        label {
            width: auto;
            margin: 0;
        }

        input {
            float: left;
            margin: 0;
        }
    }

    // always takes up space
    .rating-wrapper {
        height: 16px;
        margin-top: rem(12px);
    }

    .product-review {
        line-height: normal;

        .rating-title {
            display: inline-block;
            margin-right: 1em;
        }

        .rating {
            display: inline-block;
        }
    }

    .p-w-r {
        .pr-category-snippet {
            margin: 0;
        }
    }
}

.capture-product-id {
    position: absolute;
    left: -999em;
}
