// --------------------------------
//  COLORS
// --------------------------------
// names taken from style guide

$brand-primary: #c80e17;
$brand-secondary: #9e0b0f;
$black: #000;
$dark-gray: #393939;
$medium-gray: #6e6e6e;
$light-gray: #c0c0c0;
$lighter-gray: #f2f2f2;
$white: #fff;
$accent1: #0081a0; // used in account, badges
$accent2: #00647c;
$accent3: #c80e17; // Add to Cart button
$accent4: #9e0b0f; // Add to Cart hover
$trans: transparent;

// consistent messaging colors
$error-color: #a00101;
$warning-color: #ffe544; // used in account
$success-color: #47807b; // used in account

$logo-width-small: 142px;
$logo-height-small: 30px;
$logo-width-tablet: 195px;
$logo-height-tablet: 42px;
$logo-width-desktop: 20%;
$logo-height-desktop: 65px;

// top banner
$promo-gradient-bg: $brand-primary;

// add to cart modal banner
$promo-gradient-bg-right: $brand-primary;

// Aliased variables used in global
$site-input-border-radius: 0;
$site-btn-border-radius: 0;

// Button Fonts
$button-font: $sans-serif-bold;

// offset for the header search per brand
$header-search-offset: -60px;
