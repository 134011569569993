.pdp-main {
    .product-add-to-cart {
        .online-stock {
            span {
                float: left;
            }
        }

        .store-stock {
            display: block;
            clear: left;
        }
    }
}

// ---------------------------
// Store Inventory
// ---------------------------

.availability-web {
    padding-bottom: 1rem;

    label {
        float: none;
    }

    p {
        margin: 0;
    }

    .availability-novariation {
        color: $body-text-color-lt;

        &.error-msg {
            color: $error-color;
            position: relative;
            padding-left: rem(24px);

            &::before {
                content: "";

                @include type-error-icon;

                display: block;
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }
}

.availability-storepickup {
    .availability-instore,
    .availability-web {
        background-color: $subtle-contrast-bg;
        padding: 1rem;
    }

    .availability-instore {
        margin-bottom: 1rem;
    }

    .availability-web {
        float: none;
    }
}

.in-stock-msg,
.is-in-stock,
[data-status="store-in-stock"] {
    color: $in-stock-color;
}

.low-inv-msg {
    color: $error-color;
}

.in-stock-date-msg,
.not-available,
.out-of-stock,
.store-error {
    color: $out-of-stock-color;
}

.not-available-msg {
    /* Style updated for CBS-711 */
    color: $out-of-stock-color;
    font-weight: 700;
    font-size: large;
}

.product-set-list {
    .not-available-msg {
        font-size: 15px;
    }
}

.on-order,
.preorder-msg,
.backorder-msg {
    color: $backorder-color;
}

#user-zip {
    display: block;
    margin: 1rem auto 0;
}

.store-list-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    margin: 0 auto;
}

.store-list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
}

.store-tile {
    /* for non-flex browsers */
    display: inline-block;
    vertical-align: top;

    /* for flex browsers */
    flex-grow: 1;
    flex-basis: 75%;
    box-sizing: border-box;
    border: $border2;
    border-style: dashed;
    list-style: none;
    margin: 0.3rem 2%;
    padding: 0.8rem;
    text-align: center;
    width: 96%; // 1 tile per row

    @include media-breakpoint-up(sm) {
        width: 45%; // 2 tiles per row
        flex-basis: 40%;
    }

    @include media-breakpoint-up(md) {
        width: 31%; // 3 tiles per row
        flex-basis: 30%;
        margin: 0.3rem 1%;
    }

    &.selected {
        background-color: rgb($accent1, 0.4);

        .select-store-button {
            @include button;
            @include simple-button;
        }
    }

    .store-status {
        @include type-store-tile-status;

        display: block;
        margin: 1rem 0;
    }
}

.store-list-pdp-container {
    margin-top: 1em;

    .store-list-pdp {
        margin-top: 0.5em;
    }

    .store-list-item {
        @include clearfix;

        display: none;
        padding: 0.5em  0.8em;

        &:last-of-type {
            border: none;
        }

        &.selected {
            @include type-store-list-item-selected;

            display: block;
        }

        &.visible {
            display: block;
        }

        .store-address {
            float: left;
        }

        .store-status {
            float: right;
        }
    }
}
