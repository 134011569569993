// --------------------------------------
// Header Styles
// --------------------------------------

@mixin type-user-logout-button {
    text-decoration: underline;
}

@mixin type-mini-cart-attribute {
    @include font-bold(15px);
}

@mixin type-search-suggestion-hits {
    line-height: 1.8em;
}

@mixin type-search-phrase-completed {
    @include font-italic;
}

@mixin type-search-phrase-original {
    @include font-bold;
}

@mixin type-search-phrase-corrected {
    @include font-bold;
}

// --------------------------------------
// Navigation Styles
// --------------------------------------

@mixin type-menu-category-link {
    @include font-reg($link-nav-font-size);

    text-decoration: $link-nav-desktop-text-decor;

    @include media-breakpoint-up(mobile-menu) {
        @include font-bold;

        letter-spacing: $link-nav-desktop-letter-spacing;
    }
}

@mixin type-nav-level-3-link {
    @include font-reg(1em);
}

@mixin type-nav-level-heading {
    @include type-h5;
}

@mixin type-nav-back-link {
    @include font-bold($body-text-size);
}

@mixin type-nav-mobile-account-link {
    @include font-bold($body-text-size);
}
