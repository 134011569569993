/*********
**  Swiss Colony Updated 01/12/2022
*********/
@import "https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900|Taviraj:300,300i,400,500,600,600i,700,900|EB+Garamond:400,400i,600i,700,800&display=swap";

footer { padding-top: 5px; }

.footer-top-promo {
    background: none !important;
}

div.headline2 { font-size: 1.5rem; }
.ui-tooltip { max-width: 60%; }

/* mobile portrait viewports 320-479 */
@media only screen and (max-width: 479px) {
    .ui-tooltip { max-width: 90%; }
}

/* mobile landscape viewports 480-767 */
@media only screen and (min-width: 480px) and (max-width: 767px) {
    .ui-tooltip { max-width: 90%; }
}

/* tablet viewports 768-1024 */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    div.headline2 { font-size: 1.875rem; }
}

/* desktop viewports 1025+ */
@media only screen and (min-width: 1025px) {
    div.headline2 { font-size: 1.875rem; }
}

@media (min-width: 1921px) {
    body > #wrapper { max-width: 1920px; }
}

.product-set .product-desc ul {
    list-style-type: disc;
    list-style-position: inside;
}

#keyword_content {
    color: #f00;
    padding-bottom: 15px;
}

.openModal { cursor: pointer; }

.br-m { display: none; }
.br-l { display: none; }
.br-xl { display: none; }
.br-s { display: block; }

@media screen and (min-width: 480px) {
    .br-s { display: none; }
    .br-l { display: none; }
    .br-xl { display: none; }
    .br-m { display: block; }
}

@media screen and (min-width: 768px) {
    .br-s { display: none; }
    .br-m { display: none; }
    .br-xl { display: none; }
    .br-l { display: block; }
}

@media screen and (min-width: 1025px) {
    .br-s { display: none; }
    .br-m { display: none; }
    .br-l { display: none; }
    .br-xl { display: block; }
}
