@include media-breakpoint-down(md) {
    .pt_cart #secondary {
        display: none;
    }
}

#cart-promo {
    &.active {
        .drawer-handle {
            border-bottom: none;
        }

        .promo-code-container {
            border-bottom: 1px solid #000;
        }
    }

    .drawer-handle {
        border-color: #000;
        margin: 0;
    }

    .promo-code-container {
        padding-right: 0;
        padding-left: 0;
        border-color: #000;
    }
}

#cart-table,
.account-module .item-list {
    border-top: $border2;

    @extend %product-table; // primary_region_elements.scss
    .header-total-price {
        text-align: right;
    }

    .item-delivery-options {
        .form-row {
            margin: rem(8px) 0;
        }

        .instore-delivery {
            .selected-store-address,
            .selected-store-availability,
            .form-row {
                margin-left: rem(24px);

                @include media-breakpoint-up(md) {
                    margin-left: 0;
                }
            }
        }

        @include media-breakpoint-up(md) {
            width: rem(140px);
        }
    }

    .item-quantity-details .item-user-actions {
        a,
        button {
            display: block;
        }
    }

    .price-promotion {
        word-spacing: 0.5em;

        .price-sales {
            color: $price-sale-color;
        }

        .price-standard {
            text-decoration: line-through;
        }

        @include media-breakpoint-only(md) {
            span {
                display: block;

                &.not-available.all-not-available {
                    display: none;
                }
            }
        }
    }

    .price-unadjusted {
        color: $body-text-color;
        display: block;
        margin: 0 0 0.42em;

        span {
            text-decoration: line-through;
        }
    }

    .price-adjusted-total {
        color: $body-text-color;
        display: block;
        margin: 0.42em 0 0;

        span {
            color: $price-sale-color;
        }
    }

    .gift-cert-delivery {
        color: $price-sale-color;
    }

    .cart-row {
        border-bottom: $border2;

        &.has-esp {
            border-bottom: none;
        }

        &.esp-last-chance {
            background-color: $light-gray;

            h5 {
                display: inline;
            }

            a.openModal {
                margin-left: 1.67rem;
                text-decoration: underline;
            }

            span.esp-last-chance {
                font-weight: bold;
                color: $error-color;
                margin-right: 1rem;
            }

            .esp-options {
                margin-top: 1.67rem;
                padding-left: rem(20px);
            }

            img.new-leaf-logo {
                float: left;
            }

            .esp-content {
                float: left;
            }

            .esp-title {
                margin-top: 1.25rem;
            }

            @include media-breakpoint-down(sm) {
                td.item-details {
                    width: 100%;
                    padding-left: 0.25rem;
                }

                img.new-leaf-logo {
                    width: 25%;
                }

                .esp-content {
                    float: left;
                    width: 70%;
                }

                a.openModal {
                    margin-left: 0;
                    display: block;
                }

                .esp-options {
                    margin-top: 1rem;
                    margin-left: -35%;
                }

                h5 {
                    display: block;
                    margin: 1rem 0;
                }
            }
        }

        &.esp-row {
            .cart-price-sales {
                text-align: left;
                font-weight: 400;
            }

            .price-total {
                font-weight: normal;
            }
        }
    }

    thead {
        display: none;

        @include media-breakpoint-up(md) {
            display: table-header-group;
        }

        tr {
            border-bottom: $border2;

            .item-header {
                padding-left: rem(10px);
                padding-right: rem(10px);
            }
        }
    }

    tfoot {
        td {
            padding: 0;
            width: 100%;
        }

        tr {
            border-bottom: 0;
        }

        @include media-breakpoint-down(md) {
            @include full-bleed-width;
        }
    }

    .product-list-item {
        line-height: 1.5em;
    }

    .item-image,
    .item-details,
    .item-price,
    .item-quantity,
    .item-total,
    .in-wishlist {
        padding: rem(8px) 0;
        box-sizing: border-box;
    }

    .in-wishlist {
        color: $success-color;
    }

    .item-details,
    .item-price,
    .item-quantity,
    .item-total {
        width: 60%;
        padding-left: rem(20px);
    }

    .item-image {
        width: 40%;
        padding-top: rem(20px);
        padding-bottom: rem(30px);
    }

    .item-edit-details {
        @include type-lone-link;

        text-align: left;
        margin-top: rem(10px);
    }

    .item-details {
        padding-top: rem(20px);
    }

    .sku {
        .label,
        .value {
            @include type-cart-sku;
        }
    }

    .name {
        @include type-cart-product-name;

        margin-bottom: rem(5px);
        font-style: normal;
    }

    .leadTimeMsg,
    .AddToCartAlertMessage {
        color: #d41836;
        line-height: 1.5rem;
    }

    .promo {
        margin-bottom: rem(5px);
    }

    .attribute,
    .pnc-selections {
        .label {
            @include type-cart-details-label;

            text-transform: capitalize;
        }

        .value {
            @include type-cart-details-value;
        }
    }

    .pnc-selections {
        margin-top: rem(10px);
    }

    .pnc-item {
        display: block;
    }

    .price-table-header {
        text-align: right;
        padding-right: rem(40px);
    }

    .quantity-table-header {
        padding-left: rem(30px);
    }

    .price-option .label {
        font-family: "nunito_sansextrabold", Arial, sans-serif;
    }

    .price-option .value {
        font-family: "nunito_sansregular", Arial, sans-serif;
    }

    .item-price {
        text-align: right;

        .price-standard {
            @include type-cart-price-reg;

            & + .price-sales {
                color: $price-sale-color;
            }
        }

        .price-sales {
            @include type-cart-price-disc;
        }
    }

    .item-quantity {
        label {
            display: none;
        }

        // do not allow users to remove items from cart by clicking qty to 0
        &.disable-decrease {
            .quantity-decrease {
                pointer-events: none;
            }
        }

        .product-availability-list {
            margin-top: rem(10px);

            span {
                display: block;
                margin-top: rem(5px);

                &:first-child {
                    margin-top: 0;
                }

                &.not-available.all-not-available {
                    display: none;
                }
            }
        }

        .add-to-wishlist,
        .remove-item,
        .add-to-registry {
            margin-top: rem(10px);

            @include type-lone-link;

            display: block;
        }

        .remove-item {
            &.esp-remove-item {
                margin-top: 0;
            }
        }
    }

    .item-total {
        padding-bottom: rem(30px);

        @include font-bold(16px);

        word-wrap: break-word;

        @include media-breakpoint-up(md) {
            font-size: 1em;
            text-align: right;
        }

        .price-total {
            @include type-cart-price-reg;
        }
    }

    @include media-breakpoint-up(md) {
        border-top: 0;

        .item-price {
            padding-top: rem(20px);
            padding-right: rem(40px);
            width: 23%;
        }

        .item-image,
        .item-details,
        .item-quantity,
        .item-total {
            padding: rem(20px) 0 rem(30px) 0;
        }

        .item-details,
        .item-quantity {
            padding-right: rem(30px);
            padding-left: rem(30px);
        }

        .item-price,
        .item-total {
            padding-left: 0;
        }

        .item-image {
            width: 16%;
        }

        .item-details {
            width: 35%;
            padding-left: rem(30px);
        }

        .esp-last-chance {
            .item-details {
                padding-left: rem(20px);
            }
        }

        .item-quantity {
            width: 16%;
        }

        .item-total {
            width: 10%;
        }
    }
}

.summary-item-total {
    font-family: "nunito_sansregular", Arial, sans-serif;
}

.cart-promo {
    color: $accent1;
    text-align: center;
    padding: 0;
    margin: 0;

    div {
        padding: rem(12px) 0;
        margin-top: rem(20px);
        margin-bottom: rem(10px);
        background-color: $subtle-contrast-bg;
        line-height: 1.25em;
    }

    .bonus-item-actions {
        font-size: 1em !important;
        text-align: right;
        vertical-align: middle;
    }

    .label {
        @include type-cart-promo-label;

        display: block;
    }

    .value {
        @include type-cart-promo-value;

        display: block;
    }

    .tooltip {
        display: inline-block;
        vertical-align: text-bottom;
    }

    @include media-breakpoint-up(md) {
        .label,
        .value {
            display: inline-block;
        }
    }
}

.cart-footer,
.cart-bottom {
    @include clearfix;

    margin: 0.83em 0;
}

.cart-coupon-code {
    input {
        margin-bottom: rem(8px);
        width: 35%;

        @include media-breakpoint-up(md) {
            width: 45%;
        }
    }

    .error {
        padding-top: 0.4em;
    }

    button {
        margin-left: 0.27em;
        padding: rem(8.5px) rem(20px);

        &.simple {
            @include secondary-button;

            padding: rem(8.5px) rem(20px);
        }
    }

    // to allow both the Apply and Update Cart buttons to fit on one line at 320
    @include media-breakpoint-down(sm) {
        input {
            float: left;
            width: 44%;
        }

        button {
            float: left;
            margin: 0 0 0 1%;
            padding: rem(8.5px) 0;
            width: 22%;

            // Update Cart button
            &.simple {
                width: 32%;
                padding: rem(8.5px) 0;
            }
        }
    }
}

.order-totals-table {
    td {
        padding: rem(5px);
        text-align: right;

        &:first-of-type {
            text-align: left;
        }
    }

    .order-totals-item {
        width: 60%;
    }

    .order-totals-value {
        width: 40%;
    }

    .order-subtotal {
        @include font-bold;
    }

    .order-total {
        @include font-bold(16px);
    }
}

.order-value {
    white-space: nowrap;
}

.cart-recommendations {
    clear: both;
    margin-top: rem(16px);

    .product-listing {
        @include clearfix;

        margin: rem(16px) 0;
        background-color: $base-bg;

        h2 {
            padding: rem(16px) rem(8px) rem(2px);
            background-color: $section-header-bg;
        }

        .search-result-items {
            margin-top: 1em;
        }
    }
}

.cart-actions-top {
    margin-top: rem(8px);
    padding-bottom: 1em;

    .cart-action-continue-shopping {
        float: none;
    }
}

.cart-empty {
    @include clearfix;

    margin-top: rem(64px);

    h1 {
        text-align: left;
    }

    .cart-action-continue-shopping {
        float: none;
        text-align: left;
        margin-bottom: rem(64px);
    }

    .product-listing {
        @include clearfix;

        background-color: $box-bg;
        padding: rem(16px) 0;

        h2 {
            margin-left: rem(21px);
        }

        .search-result-items {
            margin-top: 1em;
        }
    }
}

.promo-adjustment {
    color: $price-sale-color;
}

ul.product-availability-list {
    margin: 0;
    padding: 0;
    width: 5em;

    // for IE
    width: max-content;

    li {
        list-style: none;
        padding-left: 0;
    }

    .on-order,
    .not-available {
        color: $out-of-stock-color;
        font-size: 0.9em;
    }
}

.account-welcome {
    .welcome-message {
        font-size: 1.2em;

        @include font-italic;
    }

    .not-user {
        margin: 0.83em 0;
    }
}

#password-reset {
    white-space: nowrap;
}

// Cart Custom Styles
.pt_cart {
    .cart-header {
        margin-bottom: rem(30px);
    }

    .cart-actions-top {
        display: none;
        flex-direction: column;
        margin-top: rem(20px);

        @include media-breakpoint-up(lg) {
            display: flex;
            flex-direction: row;
            align-items: center;

            >button {
                display: inline-block;
                order: 2;
                width: 300px;
                padding: 0;
            }

            .cart-action-continue-shopping {
                order: 1;
                flex-grow: 1;
            }
        }
    }

    .invalid-product-error {
        font-size: 1em;
        color: $error-color;
        margin: 0 0 1em;
    }

    .cart-actions {
        display: flex;
        flex-direction: column;
        margin-top: rem(20px);

        .cart-action-continue-shopping {
            margin-top: rem(5px);
        }

        &.mobile-only {
            @include media-breakpoint-up(lg) {
                display: none;
            }
        }

        @include media-breakpoint-up(md) {
            flex-direction: row;
            align-items: center;

            >button {
                display: inline-block;
                order: 2;
                width: 300px;
                padding: 0;
            }

            .cart-action-continue-shopping {
                order: 1;
                flex-grow: 1;
                margin-top: 0;
            }
        }

        @include media-breakpoint-up(lg) {
            flex-direction: column;
            align-items: flex-start;

            >button {
                width: 100%;
                order: 1;
            }

            .cart-action-continue-shopping {
                margin-top: rem(5px);
                order: 2;
                flex-grow: 0;
            }
        }
    }

    .cart-action-checkout {
        button {
            width: 100%;
        }
    }

    .cart-action-continue-shopping {
        @include type-back-link;

        fieldset {
            display: inline-block;
        }

        button {
            &.button-text {
                text-decoration: none;
            }
        }
    }

    .cart-form-wrapper {
        display: flex;
        flex-direction: column;

        .item-list {
            order: 3;
        }

        .cart-footer {
            order: 1;
            margin-bottom: rem(30px);
        }

        .cart-header-items {
            order: 2;
        }

        .cart-bottom {
            order: 4;
            margin-bottom: rem(30px);
        }

        .cart-footer-two {
            order: 5;
            margin-bottom: rem(30px);
        }

        .cart-footer,
        .cart-footer-two,
        .cart-bottom {
            .cart-footer-inner {
                &.asset-only {
                    h6 {
                        margin-bottom: rem(5px);
                        margin-top: rem(20px);

                        &:first-of-type {
                            margin-top: 0;
                        }
                    }

                    h5 {
                        margin-bottom: rem(15px);
                        margin-top: 0;
                    }

                    p {
                        margin-top: 0;
                    }

                    a {
                        @include type-lone-link;
                    }
                }
            }
        }

        @include media-breakpoint-up(lg) {
            .item-list {
                order: 2;
            }

            .cart-header-items {
                order: 1;
            }

            .cart-bottom {
                order: 3;
            }

            .cart-footer {
                order: 4;
                display: flex;

                .cart-footer-inner {
                    width: 50%;
                    display: inline-block;
                    vertical-align: top;

                    &:first-child {
                        margin-right: 5%;
                    }

                    &:last-child {
                        margin-left: 5%;
                    }
                }
            }

            .cart-footer-two {
                order: 5;
            }
        }
    }

    .cart-coupon-code {
        input {
            width: calc(75% - 4%);
            margin-right: 4%;
            margin-bottom: 0;

            @include media-breakpoint-up(md) {
                margin-right: 3%;
            }
        }

        button {
            width: 25%;
            margin-left: 0;
        }
    }

    .coupon-callout {
        @include font-bold($body-text-size);

        margin-bottom: rem(2px);
    }

    .cart-order-totals {
        margin: rem(20px) 0;

        .order-totals-table {
            td {
                padding: 0 0 rem(15px) 0;
            }
        }

        .order-subtotal,
        .order-shipping,
        .order-sales-tax {
            @include type-cart-order-review;
        }

        .order-discount {
            @include type-cart-order-discount;
        }

        .order-total {
            @include type-cart-order-total;

            td {
                padding-bottom: 0;
            }
        }
    }

    .error-form {
        margin-bottom: rem(15px);

        h4 {
            margin: 0;
        }
    }

    .summary-coupon {
        .discount {
            margin-top: rem(20px);
            color: #3c0;

            .icon {
                margin-right: rem(10px);
                fill: #3c0;
            }

            span {
                @include type-cart-order-review;

                vertical-align: middle;
            }

            button {
                float: right;

                @include type-lone-link;
            }
        }
    }

    .cart-callout-message {
        color: $accent1;
        text-align: center;
        margin-bottom: rem(20px);
        position: relative;
        line-height: 1.25em;

        .cart-promo {
            padding: rem(12px) 0;
            margin-top: rem(20px);
            background-color: $subtle-contrast-bg;
        }

        .label {
            @include type-cart-promo-label;

            display: block;
        }

        .value {
            @include type-cart-promo-value;

            display: block;
        }

        @include media-breakpoint-up(md) {
            border-bottom: $border2;
            padding-bottom: rem(30px);

            .label,
            .value {
                display: inline-block;
            }
        }
    }
}
