// Slots and Content Assets: Organization-Level Styles
// should contain only structural rules, i.e. values that will likely need
// to stay consistent from site to site, such as display, widths, and spacing.

.content-slot {
    img {
        height: auto;
        max-width: 100%;
    }
}

.slot-grid-header {
    & > * {
        margin-bottom: rem(10px);
    }

    p {
        margin: 0;
        padding: 0;
    }

    .search-header-banner {
        position: relative;
        margin-bottom: rem(10px);

        @include media-breakpoint-up(md) {
            margin-top: rem(-36px);
        }

        h1 {
            position: absolute;
            text-align: center;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        img {
            vertical-align: top;
        }
    }
}

.search-bottom-banner {
    @include media-breakpoint-up(md) {
        margin: rem(66px) 0 rem(30px);
    }

    .search-promo-item {
        position: relative;
        box-sizing: border-box;
        margin: rem(30px) 0;

        @include media-breakpoint-up(md) {
            float: left;
            width: 50%;
            margin: 0;
        }

        &.left {
            @include media-breakpoint-up(md) {
                padding-right: 2%;
            }
        }

        &.right {
            @include media-breakpoint-up(md) {
                padding-left: 2%;
            }
        }

        img {
            vertical-align: top;
            max-width: 100%;
        }

        .search-promo-content {
            position: absolute;
            top: 50%;
            left: rem(30px);
            min-width: rem(200px);
            transform: translateY(-50%);

            @include media-breakpoint-up(lg) {
                top: rem(24px);
                transform: none;
            }

            h2 {
                margin: 0 0 rem(10px);
                color: $white;

                @include media-breakpoint-up(lg) {
                    @include font-size(44px);
                    @include line-height(48px);

                    margin: 0 0 rem(20px);
                }
            }
        }
    }
}

// custom slider tiles
.horizontal-carousel,
.horizontal-carousel-with-arrows {
    .slick-slide {
        box-sizing: border-box;
        width: 94%;
        padding: rem(20px) rem(20px) rem(30px);
        margin: 0 rem(8px) rem(20px);
        vertical-align: top;
        background: $base-bg;
        box-shadow: $shadow-tile-slider;

        @include media-breakpoint-up(sm) {
            width: 44%; // 2 tiles per row
        }

        @include media-breakpoint-up(md) {
            width: calc(100% * (1 / 4) - 6%); // 4 tiles per row
        }
    }

    .tiles-container {
        &:not(.slick-initialized) {
            display: none;
        }
    }

    .slick-dots {
        position: relative;

        li {
            button {
                height: rem(12px);
                width: rem(12px);

                &::before {
                    opacity: 1;
                    box-shadow: none;
                    height: rem(12px);
                    width: rem(12px);
                }
            }

            &.slick-active {
                button {
                    &::before {
                        color: $brand-primary;
                        background-color: $brand-primary;
                        box-shadow: none;
                        opacity: 1;
                    }
                }
            }
        }
    }

    .slick-track {
        display: flex;

        .slick-slide {
            display: flex;
            height: auto;

            img {
                width: 100%;
            }
        }
    }
}

.pt_storefront {
    .horizontal-carousel {
        .slick-dots {
            button {
                &::before {
                    color: $light-gray;
                    background-color: $light-gray;
                }
            }

            &.slick-active {
                button {
                    &::before {
                        color: $brand-primary;
                        background-color: $brand-primary;
                    }
                }
            }
        }
    }
}

.global-carousel-wrapper {
    padding-top: rem(30px);
    padding-bottom: rem(30px);
    margin-top: rem(40px);

    &.gradient-background {
        @include full-bleed-width;
        @include gradient($lighter-gray, $white, "vertical");

        .horizontalcarousel-with-banner {
            margin-top: 0;
        }

        .carousel-banner {
            max-width: $max-content-breakpoint;
            width: 100%;
            margin: 0 auto;
            text-align: center;

            h3 {
                margin-bottom: rem(40px);
                margin-top: 0;
            }
        }
    }

    .slick-dots {
        li {
            button {
                &::before {
                    color: $slick-dot-color;
                    border: $border;
                }
            }

            &.slick-active {
                button {
                    &::before {
                        color: $slick-dot-color-active;
                        background-color: $slick-dot-color-active;
                        border: 1px solid $slick-dot-color-active;
                    }
                }
            }
        }
    }

    .slick-prev,
    .slick-next {
        top: 45%;
        height: rem(32px);
        width: rem(32px);
    }

    .slick-arrow {
        &::before {
            content: none;
        }

        &.slick-prev {
            background: url("../images/svg-icons/arrow-block-left.svg") no-repeat center;
        }

        &.slick-next {
            background: url("../images/svg-icons/arrow-block-right.svg") no-repeat center;
        }
    }
}

.horizontalcarousel-with-banner {
    margin-top: rem(40px);

    @include media-breakpoint-up(md) {
        margin-top: rem(60px);
    }

    .carousel-banner {
        margin-bottom: rem(30px);
    }

    .carousel-wrapper {
        width: 100%;
        max-width: $max-content-breakpoint;
        margin: 0 auto;
        box-sizing: border-box;
        padding: 0 rem(20px);

        @include media-breakpoint-up(md) {
            padding: 0;
        }

        .horizontal-carousel-with-arrows {
            @include media-breakpoint-up(md) {
                padding: 0 rem(30px);

                .slick-prev {
                    left: -30px;
                }

                .slick-next {
                    right: -30px;
                }
            }
        }
    }
}

.content-asset {
    .account-options {
        align-items: stretch;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0;
        padding: 0;

        li {
            /* for non-flex browsers */
            display: inline-block;
            width: 100%;
            vertical-align: top;

            /* for flex browsers */
            flex-basis: 100%;

            /* end flex styling */
            max-width: rem(320px);
            background-color: $box-drk-bg;
            border: $border-thick;
            border-radius: 5px;
            list-style-type: none;
            margin: 1rem 0;
            text-align: center;
            transition: background-color 1000ms ease;

            &:hover {
                background-color: $accent2;
                border-color: $accent2;
            }

            a {
                color: $inverted-text-color;
                display: block;
                padding: 1rem;

                i {
                    @include font-size(96px);
                }
            }

            @include media-breakpoint-up(sm) {
                /* for non-flex browsers */
                width: 41%;

                /* for flex browsers */
                flex-basis: 41%;

                /* end flex styling */
                margin: 1rem;
            }

            @include media-breakpoint-up(lg) {
                /* for non-flex browsers */
                width: 27%;

                /* for flex browsers */
                flex-basis: 27%;

                /* end flex styling */
            }
        }
    }
}

.browser-compatibility-alert {
    background-color: $error-color;
    color: $error-bg-color;
    font-size: 1.3em;
    line-height: 2em;
    padding: 0.8em;
}

.folder-slot {
    border: $border4;
    padding: 0.5em;

    h2 {
        margin: 0.5em 0;
    }
}

.menu-slot {
    display: none;
    border-top: $border;
    margin-top: 0.5em;
    padding-top: 1.5em;

    @include media-breakpoint-up(md) {
        width: 13.5rem;
        display: block;
    }

    .social-links {
        padding-left: 0;

        li {
            display: inline-block;
            padding-left: 0;
        }
    }
}

.category-slot,
.product-slot {
    h1 {
        position: absolute;
        text-align: right;
        bottom: 2%;
        left: 2%;
    }

    h2 {
        margin: 0;
        padding: 0.1rem 0.5rem;
    }

    h3 {
        bottom: 0;
        margin: 0;
        opacity: 0.6;
        padding: 2rem;
        position: absolute;
        width: 100%;
    }

    .catlanding-banner {
        overflow: hidden;
        position: relative;
        width: 100%;

        img {
            height: auto;
            width: 100%;
        }
    }

    .category-tile {
        float: left;
        overflow: hidden;
        padding: 0 0.15%;
        position: relative;
        width: 100%;

        @include media-breakpoint-up(md) {
            width: 33%;
        }

        img {
            height: auto;
            width: 100%;
        }
    }

    .cat-banner {
        overflow: hidden;
        position: relative;
        width: 100%;
        margin-bottom: rem(10px);

        .full-width & {
            box-sizing: border-box;
            padding: 0 0.5em;
            margin-top: 1em;
        }

        img {
            height: auto;
            width: 100%;
            vertical-align: top;

            & + h1 {
                position: absolute;
                text-align: center;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }

        h1 {
            color: $section-heading-color;
            right: 2%;
        }
    }

    .search-result-items {
        .grid-tile {
            box-sizing: border-box;
            box-shadow: none;
            margin: 0;
            padding: 0 1%;
            width: 49%;

            @include media-breakpoint-up(md) {
                width: 33%;
                box-sizing: border-box;
            }
        }

        .product-tile {
            margin: 0 0.15%;
            padding: 1rem 0;
            width: 100%;
        }

        .product-name {
            @include font-reg(18px);

            height: auto;
            margin-top: 0.5em;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            a {
                color: $link-color;
            }
        }

        .product-pricing {
            @include font-reg(16px);
        }

        .product-swatches {
            display: none;
        }
    }
}

// used in generic assets to give structure
.boxplaceholder {
    border: 1px solid #c9c9c9;
    background-color: #cfcfcf;

    @include font-reg(16px);

    text-align: center;
    padding: rem(20px);
}

@include media-breakpoint-only(md) {
    .html-slot-container {
        img {
            max-width: 100%;
        }
    }
}

.carousel-wrapper-a2c-confirmation {
    padding: 0 38em;

    @include media-breakpoint-down(md) {
        padding: 0 35em;
    }

    .slick-track {
        display: flex;
    }

    .slick-slide {
        height: inherit;
    }

    .slick-next {
        right: -0.875rem;
        top: 34%;

        @include media-breakpoint-down(md) {
            top: 45%;
            right: 1.0625rem;
        }
    }

    .slick-prev {
        left: -0.875rem;
        top: 34%;

        @include media-breakpoint-down(md) {
            top: 45%;
            left: 1.0625rem;
        }
    }

    .grid-tile {
        background: #fff;
        box-shadow: 0 0 9px 0 #c0c0c0;
        width: 48% !important;
        height: auto;
        padding: 0;
        margin: 0.3125rem 0.4375rem;
    }

    // Start carousel-wrapper-a2c-confirmation
    @include media-breakpoint-down(md) {
        padding: 0;

        .grid-tile {
            @include media-breakpoint-down(md) {
                width: 20.75rem !important;
                margin: 0.3125rem 1.4375rem;
            }
        }
    }
}
