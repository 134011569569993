.pt_gift-registry {
    // custom registered styles
    #main.registered {
        background: $account-bg;
        padding: 0;
        margin-top: rem(10px);
        width: 100%;
        max-width: 100%;

        @include media-breakpoint-down(md) {
            padding: 0 rem(10px);
        }

        .account-nav-registered {
            margin: 0;
            border-top: none;

            @include media-breakpoint-up(md) {
                margin: 0 0 rem(25px);
            }
        }
    }

    .item-list {
        @extend %product-table; // primary_region_elements.scss

        @include media-breakpoint-down(md) {
            label:not(.visually-hidden),
            .field-wrapper {
                width: 100%;
                float: left;
            }

            td {
                &:not(.item-image) {
                    padding-left: rem(10px);
                }
            }
        }

        .view a {
            @include type-lone-link;
        }

        .item-details,
        .item-availability {
            padding-top: rem(10px);
        }

        .item-availability ul {
            line-height: normal;
        }

        .AddToCartAlertMessage {
            color: #d41836;
            width: 100%;
            text-align: center;
            margin-top: 10px;
        }
    }

    legend {
        padding: rem(10px) 0;
        border: 0 none;
        margin-bottom: rem(10px);
        width: 100%;
    }

    .has-tooltip {
        .field-wrapper {
            @include media-breakpoint-up(md) {
                width: 50%;
            }
        }
    }

    .primary-content h3 {
        @include section-header;
    }

    .create-new-registry {
        margin: 1em 0;

        button {
            float: left;
            margin-right: 2em;
        }

        p {
            font-size: 1.3em;
        }
    }

    @include media-breakpoint-down(md) {
        .create-new-registry {
            overflow: hidden;

            p {
                display: none;
            }
        }
    }

    .form-row-button {
        clear: both;

        @include media-breakpoint-down(md) {
            button:nth-of-type(2) {
                margin-top: rem(15px);
            }
        }
    }
}

.page-content-tab-navigaton {
    padding: rem(10px) 0 rem(30px);
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    @include media-breakpoint-up(md) {
        display: block;
    }

    button {
        @include type-account-nav;

        border-radius: 0;
        text-decoration: none;

        @include media-breakpoint-up(md) {
            margin-right: rem(50px);
        }

        &:hover,
        &.selected {
            text-decoration: none;

            @include type-active-account-nav;
        }
    }
}

.gift-reg-purchases {
    .item-purchased-by {
        dd {
            float: left;

            @include font-bold;

            margin: 0 1em 0 0;
            position: relative;
            width: auto;
        }

        dt {
            clear: both;
            float: left;
            margin: 0 1em 0 0;
            position: relative;
            width: auto;
        }
    }
}

.registry-search {
    .form-caption,
    span.error {
        width: 100%;
    }

    .form-row-button {
        clear: both;
    }
}

.gift-registry-advanced {
    clear: both;

    .toggle-content {
        display: none;
    }
}

// Wishlist and Registry line item styles match the cart
// .item-list class was added to #cart-table in _shopping_cart.scss

.account-module {
    .item-list {
        @include media-breakpoint-down(md) {
            margin-top: rem(20px);
        }

        .price .label {
            display: none;
        }

        .label {
            @include font-bold;
        }
    }

    // guest registry search
    &.guest-registry-view {
        .list-share {
            padding: rem(10px) 0;
        }

        .event-info,
        dl,
        dl dt:first-of-type,
        dl dd:first-of-type {
            margin: 0;
        }

        dl dt {
            @include font-bold;
        }

        .item-dashboard {
            padding-top: rem(20px);
        }

        .item-option {
            .label,
            .value {
                line-height: 2;
                width: 47%;
            }

            .value {
                float: right;
                text-align: right;
            }
        }

        form {
            width: 100%;

            button {
                height: $lg-btn-height;
            }
        }
    }
}
