.mini-cart-content-modal {
    display: none;
}

// give dialog greater specificity to override jquery
.add-to-cart-dialog.ui-dialog {
    position: relative;

    .ui-dialog-titlebar {
        padding: rem(5px) rem(10px);
    }

    .ui-dialog-title {
        width: 95%;

        span {
            display: inline-block;
            float: left;

            @include line-height(28px);
        }

        .icon {
            position: relative;
            top: -5px;
        }

        .view-cart-title-link {
            float: right;
            margin-right: rem(15px);
        }

        .lone-link {
            color: $inverted-text-color;

            @include font-reg($body-text-size);

            &:hover {
                color: $inverted-text-color;
            }
        }
    }

    .close-modal-div button {
        width: 100%;

        @include type-h5;
    }

    // modal body
    .ui-dialog-content {
        padding: rem(10px);

        // override widget base font size
        @include font-size($body-text-size);

        @include media-breakpoint-up(md) {
            padding: rem(20px);
        }
    }

    .add-to-cart-modal-recommendations {
        .slick-next {
            right: -0.5rem;
        }
    }

    // product line items
    .mini-cart-products {
        padding: 0;

        .mini-cart-product {
            padding: 0;

            .mini-cart-image {
                width: 20%;

                @include media-breakpoint-up(md) {
                    width: 22%;
                }
            }
        }

        .mini-cart-product-details {
            box-sizing: border-box;
            padding-left: rem(10px);
            margin-bottom: rem(10px);
            float: left;
            width: 78%;

            @include media-breakpoint-up(md) {
                padding-right: rem(20px);
            }
        }

        .mini-cart-price {
            display: block;

            @include type-h5;
        }

        .mini-cart-name,
        .mini-cart-qty,
        .mini-cart-pricing {
            margin-bottom: rem(10px);
        }

        // new layout in desktop
        @include media-breakpoint-up(md) {
            width: 50%;
            float: left;
            margin-bottom: rem(20px);
        }
    }

    // subtotal
    .mini-cart-totals {
        @include font-size($body-text-size);

        letter-spacing: 0.2px;

        // new layout in desktop
        @include media-breakpoint-up(md) {
            width: 50%;
            float: left;
        }

        .mini-cart-subtotals {
            @include media-breakpoint-down(sm) {
                padding: rem(10px) 0;
                border-top: $border2;
            }

            @include media-breakpoint-up(md) {
                float: left;
                padding: 0 0 rem(20px);
            }

            .value {
                margin-left: rem(10px);
            }

            .select-bonus {
                white-space: nowrap;

                @include media-breakpoint-up(md) {
                    float: right;
                    margin-right: rem(10px);
                }
            }
        }

        .atc-actions {
            @include media-breakpoint-up(md) {
                .close-modal-div,
                .mini-cart-link-checkout {
                    width: calc(50% - 10px);
                    float: left;
                    margin-right: 10px;
                }

                .close-modal-div {
                    button {
                        padding: 0;
                    }
                }

                .mini-cart-link-checkout {
                    clear: none;
                    margin: 0;
                    padding: 0;
                    line-height: 36px;
                }
            }
        }
    }

    // Credit Banner Asset styles in site asset scss
    .desktop-tablet {
        @include media-breakpoint-up(md) {
            clear: left;
        }
    }

    .add-to-cart-banner {
        width: 100%;
        margin: rem(10px) 0;
    }
}

.addedtocart-recommendations {
    li {
        display: initial;
    }

    .recommendation-header {
        text-align: center;

        h4 {
            margin-bottom: rem(16px);
        }
    }

    .tiles-container {
        height: rem(415px);
        display: flex;

        .tile-wrapper {
            box-sizing: border-box;
            display: flex;
            flex-basis: 20%;
            padding: 0 rem(10px);
        }

        & > div {
            &:first-of-type {
                padding-left: 0;
            }

            &:last-of-type {
                padding-right: 0;
            }
        }

        .product-name {
            @include font-size(12px);
        }

        .product-price {
            flex: 1 0 50px; // top align
        }

        .rating-wrapper {
            display: none;
        }
    }
}
