footer {
    background-color: $footer-bg;
    clear: both;
}

.footer-container {
    color: $body-text-color;

    @include content-width;

    @include media-breakpoint-down(sm) {
        width: calc(100% - #{rem(20px)});
    }

    @include media-breakpoint-up(md) {
        display: flex;
        flex-flow: row wrap;
        padding: 0 rem(15px);
    }

    @include media-breakpoint-up(lg) {
        padding: rem(1px) 0;
    }

    .footer-item {
        display: block;
        width: 100%;
        border-bottom: $border2;
        box-sizing: border-box;

        @include media-breakpoint-up(md) {
            border: 0 none;
            width: calc((100% - #{rem(233px)}) / 3);
        }

        @include media-breakpoint-up(lg) {
            padding: rem(24px) 0;
            max-width: rem(200px);
            width: calc((97% - #{rem(574px)}) / 3);

            &:nth-child(2) {
                max-width: rem(160px);
            }
        }

        h3 {
            @include type-h6;

            position: relative;
            padding: 0 rem(10px);
            margin: 0;

            @include line-height(46px);

            @include media-breakpoint-up(md) {
                @include line-height(19px);

                margin-bottom: rem(4px);
                padding: 0;
            }

            &.toggle {
                &::after {
                    content: "";
                    display: block;
                    position: absolute;
                    right: rem(16px);
                    top: rem(15px);
                    width: rem(16px);
                    height: rem(16px);
                    background: url("../images/svg-icons/increase.svg") no-repeat center;

                    @include media-breakpoint-up(md) {
                        content: none;
                    }
                }
            }

            &.expanded {
                &::after {
                    background: url("../images/svg-icons/decrease.svg") no-repeat center;
                }
            }
        }

        h4 {
            margin: 0;
        }

        .note {
            margin-bottom: rem(10px);

            @include media-breakpoint-up(md) {
                margin: 0;
            }

            @include media-breakpoint-up(lg) {
                margin: rem(2px) 0 rem(17px);
            }
        }

        &.email-item {
            padding: rem(12px) 0;

            @include media-breakpoint-up(md) {
                width: 100%;
                padding: rem(20px) 0 rem(30px);
            }

            @include media-breakpoint-up(lg) {
                width: rem(351px);
                border-right: $border2;
                padding-right: rem(58px);
                max-width: none;
                margin-right: 3%;
                padding-top: rem(50px);
            }

            h4 {
                @include line-height(26px);

                @include media-breakpoint-up(lg) {
                    @include line-height(24px);
                }
            }

            .sign-up-title {
                @include media-breakpoint-up(md) {
                    float: left;
                }
            }
        }

        &.social-item {
            border: 0 none;
            margin-bottom: rem(20px);
            padding: 0 rem(10px);

            @include media-breakpoint-up(md) {
                width: rem(233px);
                padding: 0;
                margin-bottom: rem(30px);
            }

            @include media-breakpoint-up(lg) {
                width: rem(223px);
                padding: rem(24px) 0;
                max-width: none;
                margin-bottom: 0;
            }

            h4 {
                @include media-breakpoint-up(md) {
                    @include type-h5;
                    @include line-height(24px);
                }

                @include media-breakpoint-up(lg) {
                    @include font-size(18px);
                }
            }

            a {
                @include line-height(19px);

                &.lone-link {
                    color: $link-hover-color;

                    @include font-bold($body-text-size);
                    @include line-height(20px);

                    display: inline-block;
                    text-decoration: $link-hover-decor;
                    margin-top: rem(2px);
                }
            }

            .call-us {
                @include line-height(24px);

                margin-top: rem(20px);

                @include media-breakpoint-up(lg) {
                    margin-top: rem(17px);
                }

                a {
                    @include type-h5;
                }
            }
        }
    }

    .menu-footer {
        float: none;

        @include media-breakpoint-up(md) {
            display: block !important;
        }

        li {
            float: none;
            list-style: none;

            @include line-height(19px);

            margin-bottom: rem(13px);
            padding-left: rem(10px);

            @include media-breakpoint-up(md) {
                padding: 0;
                margin-bottom: rem(8px);
            }
        }
    }

    a {
        @include type-footer-link;
    }
}

.social-links {
    margin: rem(20px) 0;
    padding: 0;
    display: table;
    width: 100%;
    line-height: 1;

    @include media-breakpoint-up(md) {
        margin-top: 0;
    }

    li {
        display: table-cell;
        padding: 0 rem(10px);
        text-align: center;
        list-style: none;

        @include media-breakpoint-up(md) {
            padding: 0 rem(12px);
        }

        &.first {
            text-align: left;
            padding-left: 0;
        }

        &.last {
            text-align: right;
            padding-right: 0;

            @include media-breakpoint-up(md) {
                text-align: center;
                padding: 0 rem(12px);
            }

            @include media-breakpoint-up(lg) {
                text-align: right;
                padding-right: 0;
            }
        }

        a {
            text-decoration: none;
        }

        .icon {
            vertical-align: top;
        }
    }
}

#email-alert-signup {
    white-space: nowrap;
    position: relative;
    width: 100%;

    @include media-breakpoint-up(md) {
        float: right;
        max-width: rem(380px);
    }

    label {
        margin: 0;
    }

    input[type="email"] {
        padding-right: rem(40px);

        & + span {
            &.error {
                float: left;
            }
        }
    }

    button {
        width: rem(40px);
        height: rem(40px);
        padding: 0;
        border: none;
        background-color: $trans;
        position: absolute;
        right: 0;
        top: 0;
    }
}

span {
    &.email-signup-msg {
        color: $success-color;
        position: relative;
        display: inline-block;

        @include font-size(12px, 17px);

        letter-spacing: 0.1px;
        margin-top: rem(10px);
        clear: both;

        @include respond($large-breakpoint, $desktop-breakpoint) {
            float: right;
            width: rem(380px);
            box-sizing: border-box;
        }

        &.error {
            color: $error-color;
            padding-left: rem(24px);

            &::before {
                content: "";

                @include type-error-icon;

                display: block;
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }
}

.copyright {
    text-align: center;
    background: $footer-copy-bg;
    color: $link-color;

    @include font-size(12px, 28px);

    @include media-breakpoint-up(md) {
        @include line-height(30px);
    }

    .copyright-container {
        @include media-breakpoint-up(md) {
            @include content-width;
        }

        .copy-links {
            @include media-breakpoint-up(md) {
                float: left;
            }

            a {
                margin: 0 rem(10px);

                @include type-footer-link;

                @include media-breakpoint-up(md) {
                    margin: 0 rem(15px);
                }

                &.first {
                    @include media-breakpoint-up(md) {
                        margin-left: 0;
                    }
                }
            }
        }

        .copy {
            @include media-breakpoint-up(md) {
                float: right;
            }
        }
    }
}

.back-to-top {
    visibility: hidden;
    position: fixed;
    bottom: 30px;
    right: 30px;
    opacity: 0;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s;

    &.show {
        visibility: visible;
        opacity: 1;
    }

    .back-to-top-icon {
        display: inline-block;
        width: 36px;
        height: 36px;
    }
}
