#ModalViewDialog {
    color: $body-text-color;

    .form-row {
        .label {
            @include type-label;

            display: block;
            float: left;
            width: rem(100px);

            & + .field-wrapper {
                float: left;
                width: calc(100% - #{rem(100px)});
            }
        }

        .field-wrapper {
            width: 100%;
        }

        &.radio {
            overflow: auto;
            max-height: rem(300px);

            @include media-breakpoint-up(md) {
                max-height: rem(425px);
            }
        }
    }
}

.header_prompt {
    h4 {
        margin: 0;
    }

    p {
        margin-top: 0;
    }
}

.col-items {
    .header_prompt-subtitle {
        margin: 0 0 rem(15px);
        padding: rem(5px) 0;
        border-top: $border2;
        border-bottom: $border2;

        h5 {
            margin: 0;
            float: left;
        }

        a {
            @include type-lone-link;

            float: right;
        }
    }

    .enter_partial {
        @include media-breakpoint-up(md) {
            float: left;
            width: 48%;
        }

        & + .right_details {
            margin-top: rem(20px);

            @include media-breakpoint-up(md) {
                float: right;
                width: 48%;
                margin-top: 0;
            }
        }

        .picklist {
            display: none;
            max-height: rem(315px);
            overflow: auto;

            div {
                margin: rem(10px) 0;

                span {
                    display: inline-block;
                }
            }
        }
    }
}

.partialPickList {
    max-height: rem(315px);
    overflow: auto;
}

.enter_email .error_message.unreachable,
.enter_email .error_message.undeliverable,
.enter_email .error_message.illegitimate,
.enter_email .error_message.disposable,
.enter_email .error_message.unknown,
.enter_phone .error_message.unreachable,
.enter_phone .error_message.undeliverable,
.enter_phone .error_message.illegitimate,
.enter_phone .error_message.disposable,
.enter_phone .error_message.unknown {
    display: none;
}

.ui-dialog {
    &.qas-dialog {
        z-index: $ui-zindex-dialog + 1 !important;

        & + .ui-widget-overlay {
            z-index: $ui-zindex-dialog !important;
        }

        .newAddressText {
            margin-right: rem(15px);
        }
    }
}
