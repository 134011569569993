// --------------------------------------
// Footer Styles
// --------------------------------------

@mixin type-footer-link {
    color: $link-alt-color;
    text-decoration: $link-alt-decor;

    &:hover {
        text-decoration: $link-alt-hover-decor;
    }
}
