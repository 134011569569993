@use "sass:math";

// A useful mixin to output a list of CSS styles passed in as a map
// Example:
//  // set a map variable
//  $primary-nav: (
//      padding-top: .2em,
//      margin-top: .2em,
//      line-height: 1.3,
//  );
//  // now output with the mixin
//  @include output-styles($primary-nav);
// Compiles to:
//  .primary-nav {
//      padding-top: .2em;
//      margin-top: .2em;
//      line-height: 1.3;
//  }
@mixin output-styles($map) {
    @each $property, $value in $map {
        #{$property}: $value;
    }
}

// Menu - Base Styles
// --------------------------------------
@mixin menu() {
    float: left;
    margin: 0;
    padding: 0;

    li {
        float: left;
        list-style: none !important;
    }
}

// Row Machine
@mixin row-machine($numPerRow, $margin) {
    width: math.div(100% - (($numPerRow - 1) * $margin), $numPerRow);

    &:nth-child(n) {
        margin-bottom: $margin;
        margin-right: $margin;
    }
    &:nth-child(#{$numPerRow}n) {
        margin-right: 0;
        margin-bottom: 0;
    }
}

// Custom mixin for dark overlay background with opacity
// ex: @include overlay(0.8) - applies an opacity of 0.8
// --------------------------------------
@mixin overlay($op) {
    background-color: $box-drk-bg;
    background-size: cover;
    left: 0;
    opacity: $op;
    position: absolute;
    height: 100%;
    top: 0;
    width: 100%;
    z-index: $z-ui-widget-overlay;
}

// Triangle helper mixin
// @param {Length} $size - Triangle size
// @param {Direction} $direction - Triangle direction, either `top`, `right`, `bottom` or `left`
// @param {Color} $color [currentcolor] - Triangle color
@mixin triangle($size, $direction, $color) {
    height: 0;
    width: 0;
    border-width: $size;
    border-style: solid;
    border-color: transparent;

    @if $direction == up {
        border-bottom-color: $color;
    } @else if $direction == right {
        border-left-color: $color;
    } @else if $direction == down {
        border-top-color: $color;
    } @else if $direction == left {
        border-right-color: $color;
    }
}
