.pt_error {
    #main.registered {
        .account-nav-asset {
            border: 0;
            margin-top: 0;
            padding-top: 0;
        }
    }
}

.error-page-search {
    h5 {
        margin-bottom: rem(20px);
    }

    .search-form {
        .form-row {
            width: 75%;
            position: relative;
        }
    }

    .notfound-search {
        @include type-h5;

        margin: 0 0 rem(15px);
    }

    .input-text {
        margin-bottom: rem(10px);
        padding-right: 2.5rem;
    }

    button {
        height: rem(40px);
        width: rem(40px);
        padding: 0;
        border: none;
        background-color: $trans;
        position: absolute;
        right: 0;
        top: 0;
    }
}
