// --------------------------------------
// Account Styles
// --------------------------------------

@mixin type-account-logout-link {
    font-size: 0.6em;
}

@mixin type-address-list-title {
    @include visually-hidden;
}

@mixin type-address-text {
    @include font-reg;
    @include line-height($body-line-height);

    font-style: normal;
}

@mixin type-order-history-header-label {
    @include font-bold;
}

@mixin type-order-history-header-button {
    @include media-breakpoint-down(md) {
        font-size: 1em;
    }
}

@mixin type-account-nav {
    @include type-h5;

    border-bottom: 2px solid $trans;
    padding-bottom: rem(2px);
}

@mixin type-active-account-nav {
    color: $active-account-nav;
    border-color: $active-account-nav;
}

// --------------------------------------
// Product List Styles
// --------------------------------------

@mixin type-product-list-item-name {
    @include font-italic(1.2em);
}

@mixin type-option-add-to-cart-label {
    @include font-bold;
}

// --------------------------------------
// Login Page Styles
// --------------------------------------

@mixin type-oauth-first-paragraph {
    font-size: 2em;
}

// --------------------------------------
// Wishlist Styles
// --------------------------------------

@mixin type-list-share-options {
    font-size: 1.7em;
}
