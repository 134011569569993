.checkoutlogin {
    .login-box,
    .col-1,
    .col-2 {
        box-sizing: border-box;
    }

    .col-1 {
        .login-box {
            padding-right: 0;
        }
    }

    .col-2 {
        .login-box {
            padding-right: 0;
        }

        form {
            button {
                margin-top: 0;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        span.create-later {
            display: block;
            text-align: center;
        }
    }

    @include media-breakpoint-up(md) {
        hr {
            width: 300px;
            margin-left: 0;
            margin-bottom: 1rem;
        }

        .col-1 {
            form {
                width: 65%;
            }

            .login-box {
                padding-right: 120px;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        .login-box {
            input {
                width: 500px;
                margin-right: 10px;
            }

            span.create-later {
                margin-left: 0.5rem;
            }
        }

        .col-1 {
            form {
                width: 100%;
            }

            .form-row-button {
                width: 120%;
            }
        }

        .col-2 {
            .login-box {
                padding-left: 80px;
            }
        }
    }
}

.checkoutlogin,
.container-login-account {
    h1 {
        @include type-h2;
    }
}

.checkoutlogin,
.container-login-account,
.pt_wish-list,
.pt_gift-registry {
    .dialog-required {
        display: block;
        margin: rem(10px) 0;
    }

    .col-1 {
        float: left;

        @include media-breakpoint-up(lg) {
            border-top: 0;
            border-right: $border2;
            padding-top: 0;
            margin-top: 0;
        }
    }

    .col-2 {
        float: right;
        border-right: 0;
        border-top: $border2;
        margin-top: rem(15px);
        padding: rem(15px) 0 0;

        @include media-breakpoint-up(lg) {
            border-top: 0;
            padding-top: 0;
            margin-top: 0;
        }
    }

    .form-row-button {
        button {
            width: 100%;

            @include media-breakpoint-up(md) {
                width: rem(284px);
                padding: 0;
            }
        }
    }

    button[name$="login_unregistered"] {
        @include primary-button;
        @include responsive-button(rem(300px));
    }

    button[name$="login_register"] {
        margin-top: rem(25px);
        padding: 0;

        @include responsive-button(300px);
    }

    button[name$="login_login"] {
        @include responsive-button(rem(240px));
    }

    button[name$="login_verifyotp"] {
        @include responsive-button(rem(240px));
    }

    .login-rememberme {
        margin: 1em 0 0;

        @include media-breakpoint-up(md) {
            margin: 0 0 0 1em;
        }
    }

    #password-reset {
        @include type-lone-link;
    }

    .create-account-benefits,
    .create-giftregistry-benefits {
        margin-bottom: rem(50px);

        h3 {
            @include type-h5;
        }

        ul.no-bullets {
            margin: 0;
            padding: 0;

            @include font-reg($body-text-size, 20px);

            li {
                list-style: none;
                margin: rem(10px) 0;
                padding: 0;
            }

            .title {
                @include font-bold($body-text-size, 20px);
            }
        }
    }
}

.container-login-account {
    .col-1 {
        float: left;

        @include media-breakpoint-up(lg) {
            border: 0;
            padding-top: 0;
            margin-top: 0;
        }
    }

    .col-2 {
        float: right;
        border-right: 0;
        border-top: $border2;
        margin-top: rem(15px);
        padding-top: rem(15px);

        @include media-breakpoint-up(lg) {
            border-top: 0;
            border-left: $border2;
            padding-left: 3%;
            padding-top: 0;
            margin-top: 0;
        }
    }

    button[name$="login_register"] {
        margin-top: 0;
    }
}

.login-box {
    padding-right: rem(20px);

    h3 {
        margin: 0;
    }

    .field-wrapper {
        width: 100%;
    }

    p {
        margin: 1em 0;
    }

    .error {
        margin-left: 0;
    }

    span.error {
        display: block;
    }

    .login-box-content {
        .error-form {
            .error-icon {
                width: 48px;
                height: 48px;
                margin-top: -18px;
            }
        }
    }
}

.login-rememberme {
    display: inline-block;
    margin: 0 0 0 1em;
    vertical-align: middle;

    .field-wrapper {
        width: 100%;
    }
}

.login-oauth {
    p:first-child {
        @include type-oauth-first-paragraph;

        margin: 0;
        text-align: center;
    }
}

#OAuthProvider {
    display: block;
    width: 80%;
    margin-bottom: 1em;
}

.login-otp-form {
    padding: 10px 20px 20px;

    .login-otp-codesent {
        font-size: 1rem;
    }

    .cta-cancelotp,
    .cta-verifyotp {
        display: inline-block;
        width: 100%;
    }

    .cta-resendotp {
        margin-bottom: 20px;
    }

    .otp-cta-grid {
        @include column-respond((600px: 2, gutter: 5%));
    }
}
