.pt_checkout {
    #main {
        padding-top: 0;
        display: flex;
        flex-direction: column;

        @include media-breakpoint-up(md) {
            padding-top: rem(15px);
        }

        @include media-breakpoint-up(lg) {
            flex-direction: row;
        }

        @include media-breakpoint-down(md) {
            width: 100%;
        }
    }

    h3 {
        padding-bottom: 1em;
    }

    .primary-focus {
        .primary-content {
            width: 100%;
            float: none;

            @include media-breakpoint-up(lg) {
                box-sizing: border-box;
                padding-right: rem(60px);
                width: 92%;
                float: none;
            }
        }

        #primary {
            @include media-breakpoint-down(md) {
                width: calc(100% - 30px);
                max-width: $max-content-breakpoint;
                margin: auto;
            }
        }

        #secondary {
            width: 100%;
            float: none;
            padding-left: 0;
            margin-bottom: rem(30px);

            .section-header,
            .summary-asset {
                a {
                    @include type-lone-link;

                    font-weight: normal;
                }

                .number {
                    a {
                        text-decoration: none;
                    }
                }
            }

            @include media-breakpoint-up(lg) {
                float: none;
                width: 24%;
                margin-top: rem(110px);
            }
        }
    }

    .universal-drawer {
        .drawer-handle {
            border-top: 0;

            @include media-breakpoint-up(md) {
                border-top: $border2;
            }
        }

        &.remove-padding-bottom {
            padding-bottom: 0;
        }
    }

    .form-row-button {
        padding-top: rem(20px);
        margin-top: rem(30px);
        border-top: $border2;

        @include media-breakpoint-down(sm) {
            button {
                width: 100%;
            }
        }

        .pin-wrapper {
            button {
                &.pinned {
                    width: calc(100% - 30px);
                }
            }
        }
    }
}

// Checkout Steps Custom Styles

.checkout-progress-indicator {
    @include type-progress-indicator;

    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: rem(15px) 0 rem(30px) 0;
    border-bottom: $border2;

    li {
        position: relative;
        padding-top: rem(25px);

        .progress-step-number,
        .icon {
            position: absolute;
            top: 0;
            left: 50%;

            @include type-progress-indicator-number;

            transform: translateX(-50%);
            height: 20px;
            width: 20px;
            border-radius: 3em;
            display: flex;
            justify-content: center;
            align-items: center;

            span {
                color: $progress-indicator-number-color;
                line-height: 1em;
            }
        }

        &.active {
            color: $progress-indicator-active-color;

            .progress-step-number {
                background-color: $progress-indicator-active-color;
            }

            a {
                color: $progress-indicator-active-color;
            }

            .icon {
                fill: $progress-indicator-active-color;
            }
        }

        &.inactive {
            color: $progress-indicator-inavtive-color;

            .progress-step-number {
                background-color: $progress-indicator-inavtive-color;
            }
        }

        &::after {
            display: none;
        }
    }

    @include media-breakpoint-up(md) {
        padding: rem(20px) 0 rem(60px) 0;
        border-bottom: 0;

        li {
            padding-top: 0;
            display: flex;
            align-items: center;

            > span {
                order: 2;
            }

            > a {
                display: flex;
                align-items: center;

                > span {
                    order: 2;
                }
            }

            .progress-step-number,
            .icon {
                position: relative;
                top: initial;
                left: initial;
                transform: none;
                height: 24px;
                width: 24px;
                order: 1;
                margin-right: rem(10px);
            }
        }
    }

    @include media-breakpoint-up(lg) {
        width: 85%;
    }
}

// Billing Custom Styles
.checkout-form-section-header {
    margin: 0 0 rem(10px) 0;
}

.checkout-guest-login {
    padding: 0;
    width: 100%;

    a {
        @include button-text;
        @include font-reg($body-text-size);

        padding-left: rem(5px);
    }

    .login-box {
        h2,
        p {
            display: none;
        }

        .form-row-button {
            padding-top: 0;
            border-top: 0;
        }
    }
}

.checkout-form-header {
    margin: 0 0 rem(20px) 0;

    .required-indicator {
        display: block;
        margin: rem(10px) 0 0 0;
    }
}

.universal-drawer {
    .increase,
    .arrow-down {
        display: block;
    }

    .decrease,
    .arrow-up,
    .drawer-contents {
        display: none;
    }

    .increase,
    .decrease,
    .arrow-down,
    .arrow-up {
        position: absolute;
        right: 14px;
        top: calc(50% - 8.5px);
    }

    &.active {
        .increase,
        .arrow-down {
            display: none;
        }

        .decrease,
        .arrow-up,
        .drawer-contents {
            display: block;
        }
    }

    .drawer-handle {
        position: relative;
        box-sizing: border-box;
        padding: rem(20px) 0;
        margin: 0 0 rem(20px) 0;
        border-top: $border2;
        border-bottom: $border2;
        cursor: pointer;

        > div {
            display: inline-block;
        }

        @include media-breakpoint-up(md) {
            padding: rem(20px) rem(14px) rem(20px) 0;
        }
    }

    .drawer-contents {
        padding: 0 0 rem(20px) 0;

        @include media-breakpoint-up(md) {
            padding: 0 rem(20px) rem(20px) rem(20px);
        }

        &.border-bottom {
            border-bottom: $border2;
        }

        &.additional-margin {
            margin-bottom: rem(20px);
        }
    }

    &.account-module {
        line-height: 2.25em;
        align-self: flex-start;

        .drawer-handle {
            padding: rem(40px);
            border: 0;
            margin: 0;

            h4 {
                margin-right: rem(40px);
            }
        }

        .drawer-contents {
            padding: 0 rem(40px) rem(40px) rem(40px);
            margin-top: 0;
        }

        .form-row-button {
            margin-bottom: 0;
            margin-top: rem(40px);
        }

        .increase,
        .decrease,
        .arrow-down,
        .arrow-up {
            right: 40px;
        }
    }
}

.drawer-tile-layout {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-flow: column nowrap;

    @include media-breakpoint-up(md) {
        flex-flow: row wrap;
    }

    &.wrap-reverse {
        flex-flow: column nowrap;

        @include media-breakpoint-up(md) {
            flex-flow: row-reverse wrap;
        }
    }
}

.universal-drawer-cabinet {
    .universal-drawer {
        padding: 0;

        &:first-child {
            padding-top: rem(20px);
        }

        &:last-child {
            .drawer-handle {
                border-bottom: $border2;
            }
        }

        .drawer-handle {
            margin-bottom: 0;
            border-bottom: 0;
        }

        &.active {
            .drawer-handle {
                margin-bottom: rem(20px);
            }
        }

        &.radio-treatment {
            .fake-radio {
                position: relative;
                padding-top: 3px;
                margin-right: rem(5px);

                &::before {
                    content: "";
                    display: inline-block;
                    width: 16px;
                    height: 16px;
                    border: $input-border;
                    margin-right: 8px;
                    vertical-align: text-bottom;
                    text-align: center;
                    border-radius: 3em;
                    background: $input-bg; // should not be $trans
                }

                .icon {
                    display: none;
                    position: absolute;
                    top: 4px;
                    left: 1px;
                }

                @include media-breakpoint-down(md) {
                    position: absolute;
                    left: 0;

                    &::before {
                        position: absolute;
                        left: 0;
                    }
                }
            }

            &.active {
                .fake-radio {
                    .icon {
                        display: block;
                    }
                }
            }

            &:first-child {
                .drawer-handle {
                    border-top: 0;
                }
            }

            &:last-child {
                .drawer-handle {
                    border-bottom: 0;
                }
            }

            .drawer-handle {
                @include media-breakpoint-down(md) {
                    border-top: $border2;

                    > span {
                        position: relative;
                        padding-left: 30px;
                        line-height: 1.5em;
                    }
                }
            }
        }
    }
}

.privacy-policy {
    @include button-text;
}

.checkout-guest-create-account {
    margin-top: rem(30px);
    padding: rem(20px) rem(20px) rem(30px) rem(20px);
    box-sizing: border-box;
    background: $subtle-contrast-bg;

    &.review {
        .order-review-account-form {
            display: flex;

            @include media-breakpoint-down(sm) {
                flex-direction: column;
                padding-bottom: 1rem;
            }

            .field-wrapper {
                width: 100%;
                padding-right: 50px;
            }
        }
    }

    .section-header {
        padding-top: 0;
    }

    p {
        padding: 0 0 rem(20px) 0;
        box-sizing: border-box;
        margin: 0;
    }

    .dialog-required {
        em {
            @include type-dialog-required;
        }
    }

    .form-row {
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.input-with-tooltip {
    position: relative;

    .field-wrapper {
        width: 90%;
    }

    .tooltip {
        position: absolute;
        left: initial;
        right: 0;
        top: rem(32px);

        .icon {
            height: 16px;
            width: 16px;
        }
    }

    @include media-breakpoint-up(md) {
        .field-wrapper {
            width: 50%;
        }

        .tooltip {
            left: 51%;
            right: initial;
        }
    }
}

// Logged in
.checkout-logout {
    margin-bottom: rem(10px);
    border-bottom: $border2;

    h4 {
        display: inline-block;
        text-transform: capitalize;
        margin: 0;
    }

    span {
        float: right;

        @include font-reg(15px);

        a {
            text-decoration: underline;
        }
    }
}

.false-header {
    margin-top: rem(20px);
    padding-bottom: rem(20px);
    border-bottom: $border2;
}

.billing-logged-in-address {
    margin-bottom: rem(20px);

    .address {
        div {
            margin-bottom: rem(15px);
        }
    }
}

.address-actions {
    span {
        &:first-child {
            margin-right: rem(10px);
        }

        &:last-child {
            margin-left: rem(10px);
        }

        a {
            text-decoration: underline;
        }

        &.edit-address {
            margin-left: 0;
        }
    }
}

.billing-form-container {
    display: none;

    .checkout-billing-submit {
        display: none;
    }
}

.missingState {
    color: #d41836;
    font-size: 0.75rem;
    line-height: 2em;
    letter-spacing: 0.1px;
}

.payment-method-expanded {
    display: block;

    .ssn {
        clear: both;
    }
}

.checkout-billing-modal-form {
    display: none;
}

.address-modal {
    .ui-dialog-titlebar {
        padding: rem(5px) rem(15px);

        @include type-checkout-modal-header;
    }

    h3 {
        &.error {
            margin: 0;
        }
    }

    .field-wrapper {
        width: 100%;
    }

    .has-tooltip {
        .field-wrapper {
            width: 93%;
        }

        .form-field-tooltip {
            width: 6%;

            @include line-height(40px);
        }
    }

    .actions {
        padding-top: rem(20px);
        margin-top: rem(10px);
        border-top: $border2;

        button {
            width: 100%;
        }

        .cancel {
            margin-top: rem(15px);

            @include tertiary-button;
        }

        .delete {
            display: none;
        }
    }

    @include media-breakpoint-up(md) {
        .actions {
            button {
                width: calc(100% * (1 / 2) - 2%);
            }

            .cancel {
                margin-top: 0;
                margin-left: 2%;
            }
        }
    }

    @include media-breakpoint-down(md) {
        position: fixed;
        max-height: 86%;
        overflow-y: scroll;

        .ui-dialog-titlebar {
            position: fixed;
            width: 98%;
            z-index: 2;
        }
    }
}

// Checkout Secondary Custom Styles

.summary {
    .section-header {
        padding: rem(10px);
        margin-bottom: rem(20px);
        background: $subtle-contrast-bg;

        h4,
        h6 {
            @include type-summary-section-header;

            margin: 0;
            display: inline-block;
        }

        a,
        .section-header-note {
            @include type-summary-edit-link;

            float: right;
            text-decoration: underline;
        }
    }

    h4 {
        @include type-summary-section-header;

        margin: 0;
    }

    .summary-wrapper {
        box-sizing: border-box;
        padding: 0 rem(10px) rem(30px) rem(10px);
    }

    .coupon-apply {
        .coupon-header {
            margin-top: 0;
            margin-bottom: rem(5px);
        }

        .summary-wrapper {
            padding-bottom: rem(20px);

            form {
                position: relative;
            }
        }

        input {
            width: 60%;
            margin-right: 5%;
            padding: 10px;
        }

        button {
            width: 30%;
            padding: 0;

            @include tertiary-button;

            position: absolute;
            right: 0;
        }

        #add-coupon {
            bottom: auto;
        }

        .summary-coupon {
            margin-top: rem(20px);
        }

        .label-above {
            width: 60%;
            margin-right: 5%;
            display: inline-block;
            margin-bottom: 0;

            .field-wrapper {
                input {
                    width: 100%;
                    margin-right: 0;
                }
            }

            > label {
                span {
                    color: $error-color;
                }
            }
        }

        .coupon-error-item {
            display: flex;
            align-items: baseline;
        }

        .error-icon {
            margin-right: 10px;
            width: 45px;
        }

        .coupon-error {
            line-height: 1.25em;
        }
    }

    .checkout-order-totals {
        .order-totals-table {
            td {
                padding: 0 0 rem(15px) 0;
            }
        }

        .order-subtotal,
        .order-shipping,
        .order-sales-tax,
        .order-fee {
            @include type-summary-order-review;
        }

        .order-discount {
            @include type-summary-order-discount;
        }

        .order-total {
            @include type-summary-order-total;

            td {
                padding-bottom: 0;
            }
        }
    }

    .checkout-mini-cart {
        max-height: initial;

        .mini-cart-image {
            display: none;
        }

        .mini-cart-product {
            padding: rem(10px) 0 rem(20px) 0;
            box-sizing: border-box;
            width: 100%;
            border-bottom: $border2;

            &:first-child {
                padding-top: 0;
            }

            &:last-child {
                padding-bottom: 0;
                border-bottom: 0;
            }

            .mini-cart-name {
                width: 100%;
                margin-bottom: rem(15px);

                @include type-summary-product-name;
            }

            .label {
                @include type-summary-product-label;

                display: inline-block;
                margin-bottom: rem(3px);
                text-transform: capitalize;
            }

            .value {
                @include type-summary-product-value;

                display: inline-block;
                margin-bottom: rem(3px);
            }

            .mini-cart-attributes {
                width: 65%;
                display: inline-block;

                .attribute {
                    &:last-child {
                        .label,
                        .value {
                            margin-bottom: 0;
                        }
                    }
                }
            }

            .mini-cart-price {
                @include type-summary-product-value;

                display: block;
                margin-top: rem(3px);
            }

            .mini-cart-pricing {
                margin-bottom: 0;
                float: right;
                width: 30%;
                text-align: right;
            }

            .product-availability-list {
                width: 100%;
                margin-bottom: 0;
                color: $error-color;

                li {
                    padding-left: 0;
                }
            }
        }

        .order-component-block {
            margin: 0 rem(10px) rem(15px) rem(10px);
            padding-bottom: rem(20px);
            border-bottom: $border2;

            .section-header {
                @include type-summary-order-compnt-header;

                background: $trans;
                margin-bottom: rem(15px);
                padding: 0;
            }

            .details {
                padding-bottom: rem(20px);
                margin-bottom: rem(15px);
                border-bottom: $border2;

                &:last-child {
                    padding-bottom: 0;
                    margin-bottom: 0;
                    border-bottom: 0;
                }
            }

            .address {
                div {
                    margin-bottom: rem(10px);

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            &.mini-payment-instrument {
                .details {
                    div {
                        margin-bottom: rem(10px);

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }

            .minishipments-method {
                margin-top: rem(10px);
            }

            .label {
                @include type-summary-product-label;
            }

            .value {
                @include type-summary-product-value;
            }

            &:last-child {
                border-bottom: 0;
                margin-bottom: 0;
            }
        }
    }

    .need-help-asset {
        padding: rem(10px);
        text-align: right;

        span {
            &:first-child {
                @include font-bold(15px);

                margin-right: rem(5px);
            }

            &:last-child {
                @include type-h4;
            }
        }
    }

    .shop-confidence-asset {
        box-sizing: border-box;
        padding: rem(15px);

        .icon {
            margin-top: -8px;
            margin-right: rem(5px);
        }

        span {
            @include type-h5;
        }
    }

    .asset-background {
        background: $subtle-contrast-bg;
    }
}

// Shipping Custom Styles

.ship-to-multiple {
    padding: rem(20px) 0;

    @include type-h5;

    border-top: $border2;
    border-bottom: $border2;

    button {
        display: none;
    }

    button,
    a {
        @include font-reg(15px);

        margin-top: rem(15px);
    }

    @include media-breakpoint-up(md) {
        button,
        a {
            float: right;
            margin-top: 0;
        }
    }

    &-title {
        margin-bottom: 1.25rem;
    }
}

.shipping-address {
    margin-top: rem(20px);
}

.checkout-shipping {
    .section-header {
        @include type-h4;

        padding-top: 0;
    }

    .select-style {
        width: 100%;
        margin-bottom: rem(15px);

        @include media-breakpoint-up(sm) {
            width: 40%;
        }
    }

    &.name {
        display: flex;
        flex-direction: column;
        margin-bottom: rem(15px);

        .firstName,
        .lastName {
            display: inline-block;
        }

        > div {
            margin-bottom: rem(10px);
        }
    }

    .standard-shipping {
        text-decoration: line-through;
    }

    .discount-shipping {
        color: $error-color;
    }
}

.checkout-multi-shipping {
    .standard-shipping {
        text-decoration: line-through;
    }

    .discount-shipping {
        color: $error-color;
    }
}

.checkout-section {
    padding-bottom: rem(30px);
    margin-bottom: rem(20px);
    border-bottom: $border2;

    &.removed-padding-bottom {
        padding-bottom: 0;
    }

    &.content-below {
        margin-bottom: 0;
        border-bottom: 0;
        border-top: none;

        .drawer-handle {
            padding-top: 0;

            h4 {
                margin-top: 0;
            }
        }
    }

    .non-shipable-items {
        margin-top: 5px;
        margin-bottom: 0;

        .item {
            padding-left: 0;
        }
    }

    .go-back-to-cart {
        display: inline-block;
        margin-top: 20px;
    }
}

.shipping-methods {
    legend {
        @include type-h4;

        padding: 0;
        margin-bottom: rem(20px);
    }

    .form-row {
        margin-bottom: 0;
    }

    .shipping-promos {
        margin: 0;
    }

    label {
        margin-bottom: 0;
    }

    .form-caption {
        box-sizing: border-box;
        padding-left: rem(29px);
    }

    .tooltip {
        @include type-shipping-method-tooltip;

        margin-left: rem(5px);
        text-decoration: underline;
    }
}

.shipping-delay {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: 0;

    .field-wrapper {
        label {
            margin-bottom: rem(20px);

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    .delivery-date {
        margin-top: rem(20px);

        label {
            display: none;
        }
    }
}

.shipping-gift,
.ship-to-multiple {
    .form-row {
        margin-bottom: 0;
    }

    .radio {
        color: red;

        > label {
            .error-icon {
                display: none;
            }

            span {
                margin-bottom: rem(20px);
                display: block;
            }
        }

        > .form-row {
            margin-bottom: 0;
        }

        .field-wrapper {
            color: blue;

            label {
                margin-bottom: rem(20px);

                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }

        p {
            width: 100%;

            @include media-breakpoint-up(sm) {
                width: 40%;
            }
        }
    }

    .gift-messages {
        margin-top: rem(20px);

        .field-wrapper {
            margin-bottom: rem(15px);
        }

        .char-count {
            margin-top: rem(5px);
        }
    }
}

.section-header {
    .shipping-chart {
        @include font-reg(15px);

        margin-top: rem(5px);
        display: inline-block;

        @include media-breakpoint-up(md) {
            float: right;
            margin-top: 0;
        }
    }
}

// Billing Custom Styles

.billing-specific-styles {
    @include media-breakpoint-down(md) {
        .universal-drawer {
            .drawer-handle {
                border-top: $border2;
            }

            .drawer-contents {
                box-sizing: border-box;
                padding: 0 rem(10px);
            }
        }

        > h2 {
            margin-top: rem(20px);
            padding-bottom: rem(10px);
        }
    }
}

.billing-coupon-code {
    .form-row,
    .giftcert-apply {
        width: 100%;

        @include media-breakpoint-up(md) {
            width: 50%;
        }
    }

    .giftcert-apply {
        button {
            &:last-child {
                float: right;
                margin-top: rem(12px);

                @include type-lone-link;
                @include font-reg($body-text-size);
            }
        }
    }
}

.gc-messaging {
    display: flex;
    flex-direction: column;
    padding-top: rem(15px);
    margin-bottom: rem(10px);
    line-height: 1.5em;

    .remove-icon,
    .nav-orders {
        vertical-align: top;
    }
}

#pay-gc {
    padding-top: 0;

    .drawer-handle {
        border-top: none;
    }

    .universal-drawer {
        margin-top: 0;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.required-ind {
    margin: 0.625rem 0;
}

.select-payment-methods {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: 0;

    h4 {
        margin-bottom: 0;

        @include media-breakpoint-down(md) {
            @include type-h3;

            margin-top: rem(30px);
        }
    }

    .universal-drawer-cabinet {
        .universal-drawer {
            &:first-child {
                margin-top: rem(10px);
                padding-top: 0;
            }

            .drawer-handle {
                padding-top: rem(10px);
                padding-bottom: rem(10px);

                @include font-bold($body-text-size);

                div {
                    width: 60%;
                    vertical-align: middle;

                    @include font-bold($body-text-size);

                    line-height: 1.5em;
                }

                @include media-breakpoint-up(md) {
                    @include type-h5;

                    div {
                        width: auto;
                    }
                }
            }

            &.active {
                border-left: $border3;
                border-right: $border3;
                border-bottom: $border3;

                .drawer-handle {
                    background-color: $box-drk-bg;
                    border-top: $border3;

                    div {
                        color: $white;
                    }

                    .increase,
                    .decrease {
                        fill: $white;
                    }

                    .nav-cc {
                        fill: $white;
                    }
                }

                & + .universal-drawer {
                    .drawer-handle {
                        border-top: 0;
                    }
                }
            }
        }
    }

    .inline-icon {
        display: inline-block;

        @include media-breakpoint-down(md) {
            vertical-align: top;
        }

        .icon {
            width: rem(48px);
            height: rem(32px);
            margin-right: rem(10px);
            margin-left: rem(10px);
            float: left;

            @include media-breakpoint-up(md) {
                width: rem(65px);
                height: rem(42px);
                margin-right: rem(15px);
                margin-left: rem(10px);
                float: none;
            }
        }

        &.new-store-card {
            position: relative;

            .add {
                height: 18px;
                width: 18px;
                position: absolute;
                top: 0;
                right: 10px;
                margin: 0;

                @include media-breakpoint-down(md) {
                    right: 5px;
                    top: -2px;
                }
            }
        }
    }
}

.credit-questions-asset {
    border-top: $border2;
    margin-top: rem(20px);

    .question-links {
        width: 100%;

        div {
            width: 100%;
            display: flex;
            flex-direction: column;

            a {
                display: inline-block;
                margin-bottom: 5%;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            @include media-breakpoint-up(md) {
                flex-direction: row;
                justify-content: space-between;

                a {
                    margin-right: 2%;
                    margin-bottom: 0;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}

.rates-terms-dialog {
    // override of the default modal size
    // because this content is HUGE
    @include media-breakpoint-down(md) {
        @include font-size(12px);
    }

    @include media-breakpoint-up(md) {
        max-width: 70% !important;
    }

    .dialog-content {
        // override to make this specific modal scrollable
        max-height: 600px !important;
        overflow-y: scroll;
        overflow-x: hidden;
    }
}

.payment-method {
    clear: both;

    .ssn {
        width: rem(80px);
        margin-right: rem(10px);
        display: inline-block;
        vertical-align: top;

        label {
            display: none;
        }

        &:first-child {
            label {
                display: block;
                width: 300%;
            }
        }

        &:last-child {
            margin-right: 0;
        }

        .field-wrapper {
            width: auto;
        }

        @include media-breakpoint-down(md) {
            width: calc(100% * (1 / 3) - 1.75%);
            margin-right: 1%;

            &:first-child {
                label {
                    width: 200%;
                }
            }
        }
    }

    .month,
    .day,
    .year {
        display: inline-block;
        width: 17%;
        margin-right: rem(10px);

        label {
            display: none;
        }

        .field-wrapper {
            width: 100%;
        }

        @include media-breakpoint-down(md) {
            width: 100%;
        }
    }

    .month {
        label {
            display: block;
        }
    }

    .year {
        margin-right: 0;
    }

    .payment-method-dob {
        .birthdate {
            width: 100%;

            .field-wrapper {
                width: 100%;

                .date {
                    width: 30%;
                    margin-right: 20px;
                }
            }
        }

        @include media-breakpoint-up(md) {
            display: flex;
            align-items: center;

            .birthdate {
                .field-wrapper {
                    width: 50%;
                }
            }
        }
    }

    .expiration-date {
        .month {
            &.form-row {
                @include media-breakpoint-up(md) {
                    margin: 0 5px 0 0;
                }

                @include media-breakpoint-up(lg) {
                    margin: 0 5px 1.25rem;
                }
            }

            > label {
                width: 100%;

                @include media-breakpoint-up(md) {
                    width: 150%;
                }
            }
        }

        @include media-breakpoint-up(md) {
            display: flex;
            align-items: flex-end;
        }

        @include media-breakpoint-up(lg) {
            display: flex;
            align-items: flex-end;
        }
    }

    .checkout-form-section-header {
        margin-bottom: rem(20px);

        &.additional-info {
            h4 {
                display: inline-block;
            }
        }

        h4 {
            margin-top: 0;
        }

        .header-sub {
            @include font-bold($body-text-size);

            display: inline-block;

            @include media-breakpoint-down(md) {
                display: block;
            }
        }

        .header-sub2 {
            @include font-reg(12px);

            margin-left: rem(5px);
        }

        @include media-breakpoint-down(md) {
            > h4 {
                @include type-h4;
            }
        }
    }

    .required-ind {
        margin-top: rem(15px);

        .required {
            color: $error-color;
        }
    }

    p {
        margin-bottom: 0;
    }

    .catalog {
        .form-caption {
            @include font-reg(12px);
        }
    }

    .terms-section {
        margin-top: rem(30px);
        padding-top: rem(20px);
        border-top: $border2;
    }

    .terms-box {
        height: 350px;
        overflow-y: scroll;
        border: $border2;
        box-sizing: border-box;
        padding: rem(15px);
        margin-top: rem(30px);

        p {
            &:first-child {
                margin-top: 0;
            }
        }
    }

    .extended-agreement {
        margin-bottom: rem(30px);

        .form-row {
            margin-bottom: 0;
        }

        .field-wrapper {
            width: 100%;
        }

        p {
            @include type-extended-agreement;

            margin-left: 25px;
            margin-top: -5px;
        }

        @include media-breakpoint-down(md) {
            label {
                display: block;
                line-height: 1.25rem;
            }
        }
    }

    .header-with-tooltip {
        position: relative;
        display: inline-block;
        padding-right: 25px;

        .tooltip {
            position: absolute;
            right: 0;
            top: 2px;
        }
    }

    &.credit-card {
        .expiration-date {
            > label {
                display: none;
            }

            .error-message {
                position: absolute;
            }
        }

        @include media-breakpoint-down(md) {
            .year {
                margin-bottom: 0;
            }
        }
    }

    .cvn {
        position: relative;
        padding-right: rem(30px);
        width: 25%;

        .error-message {
            width: 150%;
        }

        .field-wrapper {
            width: 100%;
        }

        .form-field-tooltip {
            position: absolute;
            right: 0;
            top: 20px;
            width: auto;
            padding: 0;
            margin: 0;
        }

        @include media-breakpoint-down(md) {
            width: 50%;

            .error-message {
                width: 200%;
            }
        }
    }

    .accepted-cards-block {
        width: 100%;

        .icon {
            width: calc(100% * (1 / 4) - 4%);
            margin-right: 1%;
            margin-bottom: rem(20px);

            &:last-child {
                margin-right: 0;
            }
        }

        @include media-breakpoint-up(md) {
            width: 40%;

            .icon {
                width: calc(100% * (1 / 4) - 6%);
                margin-right: 2%;
                margin-bottom: rem(20px);

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}

.min-order-header {
    color: red;
    text-align: center;
    padding-bottom: 10px;
}

.min-order-msg {
    text-align: center;
}

// Place Order Custom Styles
.checkoutplaceorder,
.orderdetails-checkout {
    border-top: $border2;
    margin-top: 0;

    .cart-row {
        .item-quantity {
            width: 100%;
            display: flex;
            flex-direction: column;
            text-align: left;
            margin-top: rem(10px);

            div {
                display: block;
                max-width: 100%;
            }

            ul {
                max-width: 100%;

                li {
                    padding: 0;
                }
            }

            @include media-breakpoint-up(md) {
                width: 30%;
                margin-top: 0;
            }
        }

        .mobile-only {
            display: block;

            @include media-breakpoint-up(md) {
                display: none;
            }
        }
    }

    .item-stock {
        margin-top: rem(10px);
    }

    .item-list {
        border-top: $border2;
    }

    .item-total {
        width: 100%;
        text-align: left;
        margin-top: rem(10px);

        @include type-product-line-item-price;

        @include media-breakpoint-up(md) {
            width: 25%;
            text-align: right;
            margin-top: 0;
        }

        .mobile-only {
            margin-bottom: rem(5px);
        }

        .option-price {
            font-family: "nunito_sansregular", Arial, sans-serif;
        }
    }

    .item-list-heading {
        padding-top: rem(20px);

        @include media-breakpoint-down(md) {
            position: relative;
        }

        .item-shipping-address {
            .shipments-method,
            .delivery,
            .deliverydate {
                margin-top: rem(10px);
                display: block;
            }

            .label {
                @include type-summary-product-label;
            }

            .value {
                @include type-summary-product-value;
            }

            @include media-breakpoint-down(md) {
                margin-top: rem(10px);

                div {
                    display: inline-block;
                    line-height: 1.25em;
                }

                a {
                    position: absolute;
                    right: 0;
                    top: rem(20px);
                }
            }
        }
    }

    @include media-breakpoint-up(md) {
        margin-top: rem(60px);
    }
}

#dynamic-payment-message {
    margin-bottom: rem(10px);

    .dynamic-payment-message-response,
    .dynamic-payment-message-headline {
        font-size: 1.125rem;
    }
}

.secondary-place-order-button {
    .form-row {
        margin-bottom: 0;
    }

    button {
        width: 100%;
    }

    @include media-breakpoint-down(md) {
        width: calc(100% - 30px);
        max-width: $max-content-breakpoint;
        margin: auto;
        margin-bottom: rem(30px);
    }
}

.order-summary-footer {
    margin-top: rem(20px);

    @include media-breakpoint-down(md) {
        button {
            width: 100%;
        }
    }
}

.shipment-gift-message {
    span {
        display: block;
        margin-top: rem(5px);

        &:first-of-type {
            margin-top: rem(10px);
        }
    }
}

.place-order-billing-info,
.order-receipt-summary {
    padding-top: 1.25rem;

    .order-component-block {
        display: block;
        width: 100%;
        margin-bottom: rem(30px);

        &:first-child {
            border-bottom: $border2;
            padding-bottom: rem(20px);
        }

        @include media-breakpoint-up(md) {
            margin-bottom: rem(50px);
            padding-bottom: 0;
            display: inline-block;

            &:first-child {
                margin-right: 5%;
                border-bottom: 0;
                padding-bottom: 0;
            }

            &:last-child {
                margin-left: 5%;
            }
        }
    }

    .section-header {
        padding-bottom: rem(15px);
        margin-bottom: 0;

        @include type-order-summary-section;

        a {
            float: right;

            @include type-summary-edit-link;

            text-decoration: underline;
        }

        @include media-breakpoint-up(md) {
            border-bottom: $border2;
            margin-bottom: rem(20px);
        }
    }

    .address,
    .details {
        div {
            line-height: 1.5em;
        }
    }

    @include media-breakpoint-up(md) {
        display: flex;
    }

    .details {
        & + .details {
            margin-top: rem(10px);
        }
    }

    .label {
        @include type-summary-product-label;
    }

    .value {
        @include type-summary-product-value;
    }
}

.place-order-billing-info {
    .order-component-block {
        @include media-breakpoint-up(md) {
            width: calc(100% * (1 / 2) - 5%);
        }
    }
}

#checkout-promo {
    &.active {
        .drawer-handle {
            margin: 0;
            border-bottom: none;
        }

        .summary-wrapper {
            border-bottom: 1px solid #000;
        }
    }

    .drawer-handle {
        border-top: 1px solid #000;
        border-bottom: 1px solid #000;
        background-color: transparent;
    }

    .drawer-contents {
        padding-right: 0;
        padding-left: 0;
    }
}

.order-receipt-summary {
    .order-component-block {
        @include media-breakpoint-up(md) {
            width: calc(100% * (1 / 3) - 5%);
        }
    }
}

.summary-page {
    #main {
        display: flex;
        flex-direction: column;

        #secondary {
            margin-top: 0;

            @include media-breakpoint-down(md) {
                .need-help-asset {
                    display: none;
                }
            }
        }
    }

    .summary-content-wrapper {
        display: flex;
        flex-direction: column;

        @include media-breakpoint-up(lg) {
            flex-direction: row;
        }

        @include media-breakpoint-down(md) {
            #primary {
                order: 2;
            }

            #secondary {
                order: 1;
                margin-bottom: 0;
            }
        }
    }

    .breadcrumb-content {
        margin-bottom: 20px;

        @include media-breakpoint-down(md) {
            width: calc(100% - 30px);
            max-width: $max-content-breakpoint;
            margin: auto;

            .checkout-progress-indicator {
                margin-bottom: 0;
                border-bottom: 0;
            }
        }

        @include media-breakpoint-up(lg) {
            border-bottom: $border2;

            .checkout-progress-indicator {
                width: 65%;
            }
        }
    }

    @include media-breakpoint-down(md) {
        .checkout-mini-cart {
            display: none;
        }
    }
}

// Order Details Custom Styles
.orderdetails-checkout {
    border-top: none;

    @include media-breakpoint-up(md) {
        margin-top: rem(10px); // 30px on screen
    }

    .order-summary-section-header {
        margin-top: 40px;
        padding-top: 1.5rem;
        font-size: 1.25rem;
        font-family: "nunito_sansextrabold", Arial, sans-serif;

        @include media-breakpoint-down(sm) {
            margin-bottom: 20px;
        }
    }

    .order-conf-create-acct-section-footer {
        border-bottom: 1px solid #c0c0c0;
        margin-top: 1.875rem;
    }

    .order-number {
        margin-top: 0;
    }

    .item-list {
        border-bottom: $border2;
        margin-bottom: rem(20px);
        padding-bottom: rem(30px);
    }

    .order-information {
        margin-bottom: rem(30px);

        @include media-breakpoint-down(lg) {
            margin-bottom: 0;
        }
    }

    .order-receipt-summary {
        @include media-breakpoint-down(lg) {
            border-top: $border2;
            padding-top: rem(30px);
        }
    }

    .mini-payment-instrument {
        @include media-breakpoint-down(sm) {
            border-bottom: $border2;
            padding-bottom: rem(30px);
        }
    }

    .order-totals-table {
        td {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .order-date {
        @include font-bold($body-text-size);

        padding-top: rem(15px);
        display: block;

        @include media-breakpoint-up(md) {
            float: right;
        }
    }

    .checkout-section {
        padding-bottom: rem(20px);
    }

    .shipping-status,
    .trackingnumber,
    .shipping-method,
    .delivery,
    .deliverydate {
        display: block;
        margin-top: rem(10px);
    }

    .summarybox {
        .phone {
            display: none;
        }
    }

    .actions {
        margin-bottom: rem(30px);

        .button {
            padding: rem(15px) 0;
            width: 320px;

            @include media-breakpoint-down(md) {
                width: 100%;
            }
        }
    }

    .checkout-section-inner-area {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;

        .content-asset {
            flex-grow: 1;
        }
    }

    .order-summary-print-link {
        font-size: 1rem;
        text-decoration: underline;
        display: inline-block;
        margin-left: 2rem;
    }

    .order-summary-print-link img {
        vertical-align: middle;
        margin-right: 0.5rem;
    }
}

// Out Of Wallet Styles
.pt_outofwallet {
    #primary {
        width: 100%;
    }

    .breadcrumb {
        display: none;
    }

    h2 {
        border-bottom: $border2;
        padding-bottom: rem(15px);
        margin-bottom: rem(30px);
        margin-top: rem(60px);
    }

    .wallet-consent {
        width: 100%;

        .out-of-wallet-agree {
            margin-top: rem(20px);

            label {
                margin-bottom: rem(20px);
            }

            .form-row {
                span {
                    &:not(.error-icon) {
                        position: relative;

                        &::after {
                            content: "*";
                            position: absolute;
                            right: -8px;
                            top: 0;
                            color: $error-color;
                        }
                    }
                }
            }
        }

        .error {
            margin-bottom: rem(20px);
            display: none;
        }
    }
}

.out-of-wallet-section {
    width: 100%;

    .field-wrapper {
        width: 100%;
    }

    .radio {
        > label {
            margin-bottom: rem(20px);
        }
    }

    @include media-breakpoint-up(md) {
        width: 50%;
    }
}

.out-of-wallet-questions {
    &:not(.slick-initialized) {
        display: none;
    }

    .field-wrapper {
        width: 100%;

        label {
            display: inline-block;
        }
    }

    .credit-check-question {
        outline: none;

        h4 {
            margin-bottom: rem(15px);
        }

        p {
            width: 100%;

            @include font-bold($body-text-size);
        }

        .question-back {
            width: auto;

            @include font-bold($body-text-size);

            display: inline-flex;
            align-items: center;
            cursor: pointer;

            .icon {
                margin-right: rem(5px);
            }
        }

        .form-row-button {
            margin-top: rem(30px);
            width: 100%;
        }

        @include media-breakpoint-up(md) {
            .form-row-button,
            p {
                width: 50%;
            }
        }
    }
}

// Down payment styles
.down-payment-section {
    margin-top: rem(50px);
}

.downpayment {
    .order-info-block {
        line-height: 1.5em;

        .label {
            @include type-summary-product-label;

            width: auto;
            display: inline-block;

            @include media-breakpoint-up(md) {
                width: 25%;
            }
        }

        .value {
            @include type-summary-product-value;
        }
    }

    .dp-wrapper {
        border: $border2;
        box-sizing: border-box;
        padding: rem(20px) rem(5px);
        margin-top: rem(30px);

        > h4 {
            margin-top: 0;
        }

        @include media-breakpoint-up(md) {
            padding: rem(20px);
        }

        .button {
            &.lightbox-downpayment {
                padding-left: 1rem;
                padding-right: 1rem;
            }
        }
    }

    .payment-method-options {
        display: none;
    }

    .form-row-button {
        border-top: 0;
    }

    #pay-cc {
        .form-row-button {
            display: none;
        }
    }

    .credit-block {
        display: none;

        &.payment-method-expanded {
            display: block;
        }
    }

    .universal-drawer-cabinet {
        .universal-drawer {
            &:first-child {
                padding-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    #pay-ach {
        padding-bottom: 0;
        margin-bottom: 0;
    }

    .ach-content {
        .radio {
            margin-bottom: rem(30px);

            &::before {
                // required override
                display: none !important;
            }

            > label {
                display: none;
            }

            > .field-wrapper {
                label {
                    margin-right: rem(15px);
                }
            }
        }

        #routing-container {
            margin-bottom: 20px;

            .form-row {
                margin: 0;
            }
        }

        .ach-name-fields {
            margin-top: rem(20px);

            .form-row,
            .formfield {
                label {
                    font-family: inherit;

                    &.error {
                        padding-left: 25px;
                        position: relative;

                        .error-icon {
                            position: absolute;
                            top: -2px;
                            left: 0;
                        }
                    }
                }

                &:nth-of-type(1) {
                    width: calc(100% - 52%);
                    margin-right: 2%;
                    display: inline-block;
                    vertical-align: top;
                    float: left;
                }

                &:nth-of-type(2) {
                    width: calc(100% - 52%);
                    margin-left: 2%;
                    display: inline-block;
                    vertical-align: top;
                }
            }
        }
    }

    #downPaymentCreditCardList {
        @include media-breakpoint-down(md) {
            padding-left: rem(15px);

            label {
                padding-left: rem(30px);
                line-height: 1.5em;

                &::before {
                    position: absolute;
                    left: 0;
                }
            }
        }
    }
}

.downpayment-confirmation-container {
    margin-top: rem(10px);
    margin-bottom: rem(10px);
    display: flex;
    flex-flow: column wrap;

    @include media-breakpoint-up(lg) {
        @include content-width;

        flex-direction: row;
        justify-content: space-between;
    }

    .downpayment-area,
    .downpayment-module {
        background: $body-bg;
        box-sizing: border-box;
        margin: rem(15px) 0;
        overflow: auto;
        order: attr(data-order);
        width: 100%;

        @include media-breakpoint-up(lg) {
            order: 0;
        }

        &.first-in-mobile-order {
            @include media-breakpoint-down(md) {
                order: 1;
            }
        }

        &.second-in-mobile-order {
            @include media-breakpoint-down(md) {
                order: 2;
            }
        }
    }

    .downpayment-area {
        @include media-breakpoint-up(lg) {
            width: calc(58% - 15px);
        }
    }

    .downpayment-module {
        border-radius: $container-border-radius;
        box-shadow: $shadow2;
        padding: 0 rem(20px);

        @include media-breakpoint-up(lg) {
            width: calc(42% - 15px);
            padding: 0 rem(40px);
        }
    }
}

#EditAddressForm {
    @include media-breakpoint-down(md) {
        margin-top: rem(40px);
    }
}

#inline-billing-form,
.inline-shipping-form {
    margin-top: rem(20px);
    border-top: $border2;

    h3 {
        &.error {
            margin: 0;
        }
    }

    #EditAddressForm {
        margin-top: 0;

        .select-style {
            width: 100%;
        }

        .editaddress {
            width: 50%;

            @include media-breakpoint-down(md) {
                width: 100%;
            }

            .field-wrapper {
                width: 100%;
            }

            @include media-breakpoint-up(md) {
                .form-row,
                .formfield {
                    label {
                        &.error {
                            padding-left: 25px;
                            position: relative;

                            .error-icon {
                                position: absolute;
                                top: -2px;
                                left: 0;
                            }
                        }
                    }

                    &:nth-of-type(7) {
                        width: calc(100% - 52%);
                        margin-right: 2%;
                        display: inline-block;
                        vertical-align: top;
                        float: left;
                    }

                    &:nth-of-type(8) {
                        width: calc(100% - 52%);
                        margin-left: 2%;
                        display: inline-block;
                        vertical-align: top;
                    }

                    &:nth-of-type(9) {
                        width: 100%;
                        display: inline-block;
                        vertical-align: top;
                    }
                }
            }
        }
    }
}

.checkoutmultishipping {
    .cart-row {
        .inline-shipping-form {
            width: 100%;
            padding-top: rem(20px);

            .form-row,
            .formfield {
                display: block;

                &.hide {
                    display: none;
                }
            }

            button {
                width: auto;
                display: inline-block;
            }
        }
    }

    #EditAddressForm {
        @include media-breakpoint-up(lg) {
            .field-wrapper {
                width: 100%;
            }
        }
    }
}

.cart-row {
    flex-direction: column;
}

.prop-65-warning {
    border: none;
    margin: 10px 0;
}

.discount button {
    text-align: right;
}

.coupon-applied {
    max-width: 70%;
    color: #3c0;

    .icon {
        fill: #3c0;
    }

    button.remove-item,
    button.remove-item:hover {
        background-color: transparent;
    }
}

.coupon-text {
    display: inline-block;
    width: 85%;
    vertical-align: top;
}

@include media-breakpoint-up(md) {
    #shipment-arrive-by label,
    .shipment-arrive-by label {
        white-space: nowrap;
    }
}

input[type="radio"]:checked + label span.holiday-estimate {
    font-family: "nunito_sansregular", arial, sans-serif;
}

.pt_order-confirmation {
    .ebbo-membership-modal {
        visibility: hidden;
    }

    .ebbo-membership-banner {
        text-align: center;
    }
}

.ui-dialog {
    height: 600px;
    width: 316px;

    @include media-breakpoint-up(md) {
        height: 600px;
        width: 970px;
    }

    #dialog-container {
        text-align: center;
        padding: 0;

        iframe {
            border: 0;
        }
    }
}
