// --------------------------------------
// Search Results / Category Browse Styles
// --------------------------------------

@mixin type-no-hits-search-term {
    @include font-italic;
}

@mixin type-filter-button {
    @include font-size($body-text-size);
}

@mixin type-close-refinements {
    @include font-size($body-text-size);
}

@mixin type-infinite-scroll-loader {
    @include font-bold($body-text-size);
}

// --------------------------------------
// Product Compare Styles
// --------------------------------------

@mixin type-compare-item-number {
    @include font-reg(30px, 60px);
}

@mixin type-compare-table-product {
    @include font-reg;
}

// --------------------------------------
// Refinement Styles
// --------------------------------------

// Refinement Value Styles (Category, Color, Price, etc.)
@mixin type-refinement-value {
    @include font-size($body-text-size);
}

// Refinement Sub Value Styles (Sub Categories)
@mixin type-refinement-sub-value {
    @include font-size($body-text-size);
}
