.breadcrumb {
    @include type-breadcrumb;

    padding: 0;
    margin: 0;

    li {
        display: inline;
        list-style: none;
        padding-left: 0; // override list style
        &::after {
            content: "|";
            margin: 0 0.4em;
            color: $breadcrumb-spacer-color;
        }

        &:last-child {
            @include type-breadcrumb-last;

            &::after {
                content: "";
            }
        }

        &.breadcrumb-refined-by,
        &.breadcrumb-refinement {
            &:last-child {
                @include type-breadcrumb-last;

                &::after {
                    content: "";
                }
            }
        }

        a {
            @include type-footer-link;
        }
    }
}

.breadcrumb-refinement {
    @include type-breadcrumb-refinement;

    &::after {
        @include type-breadcrumb-refinement-divider;

        content: attr(data-divider);
    }

    &:last-of-type {
        &::after {
            content: "";
        }
    }
}

.breadcrumb-refinement-value {
    display: inline-block;
    padding: 0 0.42em;
    margin: 0 0.42em 0.42em 0;
}

.breadcrumb-relax {
    @include type-breadcrumb-relax;

    padding: 0 0 0 0.4em;
    background: none;
    color: $error-color;
}

.breadcrumb-result-text a,
.breadcrumb .searchphraselink {
    padding: 0;
    background: none;
    color: $link-color;
}

.paging-current-page {
    position: absolute;
    left: -999em;
}
