/* Slider */

.slick-slider {
    display: block;
    position: relative;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: $trans;

    .slick-track,
    .slick-list {
        transform: translate3d(0, 0, 0);
    }
}

ul.slick-slider {
    padding-left: 0;
}

.slick-sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.slick-list {
    display: block;
    position: relative;
    overflow: hidden;
    padding: 0;
    margin: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}

.slick-track {
    display: block;
    position: relative;
    left: 0;
    top: 0;

    &::before,
    &::after {
        content: "";
        display: table;
    }

    &::after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}

.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;

    &.slick-loading {
        img {
            display: none;
        }
    }

    &.dragging {
        img {
            pointer-events: none;
        }
    }

    [dir="rtl"] & {
        float: right;
    }

    img {
        display: block;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid $trans;
    }
}

.slick-arrow {
    &.slick-hidden {
        display: none;
    }
}

.cross-sell ul li.grid-tile {
    height: 440px !important;
}
