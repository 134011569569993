// --------------------------------------
// Checkout Styles
// --------------------------------------

@mixin type-cart-shipment-label {
    @include font-bold(22px);
}

@mixin type-progress-indicator {
    @include type-h6;

    @include media-breakpoint-up(md) {
        @include type-h4;
    }
}

@mixin type-progress-indicator-number {
    @include media-breakpoint-up(md) {
        @include font-size(18px);
    }
}

@mixin type-progress-indicator-inner {
    text-transform: uppercase;
}

@mixin type-progress-indicator-inner-active {
    @include font-bold;
}

@mixin type-summary-section-header {
    @include font-bold(20px);
}

@mixin type-summary-edit-link {
    @include button-text;
    @include font-reg($body-text-size);
}

@mixin type-summary-order-review {
    @include font-bold($body-text-size);
}

@mixin type-checkout-modal-header {
    @include type-h4;
}

@mixin type-summary-order-discount {
    @include font-reg($body-text-size);

    color: $price-sale-color;
}

@mixin type-summary-order-total {
    @include font-bold(16px);
}

@mixin type-summary-product-name {
    @include font-bold($body-text-size);
}

@mixin type-summary-product-label {
    @include font-bold($body-text-size);
}

@mixin type-summary-product-value {
    @include font-reg($body-text-size);
}

@mixin type-summary-order-compnt-header {
    @include type-h5;
}

@mixin type-bml-terms-and-conditions {
    font-size: 0.8em;
}

@mixin type-order-block-header-link {
    @include font-reg;
}

@mixin type-summary-help {
    font-size: 0.8em;
}

@mixin type-back-to-cart {
    font-size: 1.1em;
}

@mixin type-order-details-label {
    @include font-bold;
}

@mixin type-line-item-details-link {
    @include font-size(18px);
}

@mixin type-order-info-label {
    @include font-reg;

    text-transform: none;
}

@mixin type-order-info-value {
    @include font-bold;
}

@mixin type-order-info-number {
    @include font-size(28px);
}

@mixin type-payment-amount-label {
    @include font-reg;

    text-transform: none;
}

@mixin type-order-shipment-details {
    @include font-bold;
}

@mixin type-order-shipment-gift-header {
    @include font-bold(1.2em);
}

@mixin type-order-shipment-gift-message {
    font-size: 1.1em;
}

@mixin type-cvn-tooltip {
    @include font-bold;
}

@mixin type-shipping-method-tooltip {
    @include font-reg($body-text-size);
}

@mixin type-multi-ship-label {
    @include font-bold;
}

@mixin type-in-store-shipment-method-inner {
    @include font-bold;
}

@mixin type-extended-agreement {
    @include font-reg($body-text-size);
}

// --------------------------------------
// Product Table Styles
// --------------------------------------

// Product Table Product Name Styles
@mixin type-product-table-name {
    @include font-size(16px);
}

@mixin type-product-line-item-price {
    @include type-h5;
}

@mixin type-order-summary-section {
    @include type-h5;
}

// --------------------------------------
// Multiship Styles
// --------------------------------------

@mixin type-multiship-table-header {
    @include type-h4;
}

@mixin type-multiship-order-address {
    @include font-bold($body-text-size);
}

// --------------------------------------
// Cart Styles
// --------------------------------------
@mixin type-cart-sku {
    @include font-reg(12px);
}

@mixin type-cart-product-name {
    @include font-bold($body-text-size);
}

@mixin type-cart-details-label {
    @include font-bold($body-text-size);
}

@mixin type-cart-details-value {
    @include font-reg($body-text-size);
}

@mixin type-cart-price-reg {
    @include type-h5;
}

@mixin type-cart-price-disc {
    @include type-h5;
}

@mixin type-cart-order-review {
    @include type-h5;
}

@mixin type-cart-order-discount {
    @include type-h5;

    color: $price-sale-color;
}

@mixin type-cart-order-total {
    @include type-h4;
}

@mixin type-cart-promo-label {
    @include font-bold(16px);

    @include media-breakpoint-up(md) {
        @include font-bold(20px);
    }
}

@mixin type-cart-promo-value {
    @include font-reg(16px);

    @include media-breakpoint-up(md) {
        @include font-reg(20px);
    }
}
