// ----------------------------------------------
//  EXTENSIBLE CLASSES
//  Extending an existing class adds the current selector to the chain of selectors for that extended class.
//  Example SCSS:
//  .myclass {color: #cf762d;}
//  .newclass {@extend .myclass;}
//  Compiles to CSS:
//  .myclass, .newclass {color: #cf762d;}
//  Best practices when using the @extend directive in Sass:
//  Make sure the extended selector (.myclass in the example above) is present only once throughout the site.
//  Avoid extending from nested selectors. This could create more CSS bloat than just copy and pasting those extended styles themselves.
//  Avoid chaining @extend directives.  Unpredictable behavior could result.
//  Don’t try extending from within a media query; it doesn’t work. For these scenarios, use the mixin instead.
//  Any of the following can apply directly in HTML as a class, or extend in SCSS using the @extend directive
// ----------------------------------------------

// Inner page content width
// --------------------------------------
.content-width {
    @include content-width;
}

// Clearfix
// --------------------------------------
.clearfix {
    @include clearfix;
}

// Visually Hidden Elements
// --------------------------------------
.visually-hidden {
    @include visually-hidden;
}

// Menu - Base Styles
// --------------------------------------
.menu {
    @include menu;
}

// Full-Bleed (using VW's)
// --------------------------------------
.full-bleed-width {
    @include full-bleed-width;
}

.hide {
    display: none;
}

.desktop-only {
    @include media-breakpoint-down(md) {
        display: none;
    }
}

.desktop-tablet {
    @include media-breakpoint-down(sm) {
        display: none;
    }
}

.tablet-only {
    @include media-breakpoint-up(lg) {
        display: none;
    }

    @include media-breakpoint-down(sm) {
        display: none;
    }
}

.tablet-mobile {
    @include media-breakpoint-up(lg) {
        display: none;
    }
}

.mobile-only {
    @include media-breakpoint-up(md) {
        display: none;
    }
}

.no-scroll {
    @include media-breakpoint-down(md) {
        overflow: hidden;
    }
}
