@import "nav";
@import "menu_utility";
@import "search_suggestion";
@import "minicart";
@import "addtocart_modal";

$color_1: #351d0e;

.skip-nav {
    position: absolute;
    left: -10000px;

    &:focus {
        top: 0;
        left: 0;
        z-index: 10000;
        padding: 0.5em;
        background-color: $skip-nav-focus-bg-color;
    }
}

.top-banner {
    @include clearfix;

    position: relative;
    padding: $top-banner-padding-mobile;

    @include media-breakpoint-up(md) {
        padding-top: $top-banner-padding-top-medium;
        padding-bottom: $top-banner-padding-bottom-medium;
    }

    // desktop has max width of 1440px
    @include media-breakpoint-up(lg) {
        padding: $top-banner-padding-top-large $top-banner-padding-right-large $top-banner-padding-bottom-large $top-banner-padding-left-large;
    }

    * {
        box-sizing: border-box;
    }

    &.simplified {
        .header-wrapper {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
        }

        .primary-logo {
            order: 1;
            margin: initial;
            margin-left: 5%;
            float: none;
            top: initial;
            margin-bottom: rem(15px);
        }

        .mini-cart-link {
            &::after {
                display: none;
            }
        }

        .simplified-header-content {
            @include font-size($body-text-size);

            order: 3;
            width: 100%;
            background-color: $subtle-contrast-bg;
            padding: rem(5px) 5%;

            .secure-line {
                @include font-bold;

                .icon {
                    margin-left: rem(2px);
                    position: relative;
                    top: -4px;
                }

                span {
                    display: none;
                }
            }

            .secure-line,
            .need-help-line {
                padding-top: rem(4px);
            }
        }

        .simplified-mini-cart {
            order: 2;
            margin-right: 5%;
            position: absolute;
            top: 18px;
            right: 0;
        }

        @include media-breakpoint-down(mobile-menu) {
            padding-left: 0;
            padding-right: 0;

            .secure-line {
                float: left;
            }

            .need-help-line {
                float: right;
            }
        }

        @include media-breakpoint-up(md) {
            .simplified-mini-cart {
                top: 32px;
            }
        }

        @include media-breakpoint-up(mobile-menu) {
            box-shadow: $shadow2;

            .header-wrapper {
                flex-wrap: nowrap;
            }

            .primary-logo {
                order: 1;
                margin-bottom: 0;
            }

            .simplified-header-content {
                order: 2;
                flex-grow: 1;
                width: auto;
                background-color: $trans;
                margin-right: 5%;
                text-align: right;
                padding: 0;

                .content-asset {
                    display: flex;
                    justify-content: flex-end;
                }

                .secure-line {
                    margin-right: 12%;

                    span {
                        display: inline-block;
                    }
                }
            }

            .simplified-mini-cart {
                order: 3;
                margin-right: 7%;
                position: relative;
                top: initial;
                right: initial;
                margin-bottom: rem(10px);
            }
        }
    }
}

.header-wrapper {
    // desktop has max width of 1440px
    @include media-breakpoint-up(lg) {
        max-width: $max-content-breakpoint-hf;
        margin: 0 auto;
        position: relative;
    }
}

.primary-logo {
    z-index: z("primary-logo");

    // width of image
    width: $logo-width-small;

    // height of image
    height: $logo-height-small;
    background: url("../images/svg-icons/logo-mobile.svg") no-repeat;
    background-size: contain;
    margin: 0 auto;
    position: relative;
    top: 3px;

    @include media-breakpoint-up(md) {
        background: url("../images/svg-icons/logo-tablet.svg") no-repeat;
        background-size: contain;
        height: $logo-height-tablet;
        width: $logo-width-tablet;
        top: -3px;
    }

    @include media-breakpoint-up(mobile-menu) {
        background: url("../images/svg-icons/logo.svg") no-repeat;
        background-size: contain;
        float: left;
        padding: 0;
        margin-left: 5%;
        height: $logo-height-desktop;
        width: $logo-width-desktop;
        top: 0;
    }

    // give link dimension for clicking
    a {
        display: block;
        height: 100%;
    }
}

.header-banner {
    display: block;

    .header-promotion {
        padding: 3px 0 2px;
        text-align: center;
        background-color: $header-banner-bg;
        color: #fff;
    }
}

.header-search {
    clear: both;
    position: relative;
    z-index: z("search");
    width: 100%;
    height: $input-height;
    border: $input-border;
    border-radius: $input-border-radius;
    margin: rem(18px) auto 0;
    background-color: $input-bg;
    color: $body-text-color-lt;

    @include media-breakpoint-up(md) {
        width: calc(100% - 20px);
        margin: rem(20px) auto 0;
    }

    @include media-breakpoint-up(mobile-menu) {
        width: 35%;
        top: $header-search-offset;
    }

    input[type="text"] {
        float: left;
        width: calc(100% - 2.5rem);
        height: $input-height;
        padding-left: 16px;
        margin: 0;
        background-color: $trans;
        border: none;

        @include media-breakpoint-up(mobile-menu) {
            padding: 0 1rem;
            color: $color_1;
            line-height: 2;
        }
    }

    button {
        padding: 0.25rem 0.6rem;
        border: none;
        background-color: $trans;
        color: $link-alt-color;
        position: absolute;
        right: 0;
        top: 0;

        @include media-breakpoint-up(mobile-menu) {
            min-height: 20px;
            padding: 0.2rem 0.6rem;
            line-height: 1.9rem;
        }

        &:hover {
            color: $link-hover-color;
        }
    }
}
