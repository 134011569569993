.pt_catalogrequest {
    .primary-content {
        float: left;
        padding-left: 0;
        width: 100%;
    }

    .dialog-required {
        display: block;
        margin: rem(10px) 0;
    }

    .wrapper-addToEmailList {
        label {
            display: inline-flex;

            &::before {
                width: 24px;
            }
        }

        input[type="checkbox"] + label::before {
            flex: none;
        }
    }

    .privacy-policy {
        margin-bottom: rem(40px);
    }

    .form-row-button {
        margin-top: rem(25px);

        button {
            width: 100%;
        }
    }

    .catalog-request-bottom-first {
        padding: rem(20px);
        border: 3px solid $border5-color;
        overflow: hidden;

        .col {
            float: left;
            padding: 0 rem(5px);
            width: 33%;
            box-sizing: border-box;

            .wrapper-image {
                max-width: rem(100px);

                &.left {
                    float: left;
                    width: 30%;
                }

                &.center {
                    width: 25%;
                    text-align: center;
                    margin: 0 auto;
                }

                &.creditcard {
                    margin-top: -20px;
                }

                img {
                    width: 100%;
                }
            }

            .wrapper-content {
                background-color: $lighter-gray;
                padding: rem(10px) 0;
                text-align: center;
                height: rem(105px);
                max-height: rem(105px);

                @include font-reg(12px);
            }

            .inner-content {
                float: right;
                width: 70%;
            }

            &:first-child {
                width: 100%;

                @include media-breakpoint-up(md) {
                    width: 33%;
                    padding: 0;
                }

                .wrapper-content {
                    padding: rem(10px) 0;

                    @include media-breakpoint-up(md) {
                        padding: rem(10px) 0;
                    }
                }
            }

            &:last-child {
                padding-right: 0;
            }
        }

        h5 {
            @include font-bold($body-text-size);

            margin: 0;
        }

        a.link-catalog-request {
            display: block;

            @include font-bold($body-text-size);

            color: $link-alt2-color;
        }
    }

    .catalog-request-right {
        display: none;
        margin-top: rem(20px);

        picture {
            img {
                width: 100%;
            }
        }
    }

    .form-field-tooltip {
        margin: 0 3%;
        position: absolute;
        top: -10px;
        left: rem(45px);
    }

    @include media-breakpoint-up(md) {
        #CatalogRequestForm {
            width: rem(420px);
        }

        .form-row {
            .field-wrapper {
                width: 94%;
            }
        }

        .has-tooltip-max-width .form-field-tooltip {
            width: 5%;
        }

        .container-form,
        .container-assets {
            float: left;
            width: 70%;
            padding-top: rem(20px);
        }

        .container-assets {
            border-top: $border2;
        }

        .catalog-request-right {
            float: right;
            width: 25%;
            display: block;
        }

        .form-row-button {
            button {
                width: 300px;
            }
        }
    }
}
