h1,
.h1 {
    @include type-h1;

    margin: 0.625rem 0;

    @include media-breakpoint-up(md) {
        margin: 0 0 rem(14px);
    }

    #secondary & {
        @include type-h1-secondary;

        margin: 0 0 em(14px);
    }
}

h2,
.h2 {
    @include type-h2;
}

h3,
.h3 {
    @include type-h3;
}

h4,
.h4 {
    @include type-h4;
}

h5,
.h5 {
    @include type-h5;
}

h6,
.h6 {
    @include type-h6;
}

.primary-content {
    dl {
        clear: both;
        overflow: hidden;

        dt {
            clear: both;
            float: left;
            margin: 2% 0 0;
            min-width: 15%;
        }

        dd {
            float: left;
            margin: 2% 0 0;
            padding: 0 0 0 5%;
        }
    }
}

.col-1,
.col-2 {
    width: 48%;
    margin: 4px 0;

    @include media-breakpoint-down(md) {
        float: none;
        width: 100%;
    }
}

.col-1 {
    float: left;
}

.col-2 {
    float: right;
}

.content-header {
    @include type-content-header;

    margin: 0 0 14px !important;

    span {
        @include type-content-header-inner;
    }
}

@mixin section-header() {
    @include type-section-header;

    position: relative;
    padding: 1em 0;
    margin: 0;
}

.section-header {
    @include section-header;

    table & {
        @include media-breakpoint-only(md) {
            @include type-table-section-header;
        }
    }
}

.section-header-note {
    @include type-section-header-note;
}

.back {
    display: block;
    margin: 0 0 10px;
}

.item-list {
    margin-bottom: 1em;

    tr {
        border-bottom: $border2;

        &.last {
            border: none;
        }

        &.headings {
            display: none;
        }
    }

    td {
        padding: 1em;
        vertical-align: top;

        img {
            max-width: 100%;
            min-width: rem(50px);
        }
    }
}

%product-table {
    td {
        display: block;
        float: right;
        box-sizing: border-box;
        width: 60%;
        padding: rem(16px) 0 0 2.5%;

        @include media-breakpoint-down(md) {
            &:last-of-type {
                padding-bottom: rem(10px);
            }
        }

        @include media-breakpoint-up(md) {
            display: table-cell;
            float: none;
            width: auto;
            padding: rem(10px);

            &:last-of-type {
                padding-right: 0;
            }
        }
    }

    .item-image {
        float: left;
        width: 40%;
        padding-left: 0;
        text-align: center;

        @include media-breakpoint-up(md) {
            float: none;
            width: auto;
            padding-left: rem(10px);
            padding-right: 0;
        }

        img {
            display: inline-block;
            max-width: 100%;
        }
    }

    .item-details {
        @include media-breakpoint-up(md) {
            width: 32%;
        }

        .name {
            @include type-product-table-name;
        }
    }

    .item-dashboard {
        @include media-breakpoint-up(md) {
            width: 38%;
        }
    }
}

.product-table {
    @extend %product-table;
}

div {
    &[class*="align-top-"],
    &[class*="align-middle-"],
    &[class*="align-bottom-"] {
        position: absolute;
    }

    &[class*="align-top-"] {
        top: 0;
    }

    &[class*="align-middle-"] {
        top: 50%;
        transform: translateY(-50%);
    }

    &[class*="align-bottom-"] {
        bottom: 0;
    }

    &[class*="-left-pos"] {
        text-align: left;
        left: 0;

        >* {
            justify-content: flex-start;
            align-items: flex-start;
        }
    }

    &[class*="-center-pos"] {
        text-align: center;
        left: 50%;
        transform: translateX(-50%);

        .width-third,
        .width-twothird,
        .width-half {
            margin: 0 auto !important;
        }

        >* {
            justify-content: center;
        }
    }

    &[class*="align-middle-center"] {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &[class*="-right-pos"] {
        right: 0;

        >* {
            justify-content: flex-end;
            align-items: flex-end;
        }
    }
}

* {
    &.width-third {
        max-width: 100%;
        width: 100%;

        @include respond(large-up) {
            max-width: 33.33%;
            width: 33.33%;
            display: inline-block;
        }
    }

    &.width-twothird {
        max-width: 100%;
        width: 100%;

        @include respond(large-up) {
            max-width: 66.66%;
            width: 66.66%;
            display: inline-block;
        }
    }

    &.width-half {
        max-width: 100%;
        width: 100%;

        @include respond(large-up) {
            max-width: 50%;
            width: 50%;
            display: inline-block;
        }
    }

    &.width-full {
        max-width: 100%;
        width: 100%;
    }
}

.bold {
    @include font-bold;
}

.text-white {
    span,
    h1,
    h2,
    h3,
    div {
        @include media-breakpoint-up(lg) {
            color: $white;
        }
    }
}

.pin-wrapper {
    * {
        transition: 0s;
    }
}

.pinned {
    z-index: 100;
    transition: 0s;
}
