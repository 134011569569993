@use "sass:color";

/* Import the base jQuery UI styles */
@import "jqueryui/jquery-ui";

.ui-icon {
    display: block;
    text-indent: -99999px;
    overflow: hidden;
    background-repeat: no-repeat;
}

.ui-widget {
    @include font-reg($body-text-size);
}

.ui-widget-overlay {
    background: url("../images/interface/ui-overlay-shadow.png") repeat scroll 50% 50% color.adjust($box-drk-bg, $lightness: -50%);
    opacity: 0.6;
    z-index: z("dialog-overlay");
}

.ui-dialog {
    background-color: $dialog-bg;
    padding: 0;
    border-width: 0;

    .ui-widget-header {
        background-color: $dialog-header-bg;
        border-color: $dialog-header-bg;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        box-sizing: border-box;
        height: 40px;
    }

    .ui-dialog-titlebar {
        padding: rem(10px);

        @include media-breakpoint-up(lg) {
            padding: rem(10px) rem(20px);
        }
    }

    .ui-dialog-title {
        @include font-bold;

        color: $dialog-title-color;
    }

    .ui-dialog-title-close {
        width: 40px; // larger clickable area
    }

    .ui-icon-closethick {
        background-image: url("../images/svg-icons/close-white.svg") !important;
        background-position: center top;
        height: 20px;
        width: 40px; // larger clickable area
        left: 0;
        top: 10px;
        margin-left: 0;
        margin-top: 0;
    }

    .ui-dialog-titlebar-close {
        background-color: $trans;
        border: none;
        border-radius: 0;
        height: 20px;
        margin: 0;
        padding: 0;
        right: 0;
        top: 0;
        width: 40px;

        span {
            margin: 0;
            padding: 0;
        }

        .ui-button-text {
            @include visually-hidden;
        }
    }

    h1 {
        // hide the page heading in dialogs
        // using dialog title bar to display titles
        // leave showing in case dialog does not initialize
        display: none;
    }

    .dialog-required {
        position: relative;
        text-transform: none;
        margin-bottom: rem(20px);
        display: block;
    }

    .ui-dialog-buttonpane {
        padding: 0 1.25rem;
        border-top: none;

        .ui-dialog-buttonset {
            float: none;

            .button {
                margin-bottom: rem(15px);

                &.primary {
                    @include primary-button;
                    @include responsive-button(150px);
                }

                &.secondary {
                    @include secondary-button;
                    @include responsive-button(150px);
                }

                &.tertiary {
                    @include tertiary-button;
                    @include responsive-button(150px);
                }

                span {
                    @include type-h5;
                }

                &.large span {
                    @include type-h4;
                }
            }

            .ui-button-text {
                padding: 0;
            }
        }
    }

    @include media-breakpoint-down(md) {
        max-width: 98%;
        min-width: 98%;

        .ui-dialog-content {
            height: auto !important;
            overflow: visible;
            padding: rem(10px);
        }
    }

    @include media-breakpoint-up(lg) {
        max-width: 98%;

        .ui-dialog-content {
            height: auto !important;
            overflow: visible;
            padding: rem(20px);
        }
    }
}

.ui-dialog-error {
    .has-error {
        color: $error-color;
        padding-left: rem(25px);

        &::before {
            content: " ";
            display: inline-block;
            background: url("../images/svg-icons/error-icon.svg") no-repeat;
            position: relative;
            top: 4px;
            width: 20px;
            height: 17px;
            margin-right: rem(4px);
            margin-left: rem(-25px);
        }
    }
}

.no-close {
    .ui-dialog-titlebar-close {
        display: none;
    }
}

.no-title {
    .ui-widget-header {
        background-color: $white;
        border-color: $white;
    }

    .ui-dialog-titlebar-close {
        right: rem(7px);
        top: rem(4px);
    }

    .ui-icon-closethick {
        background-image: url("../images/svg-icons/close-icon.svg") !important;
    }
}

.ui-accordion {
    .ui-accordion-content {
        padding: 0;
    }
}

.ui-tabs {
    .ui-tabs-nav {
        height: 2.45em;
        padding: 0;

        li {
            border-color: $base-bg;
            border-style: solid;
            border-width: 0 0 3px;

            &.ui-state-active {
                border-bottom-color: $accent1;

                a {
                    color: $link-alt-color;

                    @include font-bold;
                }
            }

            a {
                color: $body-text-color-lt;
            }
        }
    }

    .ui-tabs-panel {
        background-color: $base-bg;
        border: 1px solid $base-bg;
        line-height: 1.5em;
        margin: 0;
        padding: 20px 10px;

        @include media-breakpoint-only(md) {
            h3 {
                display: none;
            }
        }
    }
}
