.bonus-product-list {
    .promotion {
        margin: 0;
        padding: rem(15px) 0;

        .tooltip {
            .icon {
                margin: 0;
                top: rem(-3px);
            }
        }
    }

    .bonus-product-item {
        @include clearfix;

        border-top: $border2;
        padding: rem(20px) 0;

        .product-col-1,
        .product-col-2,
        .product-col-3 {
            .js & {
                @include media-breakpoint-down(sm) {
                    width: 100%;
                    max-width: 100%;
                }
            }
        }
    }

    .product-add-to-cart {
        padding-bottom: 0;
        border-bottom: 0 none;

        form {
            width: 100%;
        }
    }

    .quantity-error {
        text-transform: inherit;
        color: $error-color;
        position: absolute;

        &.error-msg {
            padding: rem(5px) 0 0 rem(24px);

            &::before {
                content: "";

                @include type-error-icon;

                display: block;
                position: absolute;
                top: rem(5px);
                left: 0;
            }
        }
    }

    .bonus-product-list-footer {
        @include clearfix;

        padding: 0;

        .add-to-cart-bonus {
            float: right;
        }
    }

    .selected-bonus-item {
        position: relative;
        margin: 1em 0 1em 1em;

        .item-name {
            @include type-selected-bonus-item-name;
        }

        .remove-link {
            position: absolute;
            left: rem(-8px);
            line-height: 1.6;
        }
    }
}

.bonus-discount-container {
    .promo-details {
        display: none;

        &.visible {
            display: block;
        }
    }
}
