@use "sass:math";

// Line Height Sizer
// ex:  @include line-height(14px);  // sets a line height size in rems with a fallback in pxs

@mixin line-height($size) {
    line-height: $size;

    @if unit($size) == "px" {
        line-height: rem($size);
    }
}

// Font Display Mixin
// ex: font-display: swap;  // sets a font to use next listed font until webfont is loaded then uses webfont
@mixin font-display($display) {
    @if $display == "swap" {
        font-display: swap;
    } @else if $display == "block" {
        font-display: block;
    } @else if $display == "fallback" {
        font-display: fallback;
    } @else if $display == "optional" {
        font-display: optional;
    } @else {
        font-display: auto;
    }
}

// Font Sizer
// ex: @include font-size(14px);  // sets a font size in rems with a fallback in pxs
// ex2: @include font-size(14px, $breakpoint: $medium-breakpoint);  // sets a scaling font size in vw's with a fixed fallback in pxs

@mixin font-size($size: null, $lineheight: null, $breakpoint: null) {
    @if $size and $size != "" {
        @if str-index(inspect($size), em) and not str-index(inspect($size), rem) {
            font-size: $size;
        } @else if not $breakpoint {
            font-size: $size;
            font-size: rem($size);
        } @else {
            // Get a font size in VWs that will match the given pixel font-size in the given viewport.
            font-size: $size;
            font-size: calculate-vw($size, $breakpoint);
        }

        @if $lineheight and $lineheight != "" and unit($size) == unit($lineheight) {
            $calculated-line-height: math.div(strip-units($lineheight), strip-units($size)) * 1em;

            @include line-height($calculated-line-height);
        }
    }
}

// Standard Font Families

// usage examples
// ex1: @include font-reg();
// ex2: @include font-reg(16px);
// ex3: @include font-reg(14px, 22px);

@mixin font-reg($fontsize: "", $lineheight: "") {
    font-family: $sans-serif-regular;
    font-weight: normal;

    @include font-size($fontsize, $lineheight);
}

// usage examples
// ex1: @include font-bold();
// ex2: @include font-bold(16px);
// ex3: @include font-bold(14px, 22px);

@mixin font-bold($fontsize: "", $lineheight: "") {
    font-family: $sans-serif-bold;
    font-weight: 700;

    @include font-size($fontsize, $lineheight);
}

// Font Style Mixin
// usage examples
// ex1: @include font-italic();
// ex2: @include font-italic(16px);
// ex3: @include font-italic(14px, 22px);

@mixin font-italic($fontsize: "", $lineheight: "") {
    font-style: $font-style-italic;

    @include font-size($fontsize, $lineheight);
}
