// --------------------------------------
// Product Tile Styles
// --------------------------------------

@mixin type-tile-product-name {
    letter-spacing: 0.1px;

    @include line-height(1.43);
}

@mixin type-tile-view-all-link {
    @include line-height(20px);
}

@mixin type-tile-product-promo {
    color: $brand-primary;

    @include type-h5;

    line-height: 1;
    margin-top: rem(10px);
}

@mixin type-product-compare {
    line-height: rem(11px);
}

@mixin type-product-compare-label {
    line-height: 1;
}
