html {
    &.menu-active {
        overflow-x: hidden;
    }
}

body {
    background: none repeat scroll 0 0 $body-bg;
    overflow-x: hidden; // to prevent full-bleed-width assets from causing a horizontal scroll bar to appear in page

    @include font-reg($body-text-size);

    letter-spacing: $body-letter-spacing;
    color: $body-text-color;

    // antialiasing for firefox
    -moz-osx-font-smoothing: grayscale;
}

img {
    max-width: 100vw;
}

a {
    color: inherit;
    text-decoration: inherit;
}

p,
%p {
    @include line-height($body-line-height);
}

.p {
    @extend %p;
}

hr {
    border: 0;
    height: 1px;
    background-color: $border2-color;
}

// alternative bolded paragraph style
.alt-p,
%alt-p {
    @include font-bold;

    letter-spacing: $body-alt-p-letter-spacing;
}

// fine print paragraphs
.info {
    @include font-size($body-text-size - 3px);
}

// let links in paragraphs inherit line height
p a {
    @include type-lone-link;

    line-height: inherit;
}

ul,
ol {
    @include line-height($list-line-height);

    letter-spacing: $list-letter-spacing;
    padding-left: rem($list-left-padding);

    li {
        padding-left: rem($list-left-padding);
    }
}

fieldset {
    border: none;
    margin: 0;
    padding: 0;
}

table {
    width: 100%;
}

th {
    text-align: left;
}

td {
    padding: 1em;
    vertical-align: top;
}

// needed for SVG links to work in IE
// click events consumed by <use> tag and do not bubble up
svg use {
    pointer-events: none;
}

// slick slider images pan and zoom feature enabled on all touchscreen devices
.slick-slider {
    touch-action: auto !important;
}
