.js {
    #suggestions {
        display: none;
        position: absolute;
        z-index: z("search", "search-suggest-js");
        padding: 0;
        background-color: $box-drk-bg;
        color: $subtle-text-color;
        cursor: pointer;

        div {
            clear: both;
            overflow: hidden;
            height: 23px;
            padding: 5px;
            white-space: nowrap;

            &:hover {
                background-color: $border-color;
                color: $body-text-color-drk;
            }

            .suggestionterm {
                float: left;
                overflow: hidden;
                width: 110px;
            }

            .hits {
                @include type-search-suggestion-hits;

                float: right;
                margin-left: 3px;
                text-align: right;
            }
        }
    }
}

.search-suggestion-wrapper {
    @include clearfix;

    position: absolute;
    top: 40px; // just under the input
    z-index: z("search", "search-suggest-wrapper");
    width: 100%;
    background-color: $base-bg;
    box-shadow: $shadow-tile;
    padding: rem(10px) 0;

    @include media-breakpoint-up(mobile-menu) {
        width: 150%;

        &.full {
            .phrase-suggestions {
                width: 50%;
                float: left;
                padding-left: rem(20px);
            }

            .product-suggestions {
                width: 50%;
                float: right;
                border-left: $border3;
                padding: rem(10px) 0;
            }
        }
    }

    .hitgroup {
        margin: 0 0 rem(30px);
        color: $body-text-color;

        .header {
            margin: 0 0 rem(5px) 0;
        }

        .hit {
            display: block;
            margin: rem(10px) 0;
            text-decoration: underline;

            @include font-bold;

            &:hover {
                text-decoration: underline;
            }
        }

        .parent-category {
            color: $body-text-color;

            @include font-reg;
        }
    }
}

.phrase-suggestions,
.product-suggestions {
    padding: rem(10px) rem(20px) rem(20px);

    @include media-breakpoint-up(md) {
        border-top: none;
    }
}

.product-suggestions {
    border-top: $border3;
    padding: rem(10px) 0 rem(20px);

    @include media-breakpoint-up(mobile-menu) {
        border-top: none;
        padding-left: 0;
        padding-right: 0;
    }
}

.product-suggestion {
    @include clearfix;

    $background-color: $base-bg;

    padding: rem(10px) rem(20px);

    @include media-breakpoint-up(mobile-menu) {
        padding: rem(10px);
    }

    &:hover,
    &:focus {
        background-color: $subtle-contrast-bg;
    }

    .product-image {
        float: left;
        width: 27%;
        text-align: center;

        img {
            max-width: 100%;
        }
    }

    .product-details {
        float: right;
        width: 73%;
        padding-left: rem(10px);
        color: $body-text-color;

        .product-price {
            @include type-h5;

            padding-top: rem(4px);
        }
    }
}

.search-phrase {
    @include type-h6;

    margin: rem(10px) 0;
    color: $body-text-color;
    padding: 0 rem(20px);

    @include media-breakpoint-up(mobile-menu) {
        margin-top: 0;
        padding: 0 rem(10px);
    }

    // adding quotes here to prevent added whitespace around the term
    a span:first-of-type:not(.original) {
        &::before,
        &::after {
            content: "\0022";  // double quote
        }
    }

    .completed {
        @include type-search-phrase-completed;

        .original {
            @include type-search-phrase-original;

            text-decoration: underline;
        }
    }

    .corrected {
        @include type-search-phrase-corrected;
    }
}
