@use "sass:list";

@each $type in $swatch-types {
    .swatch-#{"" + list.nth($type, 1)} a {
        background-color: list.nth($type, 2) !important;
    }
}

.swatch-miscellaneous {
    a {
        background: url("../images/interface/icon-color-swatch-misc.png") no-repeat scroll left top $trans;
    }
}

.unselectable {
    .swatch-miscellaneous {
        a {
            background: url("../images/interface/icon-color-swatch-misc-unselectable.png") no-repeat scroll left top $trans !important;
        }
    }
}
