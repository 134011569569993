.pt_product-search-result,
.pt_product-search-noresult,
.pt_content-search-result {
    #main {
        // Full bleed under 1024px, but keep the max-width constraint
        @include override-content-width-gutters;

        padding: $padding-search-results-mobile;

        @include media-breakpoint-up(md) {
            padding: rem(25px) rem(20px);
        }

        @include media-breakpoint-up(lg) {
            padding: rem(25px) rem(15px) rem(60px);
        }
    }

    .refinements {
        padding-left: 0;
    }

    .breadcrumb {
        padding: 0 0 rem(12px);
        display: none;

        @include media-breakpoint-up(md) {
            display: block;
        }
    }
}

#wrapper {
    &.open-refinements {
        @include media-breakpoint-down(md) {
            height: 100vh;
            overflow: hidden;
        }

        .menu-overlay {
            @include media-breakpoint-down(md) {
                display: block;
                background-color: $nav-mobile-overlay;
                opacity: 0.5;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 10;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        #bottom-cat-banner-container {
            width: 53.6%;
            float: right;
            margin: 0 20.8em 0 0;
        }
    }
}

.pt_content-search-result,
.pt_product-search-result:not(.pt_landing) {
    .primary-content {
        padding-bottom: 0;

        @include media-breakpoint-down(md) {
            width: 100%;
        }

        @include media-breakpoint-up(lg) {
            padding-left: rem(40px);
        }
    }

    #secondary {
        &.refinements {
            @include media-breakpoint-up(lg) {
                display: block !important;
            }

            @include media-breakpoint-down(md) {
                display: none;
                position: fixed;
                left: rem(10px);
                top: rem(10px);
                z-index: 100;
                background: $white;
                width: calc(100% - #{rem(20px)});
                height: calc(100% - 20px);
                overflow-y: scroll;
                box-sizing: border-box;
            }
        }
    }
}

.no-hits-search-term {
    @include type-no-hits-search-term;

    color: $error-color;
}

.no-hits-middle {
    border-bottom: $border2;
    border-top: $border2;
    padding: rem(25px) 0 rem(19px);
    margin-top: rem(20px);

    @include media-breakpoint-up(md) {
        padding-bottom: rem(5px);
    }

    h5 {
        margin: 0 0 rem(15px);
    }

    .no-hits-help,
    .no-hits-search {
        padding-bottom: rem(6px);

        @include media-breakpoint-up(md) {
            width: 49%;
            box-sizing: border-box;
            float: left;
        }
    }

    .no-hits-search {
        @include media-breakpoint-up(md) {
            float: right;
        }
    }
}

.no-hits-search {
    .search-form {
        position: relative;

        input {
            padding-right: rem(40px);
        }

        button {
            width: rem(40px);
            height: rem(40px);
            padding: 0;
            border: none;
            background-color: $trans;
            position: absolute;
            right: 0;
            top: 0;
        }
    }
}

.no-hits-footer {
    margin: rem(20px) 0;
}

.category-main-banner img {
    max-width: 100%;
}

.search-result-bookmarks {
    margin: rem(15px) 0;

    .first {
        padding-right: rem(5px);
        border-right: $border3;
    }
}

.search-result-options {
    overflow: hidden;

    &.top-options {
        border-bottom: $border2;
        padding-bottom: rem(10px);
        margin-bottom: rem(10px);

        @include media-breakpoint-up(md) {
            margin-bottom: rem(20px);
        }

        .pagination {
            @include media-breakpoint-up(md) {
                width: calc(100% - #{rem(334px)});
            }

            @include media-breakpoint-up(lg) {
                width: calc(100% - #{rem(360px)});
            }

            ul {
                @include media-breakpoint-down(sm) {
                    display: none;
                }

                @include media-breakpoint-up(xl) {
                    margin: rem(10px) 0;
                }

                & + .results-hits {
                    @include media-breakpoint-up(md) {
                        margin: 0;
                    }

                    @include media-breakpoint-up(xl) {
                        float: left;
                        text-align: left;
                        margin: rem(10px) 0;
                    }
                }
            }
        }
    }

    &.bottom-options {
        margin-bottom: rem(15px);

        @include media-breakpoint-up(md) {
            border-top: $border2;
        }

        .pagination {
            float: none;
            width: 100%;

            .results-hits {
                display: none;
            }

            ul {
                max-width: rem(300px);
                float: none;
                margin: 0 auto;
                display: flex;
                justify-content: center;
                padding-top: rem(14px);
            }
        }
    }
}

.selected-refinements {
    .selected-refinements-title {
        margin: rem(10px) 0;

        h5 {
            margin: 0;
            float: left;
        }

        a {
            float: right;
        }
    }

    .selected-refinement-value {
        display: inline-block;
        margin-right: rem(20px);

        @include line-height(20px);

        .icon {
            vertical-align: top;
            margin-top: rem(1px);
        }
    }
}

.refinement-select {
    @extend %select-style;

    float: left;
    width: calc(50% - #{rem(10px)});
    padding-left: rem(10px);

    @include media-breakpoint-up(md) {
        max-width: rem(140px);
        margin-right: rem(20px);
    }

    @include media-breakpoint-up(lg) {
        display: none;
    }
}

.sort-by,
.items-per-page {
    float: left;

    fieldset {
        @extend %select-style;

        min-width: 4.5em; // makes room for 2 digits in the items-per-page dropdown
    }

    label {
        @include visually-hidden;
    }

    button {
        display: none;
    }
}

.items-per-page {
    display: none;

    @include media-breakpoint-up(lg) {
        display: block;
        margin-left: rem(10px);
        max-width: rem(168px);
    }
}

.sort-by {
    @include media-breakpoint-up(md) {
        max-width: rem(174px);
    }

    @include media-breakpoint-down(sm) {
        float: right;
        width: calc(50% - #{rem(10px)});
    }
}

.pagination {
    margin: 0;

    @include media-breakpoint-up(md) {
        float: right;
    }

    ul {
        float: right;
        padding: 0;
        margin: 0;
    }

    li {
        display: block;
        float: left;
        list-style: none;
        padding: 0;

        &.current-page,
        a {
            display: block;
            padding: 0 rem(15px);

            @include font-bold;
            @include line-height(20px);
        }

        &.current-page {
            text-decoration: underline;
        }

        &.first-last {
            a {
                padding: 0;

                &.page-previous {
                    margin-left: rem(10px);
                }

                &.page-next {
                    margin-right: rem(10px);
                }

                .icon {
                    vertical-align: top;
                }
            }
        }
    }

    .results-hits {
        color: $body-text-color;

        @include line-height(20px);

        text-align: center;
        clear: both;
        margin-bottom: rem(10px);

        @include media-breakpoint-up(md) {
            text-align: right;
            margin: rem(10px) 0;
        }

        @include media-breakpoint-up(xl) {
            clear: none;
        }
    }
}

.search-refinements-section {
    display: none;
    border: $border;
    margin: 0 0.5em 20px;

    .refinement {
        box-sizing: border-box;
        width: 100%;
        padding: 0 15px 15px;
        border-bottom: $border;
        margin: 15px 0 0;

        &:last-child {
            border-bottom: none;
        }

        @include media-breakpoint-up(md) {
            width: 33.333%;
            border-bottom: none;
            border-left: $border;
        }

        @include media-breakpoint-only(md) {
            &:nth-of-type(3n + 1) {
                border-left: none;
            }
        }

        @include media-breakpoint-up(lg) {
            width: 25%;

            &:nth-of-type(4n + 1) {
                border-left: none;
            }
        }
    }
}

.search-refinements-section-inner {
    display: flex;
    flex-wrap: wrap;
}

.refinements-title {
    @include line-height(40px);

    position: relative;
    padding: 0 rem(15px);
    background: $dialog-header-bg;

    @include media-breakpoint-up(lg) {
        display: none;
    }

    h4 {
        margin: 0;
        color: $white;
    }

    .filter-close {
        position: absolute;
        width: rem(20px);
        height: rem(20px);
        top: rem(10px);
        right: rem(10px);
        cursor: pointer;
        line-height: 1;

        .icon {
            fill: $white;
            vertical-align: top;
        }
    }
}

.refinements-content {
    @include media-breakpoint-down(md) {
        padding: 0 rem(20px) rem(70px) rem(20px);
    }
}

.close-refinements {
    padding: rem(10px);
    background: $refinements-bottom-bg;
    position: fixed;
    bottom: rem(10px);
    width: calc(100% - #{rem(40px)});

    @include media-breakpoint-up(lg) {
        display: none;
    }

    .button {
        width: 100%;
    }
}

.search-result-content {
    clear: both;
    margin-bottom: rem(20px);

    .full-width & {
        margin: 0 0.5em;
    }
}

.search-result-items {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    justify-content: center;

    @include media-breakpoint-up(sm) {
        justify-content: space-between;
    }

    @include media-breakpoint-up(md) {
        justify-content: flex-start;
    }

    .full-width & {
        margin: 0 -1.5%;
    }

    li {
        list-style: none;
    }

    .grid-tile {
        box-sizing: border-box;
        padding: 0;
        margin: 0 0 rem(10px);
        max-width: rem(300px);
        vertical-align: top;
        background: $base-bg;
        width: 100%;

        @include media-breakpoint-up(sm) {
            width: 50%; // 2 tiles per row
        }

        @include media-breakpoint-up(md) {
            width: 33.33%; // 3 tiles per row
            margin: 0 0 rem(20px);
            padding: 0;
            max-width: 33.33%;
        }

        &.new-row {
            .product-tile {
                @include media-breakpoint-up(md) {
                    margin-left: 0;
                }
            }
        }

        &:nth-child(3n) {
            .product-tile {
                @include media-breakpoint-up(md) {
                    margin-right: 0;
                }
            }
        }

        .product-tile {
            @include media-breakpoint-up(md) {
                max-width: rem(300px);
                margin: 0 auto;
            }
        }
    }
}

.search-promo {
    padding: rem(20px);
}

#results-content {
    padding-top: rem(15px);
    border-top: $border2;
}

.folder-content-list {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    clear: both;
    padding: 0;
    margin: 0;

    li {
        box-sizing: border-box;
        list-style: none;
        flex-basis: 100%;
        flex-grow: 1;
        padding: rem(16px);
        border: $border2;
        margin: rem(10px);

        @include media-breakpoint-up(sm) {
            width: calc(50% - #{rem(10px)});
            flex-basis: 40%;
            vertical-align: top;
        }

        h6 {
            margin: 0;
        }

        .lone-link {
            white-space: nowrap;
        }
    }
}

.folder-content-list-actions {
    text-align: center;
    margin-top: rem(20px);
}

.no-results {
    padding: rem(20px);
}
