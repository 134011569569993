@media print {
    .pt_order-confirmation #mini-cart,
    .pt_order-confirmation .menu-utility-user,
    .pt_order-confirmation .menu-utility,
    .pt_order-confirmation .header-banner,
    .pt_order-confirmation #navigation {
        display: none;
    }

    .print-hidden,
    .header-banner-top,
    .top-banner,
    .header-banner-bottom,
    footer,
    .copyright,
    .account-nav-registered {
        display: none;
    }
}
