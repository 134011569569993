/*
*   This mixin generates a NEW or SALE product image badge
*   Pass in the badge type to have the css rules outputted
*   NOTE: all badges except for mobile PDP are 50px; mobile PDP is 40px
*   The classes for the largest, more commonly used size
*/

@mixin badge($type, $page: null) {
    box-sizing: border-box;
    border-radius: 50%;
    text-align: center;

    @include font-bold($body-text-size);

    text-transform: uppercase;
    color: $badge-text-color;
    border: 3px solid $badge-border-color;

    @if $page == pdp and $type != newcolor {
        width: 40px;
        height: 40px;
        line-height: 36px;

        @include media-breakpoint-up(md) {
            width: 50px;
            height: 50px;
            line-height: 44px; // account for border
        }
    } @else if $type == newcolor {
        width: 4.23em;
        height: 4.23em;
        padding-top: 0.72em;
        line-height: 1;
    } @else {
        // default to tile and desktop PDP version
        width: 50px;
        height: 50px;
        line-height: 44px; // account for border
    }

    @if $type == new {
        background: $badge-new-color;
    } @else if $type == sale {
        background: $badge-sale-color;
    } @else if $type == rebate {
        background: $badge-rebate-color;
        color: $badge-rebate-text-color;
        border-color: $badge-rebate-border-color;

        @include font-bold(10px);
    } @else if $type == newcolor {
        background: $badge-newcolor-color;
        font-size: $badge-newcolor-font-size;
    } @else {
        // default to new
        background: $badge-new-color;
    }
}

.badge-new {
    @include badge(new);
}

.badge-sale {
    @include badge(sale);
}

.badge-newcolor {
    @include badge(newcolor);
}

.badge-rebate {
    @include badge(rebate);
}

.badge-new-pdp {
    @include badge(new, pdp);
}

.badge-sale-pdp {
    @include badge(sale, pdp);
}

.badge-newcolor-pdp {
    @include badge(newcolor, pdp);
}

@mixin early-bird() {
    display: inline-block;
    background: $badge-sale-color; // sales messaging matches
    color: $inverted-text-color;
    height: rem(40px);
    padding: 0 rem(7px);

    @include font-bold($body-text-size);

    line-height: rem(24px);
}

@mixin early-bird-ribbon() {
    display: inline-block;
    background: none;
    height: rem(40px);
    padding: 0 rem(7px);

    @include font-bold($body-text-size);

    line-height: rem(24px);
    font-size: 20px;
    position: relative;
    text-align: center;
}

.callout-badge {
    @include early-bird;
}

.early-bird {
    @include early-bird-ribbon;

    &::after,
    &::before {
        content: "";
        position: absolute;
        border-style: solid;
    }

    &::before {
        top: 18px;
        right: -15px;
        border-width: 1.1em 1em 1.1em 3em;
        border-color: var(--ribbonLight) transparent var(--ribbonLight) var(--ribbonLight); /* stylelint-disable-line */
    }

    p {
        display: inline-block;
        padding: 0.5em;
        line-height: 1.2em;
        background: var(--ribbonLight); /* stylelint-disable-line */
        position: relative;
        color: #fff;

        &::after,
        &::before {
            content: "";
            position: absolute;
            border-style: solid;
        }

        &::after {
            top: 100%;
            right: 0;
            border-width: 0.5em 2em 0 0;
            border-color: var(--ribbonDark) transparent transparent transparent; /* stylelint-disable-line */
        }

        &::before {
            bottom: 100%;
            left: 0;
            border-width: 0.5em 0.7em 0 0;
            border-color: transparent var(--ribbonDark) transparent transparent; /* stylelint-disable-line */
        }
    }
}

.personalization-delay {
    margin: 0.5rem 0;
    display: block;
    color: #d41836;
}
