// Homepage: Organization-Level Styles
// should contain only strucural rules, i.e. values that will likely need
// to stay consistent from site to site, such as display, widths, and spacing.

.pt_storefront {
    #main {
        // override content-width for full bleed
        @include override-content-width;

        padding: 0 0 rem(60px) 0;
    }
}

.hero-container {
    position: relative;
    width: 100%;

    img {
        width: 100%;
        height: auto;
    }

    .hero-content {
        position: relative;
        box-sizing: border-box;
        padding-top: rem(30px);
        padding-left: rem(20px);
        padding-right: rem(20px);

        @include media-breakpoint-up(mobile-menu) {
            position: absolute;
            padding: 0;
            margin: 120px;
            top: 0;
            left: 0;

            // Take the margin out of the dimensions
            width: calc(100% - 240px);
            height: calc(100% - 240px);
        }

        &.stacked-banner {
            .hero-content-inner {
                display: flex;
                align-items: center;

                .inner-content {
                    &.first {
                        margin-right: 5%;
                        order: 1;
                    }

                    &.second {
                        margin-left: 5%;
                        order: 2;
                    }
                }
            }

            @include media-breakpoint-down(lg) {
                .hero-content-inner {
                    width: 100%;
                    position: relative;
                }
            }

            @include media-breakpoint-down(md) {
                .hero-content-inner {
                    flex-direction: column;

                    .inner-content {
                        &:first-child {
                            order: 2;
                            margin: 0;
                            margin-bottom: rem(20px);
                        }

                        &:last-child {
                            order: 1;
                            margin: 0;
                        }
                    }
                }
            }
        }
    }

    &:not(.side-by-side) {
        .hero-content-inner {
            &:not([class*="interior-button"]) {
                .button {
                    @include media-breakpoint-down(md) {
                        @include primary-button;
                    }
                }
            }
        }
    }

    .hero-content-inner {
        .type-hero {
            display: inline-block;
            line-height: 1em;

            @include font-size($hero-heading-sm);

            margin-bottom: rem(20px);

            @include media-breakpoint-up(md) {
                @include font-size($hero-heading-lg, $breakpoint: $max-content-breakpoint-hf);

                margin-bottom: rem(24px);
            }

            @include media-breakpoint-up(xl) {
                @include font-size($hero-heading-lg);
            }
        }

        h3 {
            @include font-size($hero-sub-heading-sm);

            @include media-breakpoint-up(md) {
                @include font-size($hero-sub-heading-lg, $breakpoint: $max-content-breakpoint-hf);
            }

            @include media-breakpoint-up(xl) {
                @include font-size($hero-sub-heading-lg);
            }
        }

        p {
            @include font-bold;
        }

        .button {
            & + .button {
                margin-top: rem(10px);

                @include media-breakpoint-up(md) {
                    margin-left: 4%;
                    margin-top: 0;
                }
            }
        }

        .type-hero,
        h4,
        h3,
        h2,
        h1,
        p {
            margin-top: 0;
            color: $content-tile-inner-header;

            &.inverse {
                color: $content-tile-inner-header-inverse;
            }

            &.primary {
                color: $content-tile-inner-header-primary;
            }
        }

        h1,
        h4 {
            margin-bottom: rem(20px);

            @include media-breakpoint-up(md) {
                margin-bottom: rem(24px);
            }
        }

        &.flex {
            display: flex;
        }

        &.drop-background {
            padding: 0;

            @include media-breakpoint-down(md) {
                // override inline style
                background-color: $trans !important;
            }

            @include media-breakpoint-up(md) {
                padding: rem(30px);
            }
        }

        @include media-breakpoint-down(md) {
            &:not(.mobile-content-inside) {
                position: relative;

                &[class*="align-top-"],
                &[class*="align-middle-"],
                &[class*="align-bottom-"],
                &[class*="-left-pos"],
                &[class*="-center-pos"],
                &[class*="align-middle-center"],
                &[class*="-right-pos"] {
                    top: initial;
                    bottom: initial;
                    transform: translate(0, 0);
                }

                .button {
                    width: 100%;

                    @include media-breakpoint-up(sm) {
                        width: auto;
                    }
                }
            }

            &:not(.mobile-content-inside, .split-item, [class*="mobile-content-inside"], .mobile-primary) {
                .type-hero,
                h4,
                h3,
                h2,
                h1,
                p {
                    color: $content-tile-inner-header;
                }
            }

            &[class*="mobile-content-inside"] {
                position: absolute;
                padding: rem(20px) rem(20px) rem(30px) rem(20px);
                box-sizing: border-box;
                width: 100%;

                &[class*="-bottom-center"] {
                    top: initial;
                    right: initial;
                    left: 0;
                    bottom: 35px;
                    text-align: center;

                    &[class*="width-"] {
                        max-width: 100%;
                    }
                }

                &[class*="-middle-center"] {
                    top: 0;
                    right: auto; // IE11
                    right: initial;
                    left: 0;
                    bottom: auto; // IE11
                    bottom: initial;
                    text-align: center;

                    &[class*="width-"] {
                        max-width: 100%;
                    }
                }

                &[class*="-bottom-left"] {
                    top: auto; // IE11
                    top: initial;
                    left: 0;
                    right: auto; // IE11
                    right: initial;
                    bottom: 35px;
                    text-align: left;

                    &[class*="width-"] {
                        max-width: 100%;
                    }
                }
            }

            &.mobile-center {
                text-align: center;
            }
        }
    }

    &.banner {
        border-top: $border-thick2;
        margin-top: rem(30px);

        @include media-breakpoint-up(md) {
            margin-top: rem(50px);
        }

        .hero-content {
            max-width: 100%;

            @include media-breakpoint-up(mobile-menu) {
                margin: 60px 120px;

                // Take the margin out of the dimensions
                width: calc(100% - 240px);
                height: calc(100% - 120px);
            }
        }

        .type-hero {
            @include type-hero-banner-heading;

            color: $hero-banner-text;
        }

        h2 {
            @include type-hero-banner-sub-heading;

            margin: rem(15px) 0;
            color: $hero-banner-text;
        }

        &.alternate {
            border-top: 0;

            picture {
                img {
                    min-height: 240px;
                }
            }
        }
    }

    &.side-by-side {
        border-top: 0;

        .hero-content {
            display: flex;
            flex-direction: column;
            box-sizing: border-box;

            .video {
                order: 1;
                margin-bottom: rem(30px);
                display: inline-flex;
                height: 100%;

                img {
                    height: auto;
                    width: 100%;
                    max-width: 100%;
                }
            }

            .call-text {
                order: 2;
            }

            .image-capper {
                display: none;
            }

            @include media-breakpoint-up(md) {
                flex-direction: row;

                .video,
                .call-text {
                    width: 50%;
                }

                .video {
                    order: 2;
                    margin-bottom: 0;
                    align-self: center;

                    &.first {
                        order: 1;
                    }
                }

                .call-text {
                    box-sizing: border-box;
                    padding-right: 6%;
                    align-self: center;
                    order: 1;

                    &.second {
                        order: 2;
                        padding-right: 0;
                        padding-left: 6%;
                    }
                }

                .image-capper {
                    display: block;
                    margin-bottom: rem(10px);

                    img {
                        width: auto; // IE11
                        width: initial;
                    }
                }
            }

            @include media-breakpoint-down(lg) {
                position: absolute;
                padding: 0;
                margin: rem(20px) rem(20px) rem(30px) rem(20px);
                top: 0;
                left: 0;

                // Take the margin out of the dimensions
                width: calc(100% - 40px);
                height: calc(100% - 50px);

                .call-text {
                    .type-hero {
                        @include font-size($hero-heading-sm);
                    }
                }
            }

            @include media-breakpoint-down(md) {
                justify-content: center;
            }
        }

        @include media-breakpoint-down(lg) {
            picture {
                >img {
                    height: fit-content;
                }
            }
        }
    }

    &.third-split {
        border-top: 0;

        .hero-content {
            display: flex;
            flex-direction: column;

            img {
                width: initial;
                height: initial;
            }

            .flex {
                flex-direction: column;
            }

            .split-item {
                .container {
                    text-align: left;

                    &:first-child {
                        margin-bottom: rem(20px);
                    }
                }
            }

            @include media-breakpoint-up(lg) {
                flex-direction: row;

                .flex {
                    flex-direction: row;
                }

                .split-item {
                    align-items: center;
                    align-self: center;
                    box-sizing: border-box;
                    text-align: left;

                    &:first-child {
                        width: calc(100% * (2 / 3));
                    }

                    &:last-child {
                        width: calc(100% * (1 / 3));
                    }

                    .container {
                        text-align: left;

                        &:first-child {
                            margin-right: rem(20px);
                            margin-bottom: 0;
                        }

                        &:nth-child(2) {
                            margin-left: rem(20px);
                        }
                    }

                    &.first {
                        order: 1;
                        padding-right: 5%;
                    }

                    &.second {
                        order: 2;
                        padding-left: 5%;
                    }
                }
            }

            @include media-breakpoint-down(lg) {
                position: absolute;
                padding: 0;
                margin: rem(20px) rem(20px) rem(30px) rem(20px);
                top: 0;
                left: 0;

                // Take the margin out of the dimensions
                width: calc(100% - 40px);
                height: calc(100% - 50px);
                text-align: left;

                .call-text {
                    .type-hero {
                        @include font-size($hero-heading-sm);
                    }
                }

                .center {
                    // style override option
                    text-align: center !important;
                }
            }

            @include media-breakpoint-down(md) {
                justify-content: center;
            }
        }

        @include media-breakpoint-down(lg) {
            img {
                max-height: fit-content;
            }
        }

        &.alternate {
            .hero-content {
                .split-item {
                    align-items: center;
                }

                @include media-breakpoint-up(mobile-menu) {
                    margin: 60px 100px;

                    // Take the margin out of the dimensions
                    width: calc(100% - 200px);
                    height: calc(100% - 120px);
                }

                @include media-breakpoint-up(lg) {
                    .split-item {
                        h3 {
                            margin-top: 0;
                        }

                        &:first-child {
                            padding-right: 2%;
                        }

                        &:last-child {
                            padding-left: 2%;
                            text-align: left;
                        }

                        .container {
                            &:first-child {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    &.secondary-hero {
        margin-top: rem(30px);

        @include media-breakpoint-up(md) {
            margin-top: rem(60px);
        }
    }
}

.content-tiles {
    width: 100%;
    max-width: $max-content-breakpoint;
    margin: auto;
    margin-top: rem(40px);

    @include media-breakpoint-up(md) {
        margin-top: rem(60px);
    }

    .content-tiles-heading {
        text-align: center;
        margin: 0 0 rem(30px) 0;

        h2 {
            margin: 0;
            line-height: 1em;
        }

        p {
            padding: rem(10px) rem(30px) 0 rem(30px);
            text-align: center;

            @include media-breakpoint-up(md) {
                width: 60%;
                margin: 0 auto;
                padding: rem(10px) 0 0 0;
            }
        }

        a {
            margin-top: rem(20px);
        }

        .type-hero,
        h4,
        h3,
        h2,
        h1,
        p {
            color: $content-tile-inner-header;

            &.inverse {
                color: $content-tile-inner-header-inverse;
            }

            &.primary {
                color: $content-tile-inner-header-primary;
            }
        }

        @include media-breakpoint-down(md) {
            &:not(.mobile-primary) {
                .type-hero,
                h4,
                h3,
                h2,
                h1,
                p {
                    color: $content-tile-inner-header;
                }
            }
        }
    }

    .content-tiles-wrapper {
        display: flex;
        flex-wrap: wrap;
    }

    .content-tile-inner-wrapper {
        position: absolute;
        margin: 20px;

        // Take the margin out of the dimensions
        width: calc(100% - 40px);
        height: calc(100% - 40px);
        top: 0;
        left: 0;

        @include media-breakpoint-up(md) {
            margin: 30px;

            // Take the margin out of the dimensions
            width: calc(100% - 60px);
            height: calc(100% - 120px);
        }

        @include media-breakpoint-up(lg) {
            margin: 60px 30px;
        }
    }

    .content-tile-container {
        position: relative;
        box-sizing: border-box;
        margin-top: 5%;
        display: inline-block;

        &:first-child {
            margin-top: 0;
        }

        @include media-breakpoint-up(md) {
            margin-right: 1%;
            margin-left: 1%;
            margin-top: 2%;

            &:first-child {
                margin-left: 0;
            }
        }
    }

    img {
        width: 100%;
        height: auto;
    }

    .cta-container {
        display: flex;
        flex-direction: column;

        .button {
            & + .button {
                margin-top: 4%;

                @include media-breakpoint-up(md) {
                    margin-left: 4%;
                    margin-top: 0;
                }
            }
        }

        @include media-breakpoint-up(md) {
            flex-direction: row;
        }
    }

    &.two-up,
    &.three-up {
        .content-tile-container {
            width: 100%;
        }
    }

    &.two-up {
        .content-tile-container {
            @include media-breakpoint-up(md) {
                width: calc(100% * (1 / 2) - 1%);

                &:nth-child(2n) {
                    margin-right: 0;
                }

                &:nth-child(-n+2) {
                    margin-top: 0;
                }
            }
        }

        &.alternate {
            .content-tile-inner-wrapper {
                margin: 40px 25px;
                height: calc(100% - 80px);
                width: calc(100% - 50px);
            }
        }
    }

    &.three-up {
        .content-tile-container {
            @include media-breakpoint-up(md) {
                width: calc(100% * (1 / 3) - 1.35%);

                &:nth-child(3n) {
                    margin-right: 0;
                }

                &:nth-child(-n+3) {
                    margin-top: 0;
                }
            }
        }

        &.long-treatment {
            .content-tile-container {
                margin-bottom: rem(30px);
            }

            .content-tile-inner {
                padding: 0 rem(30px);
                box-sizing: border-box;
            }

            @include media-breakpoint-up(md) {
                .content-tile-container {
                    margin-bottom: 0;
                }

                .content-tile-inner {
                    padding: 0;
                }
            }
        }
    }

    &.inner-content {
        .content-tile-inner-wrapper {
            h2 {
                margin-top: 0;
                color: $content-tile-inner-header;

                @include type-asset-tile-heading-inner;

                &.inverse {
                    color: $content-tile-inner-header-inverse;
                }

                &.primary {
                    color: $content-tile-inner-header-primary;
                }
            }
        }
    }

    &.outer-content {
        text-align: center;

        .content-tile-container {
            margin-top: 0;
        }

        h3 {
            margin-bottom: 5%;
            color: $content-tile-outer-header;

            @include type-asset-tile-heading-outer;

            &.link-arrow {
                position: relative;

                &::after {
                    content: "";
                    position: absolute;
                    top: -3px;
                    background-image: url("../images/svg-icons/arrow.svg");
                    background-size: cover;
                    height: 30px;
                    width: 30px;
                    transform: rotate(180deg);
                }
            }
        }

        @include media-breakpoint-up(md) {
            h3 {
                margin-bottom: 0;

                &.link-arrow {
                    top: -3px;
                }
            }
        }
    }

    &.no-buttons {
        margin-bottom: rem(60px);
    }
}

.top-categories-slot {
    width: 100%;
    max-width: $max-content-breakpoint;
    margin: 0 auto;
    text-align: center;
    padding-top: rem(30px);
    box-sizing: border-box;

    .slot-header {
        padding-left: rem(20px);
        padding-right: rem(20px);
    }

    .top-categories-wrapper {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: rem(40px);
        padding: 0 2%;
    }

    .category-tile {
        margin-right: 5%;
        margin-left: 5%;
        margin-top: 4%;
        width: calc(100% * (1 / 2) - 10%);
        display: inline-block;

        &:nth-child(-n+2) {
            margin-top: 0;
        }

        img {
            max-width: 100%;
        }

        @include media-breakpoint-down(md) {
            img {
                width: 100%;
                height: auto;
            }
        }

        @include media-breakpoint-up(md) {
            margin-right: 2%;
            margin-left: 2%;
            margin-top: 4%;
            width: calc(100% * (1 / 4) - 4%);

            &:nth-child(-n+4) {
                margin-top: 0;
            }
        }
    }

    @include media-breakpoint-up(mobile-menu) {
        padding-top: rem(70px);
        padding-left: 0;
        padding-right: 0;

        .slot-header {
            padding-left: 0;
            padding-right: 0;
        }

        .top-categories-wrapper {
            padding: 0;
        }
    }
}

.promo-banner {
    width: 100%;

    .promo-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        color: $promo-banner-text-color;

        .promo-banner-background {
            content: "";
            height: 75%;
            width: 100%;
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: 0;
            background-color: $promo-banner-bg-color;
        }
    }

    .item1 {
        height: rem(90px);
        width: rem(90px);
        border-radius: 4em;
        border: 2px solid $promo-banner-border-color;
        background-color: $promo-banner-bg-color;
        position: relative;

        .callout-circle,
        .callout-circle-inner {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        .callout-circle {
            z-index: 1;
            height: rem(83px);
            width: rem(83px);
            border-radius: 4em;
            border: 1px solid $promo-banner-border-color;

            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                width: 0;
                height: 0;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-top: 8px solid $promo-banner-border-color;
            }
        }

        .callout-circle-inner {
            z-index: 2;
            text-align: center;
            width: 90%;
            height: 90%;
            box-sizing: border-box;
            padding-top: 8px;

            span {
                color: $promo-banner-text-color;
                display: block;
                vertical-align: middle; // safari fix
                line-height: 1em;

                &:nth-child(1) {
                    text-transform: uppercase;

                    @include font-bold(13px);
                }

                &:nth-child(2) {
                    @include font-bold(30px);
                }

                &:nth-child(3) {
                    @include font-bold(13px);
                }
            }
        }
    }

    .item2 {
        padding: rem(15px) 0 rem(5px) 0;
    }

    .item3 {
        padding: rem(5px) 0 rem(15px) 0;
    }

    .item1,
    .item2,
    .item3 {
        z-index: 1;
    }

    .item2,
    .item3 {
        @include type-h2;
    }

    @include media-breakpoint-up(mobile-menu) {
        .promo-container {
            flex-direction: row;

            .promo-banner-background {
                content: "";
                height: 60%;
                width: 100%;
                bottom: 20%; // IE11
            }
        }

        .item1 {
            height: rem(120px);
            width: rem(120px);
            border-radius: 6em;
            order: 2;
            margin: 0 rem(25px);

            .callout-circle {
                height: rem(110px);
                width: rem(110px);
                border-radius: 6em;

                &::after {
                    border-left: 8px solid transparent;
                    border-right: 8px solid transparent;
                    border-top: 10px solid $promo-banner-border-color;
                }
            }

            .callout-circle-inner {
                span {
                    &:nth-child(1) {
                        @include font-bold(18px);
                    }

                    &:nth-child(2) {
                        @include font-bold(40px);
                    }

                    &:nth-child(3) {
                        @include font-bold(18px);
                    }
                }
            }
        }

        .item2 {
            order: 1;
            padding: 0;
        }

        .item3 {
            order: 3;
            padding: 0;
        }

        .item2,
        .item3 {
            @include type-h1;
            @include font-size(36px);
        }
    }
}

.horizontalcarousel-with-banner {
    margin-top: rem(40px);

    @include media-breakpoint-up(md) {
        margin-top: rem(60px);
    }

    .carousel-banner {
        margin-bottom: rem(30px);
    }

    .carousel-wrapper {
        width: 100%;
        max-width: $max-content-breakpoint;
        margin: 0 auto;
        box-sizing: border-box;
        padding: 0 rem(20px);

        @include media-breakpoint-up(md) {
            padding: 0;
        }
    }
}

.mobile-category-buttons {
    text-align: center;
    box-sizing: border-box;
    padding: 0 rem(20px);
    margin-top: rem(30px);

    .button {
        width: 100%;
        margin-bottom: rem(15px);
        border-color: $gray-border-dark;
    }

    h2 {
        margin-top: 0;
    }

    @include media-breakpoint-up(mobile-menu) {
        display: none;
    }
}

.home-bottom-first {
    .recommendations-container {
        position: relative;
        margin-left: 8%;
        margin-right: 8%;

        @include media-breakpoint-down(sm) {
            margin-left: 0.5%;
            margin-right: 1%;
        }
    }

    .recommendations {
        padding: 1.25rem 0 0.9375rem;
        position: relative;
        width: calc(100% + 0.125rem);

        @include media-breakpoint-up(md) {
            padding: 1.875rem 0 1.5625rem;
        }

        .slick-prev {
            left: -1.9375rem;

            @include media-breakpoint-down(sm) {
                left: 0.19rem;
            }
        }

        .slick-next {
            right: -2.5rem;

            @include media-breakpoint-down(sm) {
                right: 0.25rem;
            }
        }

        .grid-tile {
            background: #fff;
            box-shadow: 0 0 9px 0 #c0c0c0;
            width: 18.75rem;
            height: auto;
            padding: 0;
            margin: 0.3125rem 0.4375rem;

            .product-tile {
                @include media-breakpoint-down(sm) {
                    margin-left: 8%;
                }
            }

            .slick-slide {
                .slick-active {
                    width: 303px;

                    @include media-breakpoint-down(sm) {
                        width: 360px;
                    }
                }
            }
        }
    }
}

.home-bottom-second {
    background: linear-gradient(to bottom, rgb(243 243 243) 25%, rgb(255 255 255) 58%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    background-position-y: 60px;

    @include media-breakpoint-up(md) {
        background-position-y: 50px;
    }
}

.horizontal-carousel {
    .slick-track {
        display: flex;

        .slick-slide {
            display: flex;
            height: auto;

            img {
                width: 100%;
            }
        }
    }
}

#pr-write {
    margin-left: auto;
    margin-right: auto;
    max-width: 1280px;
}
