#wrapper {
    position: relative;
    left: 0;
    transition: left 0.2s ease-in;
    width: 100%;
    border: 0;
    margin: auto;
    background-color: $base-bg;

    &.menu-active {
        left: 90%;

        @include media-breakpoint-up(md) {
            left: 320px;
        }

        @include media-breakpoint-up(mobile-menu) {
            left: auto;
        }
    }
}

#main {
    @include content-width;

    padding: rem(25px) 0;

    @include media-breakpoint-up(mobile-menu) {
        // all but account section
        padding: rem(25px) rem(20px);
    }
}

.primary-content {
    width: 100%;

    &:focus {
        outline: none;
    }

    @include media-breakpoint-up(md) {
        position: relative;
        float: right;
        box-sizing: border-box;
        width: calc(100% - #{$left-nav-width});
        padding: 0 0 20px;

        .primary-focus & {
            float: left;
            width: 76%;
            border: none;
            margin: 0;
        }

        .full-width & {
            width: 100%;
            border: none;
            margin-left: 0;
        }

        .page-content & {
            padding-left: 3%;
        }
    }
}

#secondary {
    float: none;
    width: 100%;
    border: none;

    @include media-breakpoint-up(md) {
        box-sizing: border-box;
        position: relative;
        float: left;
        width: $left-nav-width;

        // account and customer service desktop navs
        nav {
            padding-right: rem(20px);

            a {
                display: block;
            }
        }

        .primary-focus & {
            float: left;
            box-sizing: border-box;
            width: 24%;
            padding-left: 0.4rem;
            border: none;
            background: none;
        }
    }
}

.secondary-content {
    clear: both;
}
