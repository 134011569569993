@mixin gradient($start-color, $end-color, $orientation) {
    background: $start-color;

    @if $orientation == vertical {
        // vertical
        background: linear-gradient(to bottom, $start-color 0%, $end-color 100%);
    } @else if $orientation == horizontal {
        // horizontal
        background: linear-gradient(to right, $start-color 0%, $end-color 100%);
    } @else if $orientation == circle {
        // circle
        background: radial-gradient(circle at center, $start-color 0%, $end-color 100%);
    } @else {
        // radial
        background: radial-gradient(ellipse at center, $start-color 0%, $end-color 100%);
    }
}
