@use "sass:string";

.pt_product-details {
    #main {
        padding-top: rem(20px);
        width: calc(100% - #{rem(20px)});

        @include media-breakpoint-up(md) {
            width: calc(100% - #{rem(40px)});
        }
    }

    .primary-content {
        @include media-breakpoint-down(md) {
            position: relative;
            padding-bottom: 68px;
        }
    }

    .breadcrumb {
        display: none;

        @include media-breakpoint-up(md) {
            display: block;
            padding: 0;
            max-width: $max-content-breakpoint - 60px;
            margin: 0 auto rem(20px);
        }
    }
}

#product-nav-container {
    overflow: hidden;
    position: absolute;
    right: 0;
    width: 160px;

    div {
        float: left;
        width: 77px;
        text-align: center;
    }

    img {
        max-width: 80%;
        max-height: 100%;
    }

    span {
        display: block;
        height: 65px;
    }

    .divided {
        span {
            padding-right: 5px;
            border-right: $border;
        }
    }

    @include media-breakpoint-down(md) {
        bottom: 0;
        width: 100%;

        div {
            width: 60px;

            &.product-next {
                float: right;
            }
        }

        .divided {
            span {
                border: 0;
            }
        }
    }
}

@media screen and (min-width: 1025px) {
    #QuickViewDialog {
        .product-badge {
            top: 10px;
            left: 10px;
        }

        .product-primary-image {
            max-width: 100%;
            float: none;
        }
    }
}

.product-primary-image {
    text-align: center;

    &.slick-slider {
        margin-bottom: 0;

        @include media-breakpoint-up(lg) {
            max-width: 87%;
            float: left;

            + div.product-badge {
                top: 10px;
                left: 14%;
            }
        }

        .slick-track {
            display: flex;

            .slick-slide {
                height: initial;

                a {
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                }
            }
        }

        &:not(.slick-initialized) {
            display: none;
        }

        .slick-arrow {
            width: 30px;
            height: 30px;
            display: none;

            &::before {
                content: none;
            }

            &.slick-prev {
                background: url("../images/svg-icons/arrow-block-left.svg") no-repeat center;
                left: rem(20px);
            }

            &.slick-next {
                background: url("../images/svg-icons/arrow-block-right.svg") no-repeat center;
                right: rem(20px);
            }

            &.slick-disabled {
                opacity: 0.3;
            }
        }

        .slick-dots {
            position: static;
            margin-top: rem(10px);

            @include line-height(10px);

            @include media-breakpoint-up(lg) {
                height: 0;
                margin: 0;
                overflow: hidden;
            }

            li {
                margin: 0 rem(5px);
                width: rem(10px);
                height: rem(10px);
                vertical-align: top;

                button {
                    min-height: auto;
                    width: rem(10px);
                    height: rem(10px);
                    padding: 0;

                    &::before {
                        width: rem(10px);
                        height: rem(10px);
                        left: 0;
                        top: 0;
                    }
                }
            }
        }

        &:hover {
            .slick-arrow {
                display: block;
            }
        }
    }
}

.main-image {
    display: block;
}

.primary-image {
    max-width: 100%;
    margin: 0 auto;
}

.product-thumbnails {
    display: none;

    @media screen and (min-width: 1025px) {
        display: block;
        float: left;
        width: 12%;
        margin-right: 1%;
        max-width: rem(450px);

        .slick-list {
            padding: 0 !important;
        }

        .slick-prev {
            top: -5px;
            right: 0;
            left: 0;
            margin: 0 auto;

            &::before {
                transform: rotate(-45deg);
            }
        }

        .slick-next {
            top: auto;
            bottom: -35px;
            right: 0;
            left: 0;
            margin: 0 auto;

            &::before {
                transform: rotate(135deg);
            }
        }
    }

    .product-thumbnails-list {
        @include clearfix;

        @include media-breakpoint-down(md) {
            margin: rem(10px) 0 0 rem(-10px);

            &.slick-slider {
                .slick-track {
                    display: flex;
                }

                .slick-slide {
                    height: auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                &:not(.slick-initialized) {
                    display: none;
                }

                &.with-nav {
                    margin: rem(10px) 0 0;
                    padding-left: rem(35px);

                    .thumb {
                        margin: rem(10px) rem(5px);
                    }
                }

                .slick-arrow {
                    width: 30px;
                    height: 30px;
                    display: none;

                    &::before {
                        content: none;
                    }

                    &.slick-prev {
                        background: url("../images/svg-icons/arrow-block-left.svg") no-repeat center;
                        left: 0;
                    }

                    &.slick-next {
                        background: url("../images/svg-icons/arrow-block-right.svg") no-repeat center;
                        right: rem(-35px);
                    }

                    &.slick-disabled {
                        opacity: 0.3;
                    }
                }
            }
        }
    }

    // thumbnail
    .thumb {
        width: rem(95px);
        padding-left: 0;
        margin: rem(10px);

        &.slick-current {
            box-shadow: $shadow-tile;

            a {
                border: 1px solid $white;
            }
        }

        a {
            display: block;
        }
    }

    // thumbnail image
    img {
        box-sizing: border-box;
        max-width: 100%;
        height: auto;
        cursor: pointer;
        vertical-align: top;
    }

    h2 {
        margin: 1rem 0 0.3rem;
    }
}

.wistia_thumb {
    display: none;

    &.wistia_embed_initialized.active {
        display: block;
        margin-top: rem(10px);
        text-align: center;
    }

    &.pdp-wistia_thumb {
        @include media-breakpoint-up(lg) {
            float: left;
            box-sizing: border-box;
            width: 100%;
            max-width: rem(180px);
            padding: 0;
            box-shadow: none;
        }

        .wistia_click_to_play {
            img {
                @include media-breakpoint-up(lg) {
                    display: block;
                    margin: 0 auto;
                }
            }

            .play-video {
                @include media-breakpoint-up(lg) {
                    font-size: 0;
                    display: block;
                    position: absolute;
                    width: rem(40px);
                    height: rem(40px);
                    padding: 0;
                    top: calc(50% - #{rem(32px)});
                    left: calc(50% - #{rem(20px)});
                }
            }

            .video-label {
                @include media-breakpoint-up(lg) {
                    display: block;
                    text-align: center;
                    white-space: nowrap;

                    @include type-product-attribute-label;

                    margin-top: rem(7px);
                    font-size: rem(12px);
                }
            }
        }
    }

    .wistia_click_to_play {
        cursor: pointer;
        position: relative;
        height: auto !important;
        width: 100% !important;
        box-sizing: border-box;

        @include media-breakpoint-down(md) {
            @include button;
            @include tertiary-button;

            max-width: rem(300px);
            padding-left: 0;
            padding-right: 0;
        }

        img {
            display: none;
            max-width: 100%;
        }

        .play-video {
            background: url("../images/svg-icons/play-button.svg") no-repeat left center;
            background-size: contain;
            padding-left: rem(25px);
        }

        .video-label {
            display: none;
        }
    }
}

.rating {
    color: $rating-color;

    .product-info & {
        @include type-product-info-rating;
    }
}

.product-variations,
.product-options {
    .attribute {
        position: relative;
        clear: both;
        padding: 0;
        margin: rem(20px) 0 rem(10px);

        @include media-breakpoint-up(md) {
            margin: rem(30px) 0 rem(20px);

            // Custom Pick n Choose styling
            &.pnc-inverse {
                margin-bottom: 0;

                .label {
                    margin-bottom: rem(20px);
                }

                .swatches li.inverse {
                    margin-bottom: rem(4px);
                }
            }
        }

        h3,
        .label {
            @include type-product-attribute-label;

            margin-bottom: rem(3px);

            * {
                @include font-reg;
            }

            .size-chart-link {
                float: right;
            }
        }

        .value {
            width: 100%;
        }

        ul {
            float: right;
            width: 100%;
        }

        .size-chart-link {
            float: none;
            clear: both;
        }
    }

    .swatches {
        li {
            float: left;
            padding: 0;
            margin: 0 rem(10px) rem(10px) 0;

            &.selected {
                a {
                    border: $swatch-selected-border;
                }

                a::after {
                    content: "";
                    display: block;
                    box-sizing: border-box;
                    top: 0;
                    left: 0;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    border: $swatch-selected-border;
                }
            }

            // PNC and Misses-Plus option products
            &.selected.inverse {
                background: $swatch-bg-inverse;

                a {
                    color: $swatch-text-color-inverse;
                }
            }

            &.unselectable {
                opacity: 0.3;
                cursor: not-allowed;

                a.swatchanchor {
                    cursor: not-allowed;
                }

                a::after {
                    content: "";
                    display: block;
                    box-sizing: border-box;
                    background: $swatch-border-color;
                    position: absolute;
                    transform: rotate(135deg);
                    width: 140%;
                    height: rem(2px);
                    left: -20%;
                    top: calc(50% - #{rem(1px)});
                }
            }
        }

        a {
            display: block;
            position: relative;
            box-sizing: border-box;
            min-width: rem(40px);
            min-height: rem(40px);
            padding: rem(5px);
            border: $swatch-border;
            text-align: center;
            white-space: nowrap;
            color: $swatch-text-color;
            overflow: hidden;

            @include line-height(28px);
            @include type-label;
        }
    }

    .color {
        a {
            width: rem(40px);
            height: rem(40px);
            padding: 0;
            line-height: 0;

            img {
                width: 100%;
                height: 100%;
            }
        }

        li {
            &.selected {
                a {
                    border-color: $swatch-border-color;
                }

                a::after {
                    border-color: $swatch-border-color;
                    border-width: rem(2px);
                    top: rem(1px);
                    left: rem(1px);
                    width: calc(100% - #{rem(2px)});
                    height: calc(100% - #{rem(2px)});
                }
            }

            &.selected:not(.swatch-white, .swatch-clear) {
                a::after {
                    border-color: $white;
                }
            }

            &.unselectable:not(.swatch-white, .swatch-clear) {
                a::after {
                    background: $white;
                }
            }

            &:not(.swatch-clear) {
                a {
                    border: solid 1px black;
                }
            }
        }
    }

    .colpackcount {
        display: flex;

        li {
            width: 100%;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

.product-review {
    @include clearfix;

    margin-bottom: 8px;
}

.product-main-attributes {
    .attribute {
        margin-bottom: rem(10px);

        @include media-breakpoint-up(md) {
            margin-bottom: rem(20px);
        }
    }

    .label {
        @include type-label;

        display: block;

        @include media-breakpoint-up(md) {
            display: inline-block;
            min-width: rem(120px);
        }
    }
}

.product-actions {
    .socialsharing {
        float: left;
        display: flex;
        justify-content: space-between;
        width: rem(180px);

        &.set-socialsharing {
            width: rem(280px);
            margin-bottom: rem(30px);

            @include media-breakpoint-down(sm) {
                margin: 0 auto rem(30px);
                float: none;
            }
        }
    }

    .product-actions-links {
        float: right;
        text-align: right;
        max-width: calc(100% - #{rem(180px)});

        @include line-height(20px);

        a {
            @include type-lone-link;

            white-space: nowrap;
            display: inline-block;

            & + a {
                margin-left: rem(10px);
                float: right;
            }
        }
    }

    .full-details {
        @include line-height(20px);

        display: inline-block;
    }
}

.pdp-callout-message {
    .content-asset {
        background: $toy-warning-message-bg;
        box-sizing: border-box;
        padding: rem(10px) rem(10px) rem(10px) rem(65px);
        min-height: rem(60px);
        margin-top: rem(20px);
        position: relative;

        @include media-breakpoint-up(md) {
            margin-top: rem(40px);
            padding-left: rem(65px);
        }

        &::before {
            content: "";
            display: block;
            position: absolute;
            background: url("../images/svg-icons/warning-icon.svg") no-repeat center;
            background-size: contain;
            left: rem(10px);
            top: rem(10px);
            width: rem(46px);
            height: rem(41px);
        }

        * {
            margin: 0;

            @include line-height(22px);
        }
    }
}

.pdp-main {
    @include clearfix;
    @include type-pdp-main;

    max-width: $max-content-breakpoint - 40px;
    margin: 0 auto;

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    label {
        @include type-pdp-label;

        width: auto;
        padding: 0;
        text-align: left;
    }

    .product-col-2 {
        position: relative;
        margin-bottom: rem(20px);

        @include media-breakpoint-up(md) {
            float: left;
            width: 55.738%;
        }

        .product-badge {
            position: absolute;
            top: 10px;
            left: 10px;
        }
    }

    .product-col-1,
    .product-col-3 {
        @include media-breakpoint-up(md) {
            float: right;
            width: 39.345%;
            clear: right;
        }
    }

    .product-col-3 {
        &.product-set {
            @include media-breakpoint-up(md) {
                width: 100%;
                float: none;
                clear: both;
            }
        }
    }

    .brought-by-messaging {
        padding-top: 10px;
    }

    .early-bird {
        display: inline-block;
        margin-bottom: rem(11px);

        @include type-product-early-bird;

        span {
            @include font-reg;
        }

        &.after-price-label,
        &.pdp-callout-badge {
            background: $inverted-text-color;
            font-size: rem(12px);
            color: transparent;
        }

        .psp-early-bird {
            color: #fff !important;
        }

        @include media-breakpoint-down(sm) {
            height: auto;
            display: block;
            padding: 0;
        }
    }

    .msrp-value,
    .compare-price {
        margin-top: 1rem;
        font-family: roboto-regular, arial, sans-serif;
        font-size: 1rem;
    }

    .product-name {
        @include type-h4;

        font-weight: 700;
        margin: 0 0 rem(10px);
    }

    .product-number {
        @include type-product-number;

        color: $product-number-color;
        margin-bottom: rem(10px);
        min-height: rem(18px);

        &.hidden {
            font-size: 0;
        }
    }

    #BVRRSummaryContainer {
        margin-bottom: rem(10px);

        @include media-breakpoint-up(md) {
            margin-bottom: rem(20px);
        }
    }

    .product-price {
        @include type-product-price;

        margin-bottom: rem(10px);

        .price-standard {
            @include font-reg;

            text-decoration: line-through;

            & + .price-sales {
                color: $price-sale-color;
            }
        }

        &.sale-set {
            .product-current-price {
                color: $price-sale-color;
            }
        }

        .price-standard-range {
            text-decoration: line-through;

            @include font-reg;
        }

        & + .monthly-product-price {
            margin: rem(-6px) 0 rem(20px);
        }

        & + .installments-product-callout {
            margin: rem(-6px) 0 rem(20px);
        }

        .price-sales-range {
            color: $price-sale-color;

            &.list-price {
                color: #000;
            }
        }

        .selected-price-sales {
            color: $price-sale-color;

            &.list-price {
                color: #000;
            }
        }

        .percentage-discount {
            color: #d71921;
            margin: 0.25rem 0 0;
            font-size: 1.25rem;
        }
    }

    .monthly-product-price,
    .installments-product-callout {
        @include monthly-product-price;

        margin-bottom: rem(10px);

        a {
            display: inline-block;
            vertical-align: top;

            .icon {
                vertical-align: top;
                top: rem(1px);
            }
        }
    }

    .additional-delivery-charge {
        margin: rem(20px) 0 rem(30px);
    }

    .product-desc {
        line-height: 1.47;
        margin-bottom: rem(10px);

        @include media-breakpoint-up(md) {
            margin: rem(19px) 0 rem(17px);
        }
    }

    .pnc {
        .pnc-products {
            padding: rem(10px) rem(20px);
            background: $subtle-contrast-bg;
        }

        .pnc-product {
            padding: rem(10px) 0;
            border-bottom: $border2;

            &.last {
                border-bottom: none;
                padding-bottom: 0;
            }

            .pnc-product-info {
                float: left;
                width: calc(100% - #{rem(140px)});

                .pnc-name {
                    @include type-h4;
                }

                .pnc-id {
                    @include type-product-number;

                    color: $product-number-color;
                }
            }

            .quantity-module {
                float: right;
            }

            // out of stock pnc items remain visible
            &.disabled {
                .pnc-name {
                    color: $btn3-disabled-color;
                }
            }
        }

        .pnc-total-count {
            @include line-height(40px);

            text-align: right;

            @include type-label;

            border-top: $border2;
        }
    }

    .personalization-title {
        border-top: $border2;
        padding: rem(20px) 0 rem(10px);

        @include media-breakpoint-up(md) {
            padding-top: rem(25px);
        }

        h5 {
            margin: 0 0 rem(3px);
        }

        a {
            float: right;
        }
    }

    .esp-box {
        border-top: $border2;

        @include media-breakpoint-up(md) {
            padding: rem(10px);
        }

        .esp-content {
            background: $product-esp-bg;
            margin: rem(10px) 0;
            padding: rem(10px) 0;

            @include media-breakpoint-up(md) {
                padding: rem(10px);
                margin: rem(10px);
            }

            h5 {
                margin-top: rem(10px);
                margin-bottom: rem(5px);
            }

            a.openModal {
                margin-bottom: rem(10px);
                text-decoration: underline;
            }

            img {
                float: left;
                width: 50px;
            }

            .esp-option {
                margin-bottom: rem(20px);
                font-weight: bold;
                margin-left: rem(15px);
            }
        }
    }

    .personalization-options,
    .ps-personalization-options {
        background: $product-personalization-bg;

        .personalization-option {
            margin: 0;
            padding: rem(10px);
            border-bottom: 2px solid $white;

            @include media-breakpoint-up(md) {
                padding: rem(14px) rem(20px);
            }

            &.visually-hidden {
                padding: 0;
            }

            label {
                width: 100%;
                float: none;

                .optional {
                    @include font-reg;
                }
            }

            .field-wrapper {
                width: 100%;
            }

            .personalization-input {
                &.uppercase {
                    text-transform: uppercase;
                }

                &.lowercase {
                    text-transform: lowercase;
                }

                &.capitalize {
                    text-transform: capitalize;
                }
            }
        }

        .show-more {
            display: block;

            @include type-h6;

            position: relative;
            padding: rem(10px);

            &::after {
                content: "";
                display: block;
                position: absolute;
                right: rem(10px);
                top: rem(10px);
                width: rem(15px);
                height: rem(15px);
                background: url("../images/svg-icons/increase.svg") no-repeat center;
            }
        }

        & ~ .product-add-to-cart {
            border-top: 0 none;
        }
    }

    .personalized-required {
        display: block;
        margin-bottom: rem(20px);

        span {
            margin-top: rem(20px);
        }
    }

    .optional-personalization {
        display: none;
    }

    .product-add-to-cart {
        overflow: hidden;
        padding: rem(20px) 0 rem(30px);
        border-bottom: $border2;
        border-top: $border2;
        margin: 0 0 rem(30px);
        max-width: 100%;
        justify-content: space-between;
        display: flex;
        flex-wrap: wrap;

        @include media-breakpoint-up(md) {
            padding: rem(30px) 0;
            margin-bottom: rem(20px);
        }

        .inventory {
            float: left;
            white-space: nowrap;
            align-self: flex-end;

            .label {
                margin-bottom: rem(5px);

                @include type-label;
                @include line-height(20px);
            }
        }

        .leadTimeMsg,
        .AddToCartAlertMessage {
            color: #d41836;
            width: 100%;
            text-align: center;

            @include media-breakpoint-up(xl) {
                text-align: left;
            }

            margin-top: 10px;

            a {
                color: #d41836;
                text-decoration: underline;
                text-underline-position: under;
            }
        }

        .leadTimeMsg {
            @include media-breakpoint-up(xl) {
                margin-left: 160px;
            }
        }

        .add-to-cart-wrap {
            float: right;
            align-self: flex-end;
            width: calc(100% - #{rem(140px)});

            @include media-breakpoint-up(xl) {
                width: calc(100% - #{rem(160px)});
            }

            .availability-web {
                padding: 0;
                margin-bottom: rem(5px);

                @include line-height(20px);
            }
        }

        button {
            width: 100%;
            padding: 0;
        }
    }

    button#add-to-cart {
        &.add-to-cart-disabled {
            cursor: not-allowed;
        }
    }

    .recommendations-container {
        position: relative;

        & + .tab {
            margin-top: rem(30px);

            @include media-breakpoint-up(md) {
                border-top: 0 none;
            }
        }

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 50%;
            width: 100vw;
            height: 100%;
            transform: translateX(-50%);
            background: $recommendations-bg;
        }
    }

    .recommendations {
        padding: rem(20px) 0 rem(15px);
        position: relative;
        margin-left: rem(-7px);
        width: calc(100% + #{rem(14px)});

        @include media-breakpoint-up(md) {
            padding: rem(30px) 0 rem(25px);
        }

        .slick-prev {
            left: -1.5rem;

            @include media-breakpoint-down(sm) {
                left: 0.75rem;
            }
        }

        .slick-next {
            right: -1.5rem;

            @include media-breakpoint-down(sm) {
                right: 1rem;
            }
        }

        h3 {
            text-align: center;
            margin: 0 0 rem(15px);

            @include media-breakpoint-up(md) {
                margin-bottom: rem(25px);
            }
        }

        .carousel-recommendations {
            position: relative;
            margin: auto;

            &:not(.slick-initialized) {
                display: none;
            }

            .slick-track {
                display: flex;
            }

            .slick-arrow {
                width: 30px;
                height: 30px;
                display: none;

                &::before {
                    content: none;
                }

                &.slick-prev {
                    background: url("../images/svg-icons/arrow-block-left.svg") no-repeat center;
                    left: rem(-10px);

                    @include media-breakpoint-up(lg) {
                        left: rem(-26px);
                    }
                }

                &.slick-next {
                    background: url("../images/svg-icons/arrow-block-right.svg") no-repeat center;
                    right: rem(-10px);

                    @include media-breakpoint-up(lg) {
                        right: rem(-26px);
                    }
                }

                &.slick-disabled {
                    opacity: 0.3;
                }
            }

            .slick-dots {
                position: static;
                margin-top: rem(10px);
                line-height: 1;

                li {
                    margin: 0 rem(5px);
                    width: rem(10px);
                    height: rem(10px);

                    button {
                        min-height: auto;
                        width: rem(10px);
                        height: rem(10px);
                        padding: 0;

                        &::before {
                            width: rem(10px);
                            height: rem(10px);
                            left: 0;
                            top: 0;
                        }
                    }
                }
            }
        }

        .grid-tile {
            background: $white;
            box-shadow: $shadow-tile;
            width: rem(300px);
            height: auto;
            padding-bottom: 75px;
            margin: rem(5px) rem(7px);
        }

        .product-tile {
            padding: rem(20px);

            &:hover {
                .recommendation-tooltip {
                    display: block !important;
                }
            }
        }

        img {
            margin: auto;
        }

        .product-name {
            @include type-recommendation-product-name;
        }

        .product-price {
            @include type-recommendation-product-price;

            margin: 0;
        }

        .recommendation-tooltip {
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: z("pdp-rec-tooltip");
            width: 300px;
            border: $border2;
            text-align: left;
            background: none repeat scroll 0 0 $base-bg;
        }
    }

    .promotion {
        overflow: hidden;
        padding: rem(10px) 0;
        border-top: $border2;

        .promotion-title {
            margin: rem(10px) 0;
        }

        .promotion-callout {
            @include type-promotion-callout;

            margin-bottom: rem(10px);

            .tooltip {
                .icon {
                    margin: 0;
                    top: rem(-3px);
                }
            }
        }
    }

    .product-review-links {
        @include media-breakpoint-down(md) {
            margin-left: 6%;
        }
    }

    .prSnippetTitle,
    .prSnippetRatingUnclickable {
        @include media-breakpoint-down(md) {
            float: left;
        }
    }
}

.product-set {
    .product-add-to-cart {
        clear: both;
        border: 0 none;
        padding: 0;
        margin: 0;
        overflow: visible;

        &.anchor-wrap {
            border-bottom: $border2;
            margin: rem(30px) 0;
            padding-bottom: rem(30px);

            @include media-breakpoint-up(md) {
                padding-bottom: rem(20px);
            }

            .anchor {
                width: 100%;
                padding-left: 0;
                padding-right: 0;
                display: none;
            }
        }

        &.product-add-all-to-cart {
            margin: rem(40px) 0;

            @include media-breakpoint-up(md) {
                margin: rem(30px) 0;

                @include line-height(50px);

                height: rem(50px);
                display: none;
            }

            .add-all-to-cart-message {
                display: none;
                margin-bottom: rem(20px);
                text-align: center;

                @include media-breakpoint-up(md) {
                    padding-right: rem(20px);
                    text-align: right;
                    margin: 0;
                    width: calc(100% - #{rem(320px)});
                }

                .product-price {
                    display: inline-block;

                    @include font-size($body-text-size);

                    margin: 0;
                }
            }

            button {
                display: none;

                @include media-breakpoint-up(md) {
                    max-width: rem(320px);
                }
            }
        }

        label {
            float: left;
            font-size: 0.75rem;
            padding-top: 0.3em;
            padding-right: 0.5em;
        }

        .availability-web {
            label {
                float: none;
                padding: top;
            }
        }
    }
}

.product-set-list {
    border-top: $border2;

    .product-set-item {
        clear: both;
        overflow: hidden;
        padding: rem(40px) 0 rem(30px);
        border-bottom: $border2;

        @include media-breakpoint-up(md) {
            padding: rem(20px) 0 rem(60px);
        }

        .add-to-cart-wrap {
            width: 185px;
            margin-left: 10px;

            @include media-breakpoint-up(xl) {
                width: auto;
                margin-left: 30px;
            }
        }
    }

    .product-set-image {
        text-align: center;
        margin-bottom: rem(20px);

        @include media-breakpoint-up(md) {
            float: left;
            width: 17%;
            max-width: rem(200px);
            min-height: rem(200px);
            margin: 0;
        }

        img {
            max-width: 100%;
            vertical-align: top;
        }

        .wistia_click_to_play {
            @include button;
            @include tertiary-button;

            max-width: rem(300px);
            padding-left: 0;
            padding-right: 0;
            margin-top: rem(10px);
        }
    }

    .product-set-header,
    .product-set-details {
        @include media-breakpoint-up(md) {
            float: left;
            width: 45%;
            max-width: rem(600px);
            padding-left: rem(30px);
            box-sizing: border-box;
        }

        a.item-details {
            text-decoration: underline;
            line-height: 1.5rem;
            font-size: 0.85rem;
        }
    }

    .product-pricing-info,
    .product-add-to-cart-info {
        @include media-breakpoint-up(md) {
            float: right;
            width: 37%;
            max-width: rem(440px);
            clear: right;
        }

        .product-actions-links {
            width: 79%;
            text-decoration: underline;
            line-height: 3rem;
            display: flex;
            justify-content: flex-end;
        }
    }

    .product-name {
        margin: 0 0 rem(20px);

        @include media-breakpoint-up(md) {
            margin-bottom: 0;
        }
    }

    .rating-wrapper {
        margin-bottom: rem(30px);

        @include line-height(20px);

        @include media-breakpoint-up(md) {
            margin-bottom: rem(20px);
        }

        .links {
            display: none;

            a {
                &.first {
                    border-right: $border;
                    padding-right: rem(8px);
                    margin-right: rem(5px);
                }
            }
        }

        & > div {
            float: left;

            & ~ .links {
                display: inline-block;
                margin-left: rem(15px);
            }
        }
    }

    .product-variations {
        .attribute {
            margin: rem(20px) 0;
        }
    }

    .pdp-callout-message {
        .content-asset {
            margin-top: rem(25px);

            @include media-breakpoint-up(md) {
                margin-top: rem(55px);
            }
        }
    }
}

.product-info {
    clear: both;
}

.tabs {
    margin-top: rem(25px);

    @include media-breakpoint-up(md) {
        margin-top: rem(20px);
    }

    .tab {
        border-top: $border2;
        padding-bottom: rem(10px);

        @include media-breakpoint-up(md) {
            padding-bottom: rem(30px);
        }

        .tab-header {
            margin: rem(29px) 0 rem(19px);
            position: relative;
            font-weight: 700;

            @include media-breakpoint-down(sm) {
                margin: rem(19px) 0 rem(9px);
                cursor: pointer;
            }

            &::after {
                content: "";
                display: block;
                position: absolute;
                right: rem(4px);
                top: 0;
                width: rem(18px);
                height: rem(18px);
                background: url("../images/svg-icons/increase.svg") no-repeat center;

                @include media-breakpoint-up(md) {
                    content: none;
                }
            }

            & + .tab-content {
                @include media-breakpoint-down(sm) {
                    display: none;
                }
            }

            &.expanded {
                &::after {
                    background: url("../images/svg-icons/decrease.svg") no-repeat center;
                }

                & + .tab-content {
                    @include media-breakpoint-down(sm) {
                        display: block;
                    }
                }
            }
        }

        .tab-info {
            line-height: 1.43;

            @include media-breakpoint-up(md) {
                float: left;
                width: 55%;
            }

            & + .tab-info {
                margin-top: rem(20px);

                @include media-breakpoint-up(md) {
                    margin-top: rem(40px);
                }
            }

            & + .tab-additional-info {
                margin-top: rem(20px);

                @include media-breakpoint-up(md) {
                    display: inline-block;
                    width: 39%;
                    margin-top: 0;
                    margin-left: 6%;
                }
            }
        }

        .tab-additional-info {
            margin-bottom: rem(20px);
        }

        p {
            margin: 0 0 rem(20px);
        }

        ul {
            list-style-type: disc;
            list-style-position: inside;
            line-height: 1.87;
            margin: 0 0 rem(20px);
        }

        h5 {
            margin: 0 0 rem(10px);
        }

        .wistia_embed {
            max-width: 100%;
            margin-bottom: rem(20px);

            & * {
                max-width: 100% !important;
                height: auto !important;
            }

            img {
                position: static !important;
            }
        }

        .wistia_click_to_play {
            & > div {
                &::before {
                    content: "";
                    display: block;
                    position: absolute;
                    z-index: 1;
                    width: rem(80px);
                    height: rem(80px);
                    background: url("../images/svg-icons/play-button.svg") no-repeat center;
                    background-size: contain;
                    top: calc(50% - #{rem(40px)});
                    left: calc(50% - #{rem(40px)});
                }
            }
        }

        .info-link {
            margin-bottom: rem(10px);
        }

        .bv-cv2-cleanslate .bv-shared .bv-action-bar-header {
            display: none !important;
        }

        .bv-cv2-cleanslate .bv-shared .bv-action-bar button:hover {
            border-color: $brand-primary !important;
        }
    }
}

.js {
    .pdp-main {
        .recommendations {
            .product-tile {
                .recommendation-tooltip {
                    display: none !important;
                }
            }
        }
    }

    .restrictions-dialog {
        h4 {
            margin: rem(10px) 0;
            font-weight: 700;
        }

        ul {
            margin: 0;
        }
    }
}

.zoom-text {
    display: none;
    position: absolute;

    @include font-bold(14px);

    color: $body-text-color;
    min-width: 150px;
    text-shadow:
        -1px -1px 0 $white,
        1px -1px 0 $white,
        -1px 1px 0 $white,
        1px 1px 0 $white;
}

.cross-sell ul li.grid-tile {
    display: inline-block;
    margin-left: 10%;
    height: 452px !important;

    @include media-breakpoint-up(md) {
        margin-left: rem(7px);
    }

    @include media-breakpoint-down(md) {
        margin-left: initial;
        height: 462px !important;
    }
}

button#product-set-add-to-cart {
    width: 185px;

    &.add-to-cart-disabled {
        cursor: not-allowed;
    }

    @include media-breakpoint-up(md) {
        width: 130px;
    }

    @include media-breakpoint-up(lg) {
        width: 200px;
    }

    @include media-breakpoint-up(xl) {
        width: 280px;
    }
}

span#product-info-melter_shipping_message {
    @include media-breakpoint-down(sm) {
        padding-top: 7px;
    }
}

#product-content {
    .rating-wrapper {
        margin-bottom: rem(30px);

        @include line-height(20px);

        @include media-breakpoint-up(md) {
            margin-bottom: rem(20px);
        }

        .p-w-r {
            .pr-snippet-stars-reco-inline {
                &.pr-snippet-minimal {
                    .pr-snippet-stars-container {
                        display: inline-block;
                        margin-right: 15px;
                    }
                }

                .pr-snippet-read-and-write {
                    a,
                    span {
                        font-size: 15px !important;
                        color: inherit;
                    }
                }
            }

            .pr-snippet-stars {
                margin-right: 5px;
            }

            .pr-no-reviews {
                .pr-snippet-stars {
                    .pr-snippet-rating-decimal {
                        display: none;
                    }
                }

                .pr-snippet-review-count {
                    display: none;
                }
            }
        }
    }
}

#QuickViewDialog {
    .p-w-r {
        .pr-snippet-rating-decimal {
            display: none;
        }

        a.pr-snippet-write-review-link {
            display: none;
        }
    }
}

#pr-reviewsnippet,
#pr-reviewdisplay,
.pr-tile-review-snippet,
.ps-review-snippet {
    .p-w-r {
        .pr-rating-stars {
            vertical-align: top;
            margin-bottom: 5px;

            .pr-star-v4 {
                height: 15px !important;
                width: 15px !important;
            }

            .pr-star-v4-100-filled {
                background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+PHN2ZyB3aWR0aD0iMTMuMzE0IiBoZWlnaHQ9IjEyLjY2MyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnN2Zz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+PHJlY3QgZmlsbD0iI2ZmZmZmZiIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgeD0iMCIgeT0iMCIvPjxwYXRoIGZpbGw9IiNkNzE5MjEiIGQ9Ik0gNi42NTcsMTAuNSAyLjU0MywxMi42NjMgMy4zMjgsOC4wODIgMCw0LjgzNyA0LjYsNC4xNjggNi42NTcsMCBsIDIuMDU3LDQuMTY4IDQuNiwwLjY2OSAtMy4zMjgsMy4yNDUgMC43ODUsNC41ODEgeiIvPjwvZz48L3N2Zz4=");
            }

            .pr-star-v4-75-filled {
                background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+PHN2ZyB3aWR0aD0iMTMuMzE0IiBoZWlnaHQ9IjEyLjY2MyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczpzdmc9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxyZWN0IGZpbGw9IiNmZmZmZmYiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHg9IjAiIHk9IjAiLz48cGF0aCBmaWxsPSIjZDcxOTIxIiBkPSJNIDYuNjU3LDEwLjUgMi41NDMsMTIuNjYzIDMuMzI4LDguMDgyIDAsNC44MzcgNC42LDQuMTY4IDYuNjU3LDAgbCAyLjA1Nyw0LjE2OCA0LjYsMC42NjkgLTMuMzI4LDMuMjQ1IDAuNzg1LDQuNTgxIHoiIHN0eWxlPSJmaWxsOiNlZmVmZWY7ZmlsbC1vcGFjaXR5OjEiLz48cGF0aCBmaWxsPSIjZDcxOTIxIiBkPSJNIDYuNjU3LDEwLjUgMi41NDMsMTIuNjYzIDMuMzI4LDguMDgyIDAsNC44MzcgNC42LDQuMTY4IDYuNjU3LDAgOS41LDQuMjc4NjYyOSBWIDQuODM3IDguMDgyIDExLjk5OTAyMiBaIiBzdHlsZT0iZmlsbDojZDcxOTIxO2ZpbGwtb3BhY2l0eToxIi8+PC9nPjwvc3ZnPg==");
            }

            .pr-star-v4-50-filled {
                background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+PHN2ZyB3aWR0aD0iMTMuMzE0IiBoZWlnaHQ9IjEyLjY2MyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczpzdmc9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxyZWN0IGZpbGw9IiNmZmZmZmYiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHg9IjAiIHk9IjAiLz48cGF0aCBmaWxsPSIjZDcxOTIxIiBkPSJNIDYuNjU3LDEwLjUgMi41NDMsMTIuNjYzIDMuMzI4LDguMDgyIDAsNC44MzcgNC42LDQuMTY4IDYuNjU3LDAgNi42NTU3NDgsNC4xNTIxOTEgViA0LjgzNyA4LjA4MiBaIi8+PHBhdGggZmlsbD0iI2Q3MTkyMSIgZD0iTSA2LjY1NywxMC41IDEwLjc3MSwxMi42NjMgOS45ODYsOC4wODIgMTMuMzE0LDQuODM3IDguNzE0LDQuMTY4IDYuNjU3LDAgNi42NTgyNTIsNC4xNTIxOTEgViA0LjgzNyA4LjA4MiBaIiBzdHlsZT0iZmlsbDojZWJlYmViO2ZpbGwtb3BhY2l0eToxIi8+PC9nPjwvc3ZnPg==");
            }

            .pr-star-v4-25-filled {
                background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+PHN2ZyB3aWR0aD0iMTMuMzE0IiBoZWlnaHQ9IjEyLjY2MyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczpzdmc9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHRyYW5zZm9ybT0ibWF0cml4KC0xLDAsMCwxLDEzLjMxNCwwKSI+PHJlY3QgZmlsbD0iI2ZmZmZmZiIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgeD0iMCIgeT0iMCIvPjxwYXRoIGZpbGw9IiNkNzE5MjEiIGQ9Ik0gNi44MTUwODk5LDEwLjQ2ODM4MiA2LjkzNzg5ODksMTAuMDA3MDkgMy4zMjgsOC4wODIgNS43ODYwOSw0LjY3ODkxMDEgNC42LDQuMTY4IDcuODU4NDgzMiw0LjI2ODQyNyA4LjcxNCw0LjE2OCBsIDQuNiwwLjY2OSAtMy4zMjgsMy4yNDUgMC43ODUsNC41ODEgeiIgc3R5bGU9ImZpbGw6I2Q3MTkyMTtmaWxsLW9wYWNpdHk6MSIvPjxwYXRoIGZpbGw9IiNkNzE5MjEiIGQ9Ik0gNi42NTcsMTAuNSAyLjU0MywxMi42NjMgMy4zMjgsOC4wODIgMCw0LjgzNyA0LjYsNC4xNjggNi42NTcsMCA5LjUsNC4yNzg2NjI5IFYgNC44MzcgOC4wODIgMTEuOTk5MDIyIFoiIHN0eWxlPSJmaWxsOiNlZmVmZWY7ZmlsbC1vcGFjaXR5OjEiLz48L2c+PC9zdmc+");
            }

            .pr-star-v4-0-filled {
                background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+PHN2ZyB3aWR0aD0iMTMuMzE0IiBoZWlnaHQ9IjEyLjY2MyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczpzdmc9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxyZWN0IGZpbGw9IiNmZmZmZmYiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHg9IjAiIHk9IjAiLz48cGF0aCBmaWxsPSIjZDcxOTIxIiBkPSJNIDYuNjU3LDEwLjUgMi41NDMsMTIuNjYzIDMuMzI4LDguMDgyIDAsNC44MzcgNC42LDQuMTY4IDYuNjU3LDAgbCAyLjA1Nyw0LjE2OCA0LjYsMC42NjkgLTMuMzI4LDMuMjQ1IDAuNzg1LDQuNTgxIHoiIHN0eWxlPSJmaWxsOiNlYmViZWI7ZmlsbC1vcGFjaXR5OjEiLz48L2c+PC9zdmc+");
            }
        }

        .pr-snippet-rating-decimal {
            font-size: 15px;
            border: 0;
            vertical-align: bottom;
        }

        .pr-snippet-stars-container {
            vertical-align: bottom;
        }

        .pr-snippet-stars-reco-reco {
            display: none;
        }

        a.pr-subheadline {
            display: none;
        }

        section.pr-review-snapshot {
            .pr-review-snapshot-simple {
                .pr-review-snapshot-snippets {
                    .pr-snippet-read-and-write {
                        a,
                        a:hover {
                            background-color: $btn-bg !important;
                            color: $btn-color !important;
                            border-bottom: none !important;
                        }
                    }
                }
            }

            .pr-ratings-histogram {
                .pr-ratings-histogram-barValue {
                    background-color: $power-reviews-primary;
                }
            }
        }

        .pr-slider-root {
            .pr-slider {
                .pr-slider-node-group {
                    .pr-slider-node.pr-active {
                        background-color: $power-reviews-primary;
                    }
                }
            }
        }

        section.pr-review-snapshot-block.pr-review-snapshot-block-recommend {
            visibility: hidden;
        }

        section.pr-review-snapshot-faceoff {
            display: none;
        }
    }
}

.p-w-r {
    .pr-accessible-btn,
    .pr-accessible-btn:hover {
        background-color: $btn-bg !important;
        color: $btn-color !important;
        border-bottom: none !important;
    }

    .pr-rd-details {
        &.pr-rd-reviewed-at {
            display: none;
        }
    }
}

.pr-tile-review-snippet,
.ps-review-snippet {
    .p-w-r {
        .pr-category-snippet {
            .pr-snippet-rating-decimal {
                display: none;
            }

            .pr-category-snippet__total {
                padding-bottom: 5px;
            }
        }

        .pr-no-reviews {
            .pr-category-snippet {
                .pr-category-snippet__total {
                    display: none;
                }
            }
        }
    }
}
